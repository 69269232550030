import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';

class page503 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex" ,backgroundColor:"#ffffff",height:"100vh",width:"100%"}}>
                    <div style={{height:"calc(100vh - 70px)",width:"100%", display:"flex",justifyContent:"center",alignItems:"center", justifyItems:"center", overflow:"auto", flexDirection:"column"}}>
                        <img style={{width:"100%", maxWidth:500}} src={require("../assets/Computer troubleshooting.gif")} alt="illustration Maintenance"/>
                        <h2 style={{fontWeight:100, textAlign:"center"}}>
                            Nous effectuons des travaux de maintenance planifiés sur cette application.
                            <br/>
                            Notre équipe travaille actuellement à la restauration du service.
                            <br/>
                            Nous vous prions de nous excuser pour les éventuels désagréments.
                        </h2>
                    </div>
                </div>
            </div>


        );
    }
}

export default withRouter(page503)