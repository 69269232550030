import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isMobile} from "react-device-detect";

import Axios from "axios";

import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Swal from "sweetalert2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import socketInstance from "../Components/SocketClient";
import jwt_decode from "jwt-decode";
import {urlAxios, urlAxiosCore} from '../Components/GetURL';


const columnsClientShare =[
    { id: 'pseudo', label: 'Nom', minWidth: 120 },
    { id: 'Action', label: 'Supprimer', minWidth: 50,maxWidth:50 },
]
const columns = [
    { id: 'Date', label: 'Date', minWidth: 150 },
    { id: 'Nom', label: 'Nom', minWidth: 200 },
    { id: 'Action', label: 'Action', minWidth: 10 },
];

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let socketIO;
let jwtDecoded;

class ListeZoneTemporaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hashFile : '',
            fileName : '',
            acceptedFile : null,
            listAllTrace:[],
            champsMDP:"",
            champsIdentifiant:"",
            connected:false,
            detailDoc:{},
            allValidateurs:[],
            showBrowserView:true,
            downloadFinished:true,
            showSaveFilePickerCanceled:false,
            validationsEnAttente:[]
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
        if(this._askConnection){
            this._askConnection.remove()
        }
    }

    _askConnection;
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        if(sessionStorage.getItem("KAGESECUR_jwt")){
            jwtDecoded = jwt_decode(sessionStorage.getItem("KAGESECUR_jwt"))
        }
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            Axios.post(urlAxiosCore+"verifyJwt ",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat === true){
                    //On décode le jwt pour connaitre les infos utiles
                    let jwtDecoded = jwt_decode(this.state.jwt)
                    // socketIO = await socketInstance.connectionSocket(this.state.jwt)
                    global.emitter.emit("onRouteChangedKageSecur",{
                        user:jwtDecoded.gestionnaire ? "gestionnaire":"client",
                        pseudo:jwtDecoded.pseudo
                    })
                    // this.setState({isGestionnaire:jwtDecoded.gestionnaire})
                    this.setState({isGestionnaire:jwtDecoded.gestionnaire})
                    this.getMasterReportValidation()
                }else{
                    global.emitter.emit("askConnection") //Display interface connexion du header Kagesecur
                    this._askConnection = global.emitter.addListener("emitConnected",()=>{ //On prévient que le client s'est connecté
                        if(sessionStorage.getItem("KAGESECUR_jwt")){
                            jwtDecoded = jwt_decode(sessionStorage.getItem("KAGESECUR_jwt"))
                        }
                        this.setState({jwt:sessionStorage.getItem("KAGESECUR_jwt")},()=>{
                            this.getMasterReportValidation() //On get les validations en attente
                            this._askConnection.remove()
                        })
                    })
                }
            })

        })
    }

    getMasterReportValidation(){
        Axios.post(urlAxios+"getFilesToValidate",{},
            {headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then(async response=>{
            if (response.data.jwt){
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
                sessionStorage.setItem("KAGESECUR_jwt", response.data.jwt)
            }
            if (response.data.resultat==="autorise"){
                socketIO = await socketInstance.connectionSocket(this.state.jwt)
                this.setState({
                    connected:true,
                    champsIdentifiant:jwtDecoded.pseudo,
                    validationsEnAttente:response.data.files,
                    isSure:false,
                    empreinte:jwtDecoded.empreinte,
                    jwt:sessionStorage.getItem("KAGESECUR_jwt")
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text:"Une erreur est survenue",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    downloadFile(){
        this.setState({showSaveFilePickerCanceled:false})
        window.open(`${urlAxios}downloadMasterReport?jwt=${this.state.jwt}&path=${this.state.empreinte+"/"+this.state.detailDoc.name.replace("##", "pourcentdiese")}`)
    }

    answerValidation(choice){
        let textSwal = choice === "accept" ? "En cliquant sur confirmer vous affirmez avoir pris connaissance du fichier et acceptez de le stocker sur votre compte Kagesecur. Vous pouvez également renommer votre fichier (optionnel) : ":"En cliquant sur confirmer vous affirmez avoir pris connaissance du fichier et refusez de le stocker sur votre compte Kagesecur."
        let titleSwal = choice === "accept" ? "Je valide le document":"Je refuse le document"
        Swal.fire({
            title: titleSwal,
            icon:choice === "accept" ? "success":"error",
            input:choice === "accept" ? "text":null,
            inputPlaceholder:"image.png",
            text: textSwal,
            confirmButtonText: 'Confirmer',
            showCancelButton:true,
            cancelButtonText:"Retour"
        }).then((result)=>{
            if(result.isConfirmed){
                let newName = result.value
                if(newName !== ""){
                    if(choice==="accept"){
                        newName = newName.split(".")
                        if(newName.length === 1){
                            newName.push("."+this.state.detailDoc.name.split("##")[1].split(".")[this.state.detailDoc.name.split("##")[1].split(".").length-1])
                            newName=newName.join("")
                        }else{
                            newName=newName.join(".")
                        }
                    }
                }

                Axios.post(urlAxios+"answerValidationMasterReport",{
                    fileName:this.state.detailDoc.name,
                    accept:choice === "accept",
                    newName:newName
                },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then(response=>{
                    if (response.data.jwt){
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                        sessionStorage.setItem("KAGESECUR_jwt", response.data.jwt)
                    }
                    let list = this.state.validationsEnAttente.slice()
                    this.state.validationsEnAttente.forEach(elt => {
                        if (elt === this.state.detailDoc){
                            list.splice(list.indexOf(elt),1)
                        }
                    })
                    this.setState({validationsEnAttente:list})
                    if(response.data.resultat === "done"){
                        if(choice === "accept"){
                            Toast.fire({
                                title: "Document "+this.state.detailDoc.name.split("##")[1] + " accepté.",
                                icon: 'success',
                            })
                        }else{
                            Toast.fire({
                                title: "Document "+this.state.detailDoc.name.split("##")[1] +" refusé.",
                                icon: 'success',
                            })
                        }
                        this.setState({detailDoc:{}})
                    }else{
                        Toast.fire({
                            title: response.data.resultat,
                            icon: 'error',
                        })
                    }
                })
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                {this.state.showBrowserView ? (
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                        <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../assets/signatureOrange.png")} alt="imageDocument"/>
                        <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../assets/acceptBlue.png")} alt="imageAuction"/>
                        <h1 style={{marginTop:50}}>Validez <span style={{color:"#52a8d9"}}>vos documents</span> provenant de <span style={{color:"#f18f29"}}>Master Report</span></h1>
                        <div style={{display:"flex", width:"100%",height:"70%", justifyContent:"center", alignItems:"center"}}>
                            <div style={{width:"50%", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center",backgroundColor:"white",padding:"4vw", paddingBottom:"2vw", paddingTop:"2vw",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                    <h3>{!!this.state.detailDoc && Object.entries(this.state.detailDoc).length>0 ?"Informations du document :" : "Selectionnez un document"}  </h3>
                                    {!!this.state.detailDoc && Object.entries(this.state.detailDoc).length>0 && (
                                        <>
                                            <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"flex-start", fontSize:17}}>
                                                <p>Nom du fichier : {this.state.detailDoc.name.split("##")[1]}</p>
                                                <p>Date d'enregistrement du fichier : {new Date(this.state.detailDoc.dateUpload).toLocaleString("fr-FR")}</p>
                                            </div>
                                            <Button onClick={()=>{this.state.downloadFinished && this.downloadFile()}} style={{backgroundColor:"#369dd7",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5, textTransform:"none"}} >
                                                {!this.state.downloadFinished ? (
                                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                        <p style={{margin:0,marginRight:10}}>Téléchargement en cours...</p>
                                                        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                            <CircularProgress style={{color:"white"}} size={30}></CircularProgress>
                                                            <p style={{position:"absolute",fontSize:10}}>{this.state.progressDownloadFile}%</p>
                                                        </div>
                                                    </div>

                                                ):(
                                                    <p style={{margin:0,marginRight:10}}>Télécharger le fichier</p>
                                                )}
                                            </Button>
                                            <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>
                                                <Button onClick={()=>{this.answerValidation("accept")}} style={{backgroundColor:"#369dd7",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5,marginLeft:0, textTransform:"none"}}>Valider</Button>
                                                <Button onClick={()=>{this.answerValidation("refuse")}} style={{backgroundColor:"#ff3e3e",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5,marginRight:0, textTransform:"none"}}>Refuser</Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div style={{width:"50%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <Paper style={{margin:10, width:"85%", overflowY:"scroll",maxHeight:365}}>
                                    <TableContainer>
                                        <Table stickyHeader >
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.validationsEnAttente.length > 0 ? (
                                                    <>
                                                        {(this.state.validationsEnAttente).map((row) => {
                                                            return (
                                                                <TableRow style={{cursor:"pointer"}} onClick={()=>{this.setState({detailDoc:row})}}  hover tabIndex={-1} key={row.code}>
                                                                    {columns.map(column => {
                                                                        return (
                                                                            <TableCell key={column.id}>
                                                                                {column.id === "Nom" || column.id === 'Date' ? (
                                                                                    column.id === "Nom" ? (
                                                                                        <p>{row.name.split("##")[1]}</p>
                                                                                    ):(
                                                                                        <p>{new Date(row.dateUpload).toLocaleString("fr-FR" ,{timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}).replace(","," à")}</p>
                                                                                    )
                                                                                ):(
                                                                                    <Button><VisibilityIcon/></Button>
                                                                                )}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </>
                                                ):(
                                                    <TableRow style={{cursor:"pointer"}}  hover tabIndex={-1} >
                                                        <p style={{marginLeft:"10%"}}>Vous n'avez aucun document à valider...</p>
                                                    </TableRow>
                                                )}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                    </div>
                ):(
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                        <h2 style={{textAlign:"center",marginTop:this.state.connected ? 0:50}}>Validez <span style={{color:"#52a8d9"}}>vos documents</span> provenant de <span style={{color:"#f18f29"}}>Master Report</span></h2>
                        <div style={{display:"flex", width:"100%",height:"70%", justifyContent:"center", alignItems:"center"}}>
                            {!!this.state.detailDoc && Object.entries(this.state.detailDoc).length>0 ?(
                                <div style={{width:"50%", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                    <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center",backgroundColor:"white",padding:"4vw", paddingBottom:"2vw", paddingTop:"2vw",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                        <div style={{position:"relative"}}>
                                            <ClearIcon onClick={()=>{this.setState({detailDoc:{}})}} style={{position:"absolute", cursor:"pointer", left:150}}/>
                                        </div>
                                        <h3>{Object.entries(this.state.detailDoc).length>0 ?"Informations du document :" : "Selectionnez un document"}  </h3>
                                        {Object.entries(this.state.detailDoc).length>0 && (
                                            <>
                                                <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"flex-start", fontSize:17}}>
                                                    <p>Nom du fichier : {this.state.detailDoc.name.split("##")[1]}</p>
                                                    <p>Date d'enregistrement du fichier : {new Date(this.state.detailDoc.dateUpload).toLocaleString("fr-FR")}</p>
                                                </div>
                                                <Button onClick={()=>{this.state.downloadFinished && this.downloadFile()}} style={{backgroundColor:"#369dd7",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5, textTransform:"none"}} >
                                                    {!this.state.downloadFinished ? (
                                                        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                            <p style={{margin:0,marginRight:10}}>Téléchargement en cours...</p>
                                                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                <CircularProgress style={{color:"white"}} size={30}></CircularProgress>
                                                                <p style={{position:"absolute",fontSize:10}}>{this.state.progressDownloadFile}%</p>
                                                            </div>
                                                        </div>

                                                    ):(
                                                        <p style={{margin:0,marginRight:10}}>Télécharger le fichier</p>
                                                    )}
                                                </Button>
                                                <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>
                                                    <Button onClick={()=>{this.answerValidation("accept")}} style={{backgroundColor:"#369dd7",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5,marginLeft:0, textTransform:"none"}}>Valider</Button>
                                                    <Button onClick={()=>{this.answerValidation("refuse")}} style={{backgroundColor:"#ff3e3e",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5,marginRight:0, textTransform:"none"}}>Refuser</Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ):(
                                <div style={{width:"95%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                    <Paper style={{margin:10, width:"100%", overflowY:"scroll",maxHeight:365}}>
                                        <TableContainer>
                                            <Table stickyHeader >
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.validationsEnAttente.length > 0 ? (
                                                        <>
                                                            {(this.state.validationsEnAttente).map((row) => {
                                                                return (
                                                                    <TableRow style={{cursor:"pointer"}} onClick={()=>{this.setState({detailDoc:row})}}  hover tabIndex={-1} key={row.code}>
                                                                        {columns.map(column => {
                                                                            return (
                                                                                <TableCell key={column.id}>
                                                                                    {column.id === "Nom" || column.id === 'Date' ? (
                                                                                        column.id === "Nom" ? (
                                                                                            <p>{row.name.split("##")[1]}</p>
                                                                                        ):(
                                                                                            <p>{new Date(row.dateUpload).toLocaleString("fr-FR" ,{timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}).replace(","," à")}</p>
                                                                                        )
                                                                                    ):(
                                                                                        <Button><VisibilityIcon/></Button>
                                                                                    )}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </>
                                                    ):(
                                                        <TableRow style={{cursor:"pointer"}}  hover tabIndex={-1} >
                                                            <p style={{marginLeft:"10%"}}>Vous n'avez aucun document à signer...</p>
                                                        </TableRow>
                                                    )}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(ListeZoneTemporaire)