import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isMobile} from "react-device-detect";
import Axios from "axios";

import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import {Cancel, Check, Close, FolderZip, KeyboardReturn, Refresh} from "@mui/icons-material";
import FolderIcon from "@mui/icons-material/Folder";
import socketInstance from "../Components/SocketClient";
import jwt_decode from "jwt-decode";
import {askPermToUpload, uploadChunk, uploadEnded} from "./SharedUploadFunctions";
// import CryptoJS from "crypto-js";
import PdfIcon from "../assets/PDFIcon.png";
import ImageIcon from "@mui/icons-material/Image";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {createFilterOptions} from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import {baseUrl, urlAxios, urlAxiosCore} from '../Components/GetURL';

let classesBar = {
    colorPrimary: {
        backgroundColor: '#9de88c',
    },
    barColorPrimary: {
        backgroundColor: '#2ed000',
    },
    colorPrimaryFalse: {
        backgroundColor: '#ec6f6f',
    },
    barColorPrimaryFalse: {
        backgroundColor: '#d00000',
    }
}
const sizeLimitFile = 10737418240 // 10GB


const columnsClientShare =[
    { id: 'pseudo', label: 'Nom', minWidth: 120 },
    { id: 'Action', label: 'Supprimer', minWidth: 50,maxWidth:50 },
]
const columnsListePreuveViaMail = [
    { id: 'recepteurName', label: 'Identifiant', minWidth: 75 },
    { id: 'recepteurEmail', label: 'Email', minWidth: 150 },
    { id: 'Action', label: 'Action', minWidth: 20 }
]

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    ignoreAccents: true,
    stringify: (option) => `${option.pseudo} ${option.email}`,
    trim: true,
});

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
let socketIO; //Initialisation de l'instance du socket
let jwtDecoded;
const lineHeight = 48
const lineHeight2 = 111

class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true,
            pf:"",
            firstVisibleIndex:0,
            virtualListHeight:0,
            allValidateurs:[],
            validateursOthersNodes:[],
            searchWithG:false,
            searchWithC:false,
            searchWithCSA:false,
            allClientsDisplayed:[],
            allClient:[],
            clientChosenForSignataire:null,
            typeSignature:this.props.signature,
            surcharge:false
        };
    }

    setInitialStates(){
        return{
            hashFile : '',
            fileName : '',
            acceptedFile : [],
            chunkedAcceptedFile:[],
            champsAdresse:'',
            champsCode:'',
            checkedExterne:true,
            readyToSend:false,
            loadingFile:false,
            cancel:false,
            cancelUpload:false,
            sendingFile:false,
            uploadingFile:false,
            progress:0,
            countFile:[],
            processusDone:false,
            totalFileDone:0,
            listSignataire:[],
            sendProofDirect:false,
            arrayBufferPiecesJointes:[],
            displayDialogSendProof:false,
            listRecepteurPreuveViaMail:[],
            phasePreuveViaMail:1
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
        window.removeEventListener("dragenter", this.handleWindowDragEnter);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.signature !== prevProps.signature){
            this.setState({typeSignature:this.props.signature},()=>{
                if(this.state.typeSignature){
                    this.getAllClient()
                }
            })
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        window.addEventListener("dragenter", this.handleWindowDragEnter);


        let pf = new URLSearchParams(window.location.search).get("pf");
        if(!!pf){
            pf = pf.replaceAll("prctslashprct","/")
            this.setState({pf})
        }
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(urlAxiosCore+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    if (jwtDecoded.freeClient){
                        Swal.fire({
                            text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/KageSecur/Accueil", { replace: true })
                        })
                    }else {
                        global.emitter.emit("onRouteChangedKageSecur", {
                            user: jwtDecoded.gestionnaire ? "gestionnaire" : "client",
                            pseudo: jwtDecoded.pseudo
                        })
                        if(this.state.typeSignature){
                            this.getAllClient()
                        }
                    }
                }else{
                    window.location.replace("/KageSecur/Accueil")
                }
            })
        })
    }

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }


    sendEmpreinte(){
        let self = this

        if(this.state.typeSignature){
            Swal.fire({
                title: "Insérez un commentaire pour le signataire",
                html:
                    '<textarea style="resize: none;width:100%;font-family: Segoe UI; font-size: 17px"  rows = "8" cols = "50" maxlength="350" id="swal-messageAP">',
                showCancelButton: true,
                showDenyButton: true,
                showLoaderOnConfirm: true,
                cancelButtonColor: '#d33',
                denyButtonColor: '#787878',
                confirmButtonText: 'Envoyer',
                cancelButtonText: "Annuler",
                denyButtonText: `Sans commentaire`,
                customClass: 'swal-offset-top',
            }).then(inputValue => {
                if (inputValue.isConfirmed || inputValue.isDenied) {
                    let messageClient = document.getElementById('swal-messageAP').value
                    if (inputValue.isDenied) {
                        messageClient = ""
                    }
                    nextStep(messageClient)
                }
            })
        }else{
            nextStep("")
        }

        async function nextStep(messageClient){
            // let divScroll = document.getElementById("divScroll")
            // /*scroll to the top of this div*/
            // divScroll.scrollTop = 0
            self.setState({uploadingFile:true,cancelUpload:false,totalFileDone:0})
            let updateJWT = true /*On évite de surcharger l'update de jwt*/

            socketIO = await socketInstance.connectionSocket(self.state.jwt)

            let acceptedFiles = self.state.acceptedFile.slice()
            let displayProblemToast=false
            let userDisconnected = false
            let simultaneousUpload = []
            acceptedFiles.forEach((file,index) => {
                self.setState({["progressUpload"+file.name]:0, ["packetDebit" + file.name]:0, ["fileSent"+file.name]:"inProgress", ["cancel"+file.name]:false}, function startUploadProcess(){
                    const data = {
                        empreinte:jwtDecoded.empreinte,
                        path:self.state.pf||"",
                        fileName:file.name,
                        bearer:self.state.jwt
                    }
                    let codeUpload
                    let serverLastResponseSequence = null
                    /*Système de gestion de l'upload "synchrone"*/
                    const MAX_SIMULTANEOUS_UPLOAD = 0 //0 = un seul flux à la fois
                    /*Système de gestion de l'upload "synchrone"*/
                    if(simultaneousUpload.findIndex(value => value === index) === -1 && simultaneousUpload.length <= MAX_SIMULTANEOUS_UPLOAD && !self.state.cancelUpload){
                        simultaneousUpload.push(index)
                    }
                    if(simultaneousUpload.findIndex(value => value === index) !== -1){
                        askPermToUpload(socketIO,"askPermToUpload",data).then(response => {
                            if(response.isAuthorized){
                                codeUpload = response.codeUpload
                                let dataFile = {
                                    empreinte:jwtDecoded.empreinte,
                                    fileName: file.name,
                                    node:baseUrl.split("/")[2],
                                    savedPath:codeUpload,
                                    idToken:response.idToken
                                }
                                let stream = file.stream()
                                let re = stream.getReader()
                                // let SHA = CryptoJS.algo.SHA256.create()
                                let estimatedTime, totalPacketTimesDuration =0, packetTimeDuration = 0, totalSizeAlreadySent = 0, sequenceChunk = 0
                                let initializeHandles = true
                                let handleErrorSocket,handleConnectSocket,handleDisconnectSocket

                                new ReadableStream({
                                    start(controller) {

                                        if(initializeHandles){
                                            initializeHandles = false
                                            handleErrorSocket = (error) => {
                                                console.error("Socket error:", error);
                                            }

                                            handleConnectSocket = () => {
                                                // Ne log que quand l'user s'est reconnecté puisque ce listener n'est activé que ici
                                                console.log("Reconnected to socket");
                                                Toast.fire({
                                                    title: 'Reconnexion au serveur.',
                                                    icon: 'success',
                                                })
                                                if(userDisconnected){
                                                    userDisconnected = false
                                                    const data = {
                                                        bearer:self.state.jwt,
                                                        codeUpload,
                                                        empreinte:jwtDecoded.empreinte
                                                    }
                                                    askPermToUpload(socketIO,"askPermToUpload", data).then(response => {
                                                        if (response.isAuthorized){
                                                            uploadingChunks = 0
                                                            console.log("deconnexion détectée, envoi des chunks manquants",chunksToSend.length)
                                                            //Vider le tableau chunksToSend avant de reprendre la lecture
                                                            function sendMissingChunks(){
                                                                return new Promise(async resolve => {
                                                                    console.log(chunksToSend.length, "chunksToSend.length")
                                                                    if (chunksToSend.length === 0){
                                                                        resolve()
                                                                    }else{
                                                                        const data = {
                                                                            buffer:chunksToSend[0].chunkData,
                                                                            status:"upload",
                                                                            bearer:self.state.jwt,
                                                                            sequence:chunksToSend[0].chunkIndex
                                                                        }
                                                                        uploadChunk(socketIO, "sendChunk##"+codeUpload, data).then(responseSC => {
                                                                            chunksToSend.shift()
                                                                            resolve(sendMissingChunks())
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                            sendMissingChunks().then(()=>{
                                                                console.log("all chunks sent, now push")
                                                                self.setState({userDisconnected:false},()=>{
                                                                    setTimeout(()=>{
                                                                        push()
                                                                    },1000)
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            }

                                            handleDisconnectSocket = (reason) => {
                                                console.log("Disconnected from the server:", reason);
                                                userDisconnected = true
                                                self.setState({userDisconnected:true})
                                                Toast.fire({
                                                    title: 'Problème réseau, reconnexion dès que possible.',
                                                    icon: 'error',
                                                })
                                            }

                                            socketIO.on("error", handleErrorSocket);
                                            socketIO.on("connect",handleConnectSocket);
                                            socketIO.on("disconnect",handleDisconnectSocket);
                                        }

                                        let chunksToSend = []
                                        let bearerJwt = self.state.jwt

                                        const MAX_CONCURRENT_CHUNKS = 5;
                                        let countChunkUploaded = 0
                                        let uploadingChunks = 0
                                        let readingDone = false
                                        let serverQueueLength = 0
                                        const timestampUploadStart = Date.now()
                                        let timeForOneChunk = null
                                        let dynamicTimeout = null
                                        function push() {
                                            const packetStartTime = Date.now()
                                            re.read().then( ({done, value}) => {
                                                sequenceChunk++
                                                chunksToSend.push({chunkData:value,chunkIndex:sequenceChunk})
                                                //Lecture du fichier et appelle de la bonne fonction
                                                if (done){
                                                    let checkDoneStatus = setInterval(()=>{
                                                        if(uploadingChunks === 0){
                                                            clearInterval(checkDoneStatus)
                                                            endOfUpload()
                                                        }
                                                    },200)
                                                }else{
                                                    processUpload()
                                                }
                                                function processUpload(){
                                                    // uploadingChunks+=1
                                                    uploadChunk(socketIO, "sendChunk##"+codeUpload, {buffer:value,status:"upload",bearer:bearerJwt,sequence:sequenceChunk})
                                                    .then(responseSC => {

                                                        timeForOneChunk = (Date.now() - packetStartTime) //en ms
                                                        if(sequenceChunk===1){
                                                            dynamicTimeout = timeForOneChunk/2
                                                        }else{
                                                            dynamicTimeout = (sequenceChunk - responseSC.sequence)*20
                                                        }

                                                        // console.log("responseSC",responseSC)
                                                        // if(responseSC.sequence < serverLastResponseSequence){
                                                        //     console.log("new SEQUENCE INFERIOR")
                                                        // }
                                                        // serverLastResponseSequence = responseSC.sequence
                                                        serverQueueLength = responseSC.queueLength
                                                        countChunkUploaded++
                                                        if(responseSC.jwt){
                                                            bearerJwt = responseSC.jwt
                                                        }
                                                        if(responseSC.isAuthorized){
                                                            chunksToSend.splice(chunksToSend.findIndex(elt => elt.chunkData === value),1)

                                                            /*STATS CALCULATION*/

                                                            /*total of bytes sent for now*/
                                                            totalSizeAlreadySent += value.length;



                                                            let packetDebit = ((value.length / (timeForOneChunk/1000)) / (1024 * 1024))*8 //x8 pour passer de bytes à bits

                                                            const progressUploadEnCours = Math.round((totalSizeAlreadySent / file.size) * 100);

                                                            //calculate time it took for arriving at this progress percentage
                                                            let timeForProgress = (Date.now() - timestampUploadStart ) / 1000 //en s

                                                            //timeRemaining is the time it will take to reach 100% from the current progress
                                                            let timeRemaining
                                                            if(progressUploadEnCours>0){
                                                                timeRemaining = Math.round(timeForProgress * (100/progressUploadEnCours)-timeForProgress)
                                                            }else{
                                                                timeRemaining = null
                                                            }
                                                            // let timeForOnePercent = timeForProgress / progressUploadEnCours

                                                            if(self.state.surcharge !== responseSC.surcharge){
                                                                self.setState({surcharge:responseSC.surcharge})
                                                            }

                                                            let previousPacketDebit = self.state["packetDebit" + file.name] || packetDebit
                                                            let meanPacketDebit = (((previousPacketDebit * (countChunkUploaded-1)) + packetDebit) / countChunkUploaded).toFixed(2)

                                                            //if countChunkUploaded is 1 or every 10 chunks uploaded, we update the estimated time and the debit states
                                                            if(countChunkUploaded === 1 || countChunkUploaded % 2 === 0){

                                                                self.setState({
                                                                   ["uploadEstimatedTime" + file.name]: timeRemaining||0,
                                                                   ["packetDebit" + file.name]: countChunkUploaded === 1 ? packetDebit.toFixed(2):meanPacketDebit,
                                                                   ["progressUpload" + file.name]: progressUploadEnCours,
                                                                   // ["timeForOnePercent" + file.name]: timeForOnePercent
                                                                });
                                                            }

                                                            /*STATS CALCULATION*/

                                                            if (!self.state["cancel"+file.name] && !self.state.cancelUpload){
                                                                // uploadingChunks-=1
                                                                //if first chunk
                                                                if (sequenceChunk === 1){
                                                                    push()
                                                                }
                                                            }else{
                                                                socketIO.off("error",handleErrorSocket)
                                                                socketIO.off("disconnect",handleDisconnectSocket)
                                                                socketIO.off("connect",handleConnectSocket)
                                                                simultaneousUpload.splice(simultaneousUpload.indexOf(index),1)
                                                                // let divScroll = document.getElementById("divScroll")
                                                                // divScroll.scrollTop = 0
                                                                socketIO.emit("sendChunk##"+codeUpload, {status:"cancel", bearer:self.state.jwt}, () => {
                                                                    bearerJwt = (responseSC.jwt ? responseSC.jwt : bearerJwt)
                                                                    sessionStorage.setItem("KAGESECUR_jwt",bearerJwt)
                                                                    self.setState({
                                                                        jwt:bearerJwt,
                                                                        surcharge:false
                                                                    })
                                                                    jwtDecoded = jwt_decode(bearerJwt)
                                                                    Toast.fire({
                                                                        icon: 'info',
                                                                        title: 'Upload annulé !'
                                                                    })
                                                                })
                                                            }
                                                        }else{
                                                            Swal.fire({
                                                                title: 'Attention',
                                                                text: "Votre session a expiré, veuillez vous reconnecter.",
                                                                icon: 'warning',
                                                                confirmButtonText: 'Ok'
                                                            })
                                                            sessionStorage.removeItem("KAGESECUR_jwt")
                                                            self.props.navigate("/KageSecur/Accueil")
                                                        }
                                                    })

                                                    // check with serverLastResponseSequence and the current sequence of chunk sent if I can send another chunk or wait a little bit
                                                    // let intervalNextChunk = setInterval(()=>{
                                                    //
                                                    // },10)

                                                    if(!self.state.cancelUpload && !self.state.userDisconnected){ //si l'upload n'est pas annulé
                                                        // console.log(sequenceChunk, serverLastResponseSequence, "sequenceChunk, serverLastResponseSequence")
                                                        // console.log(dynamicTimeout, "dynamicTimeout", "chunkSequence:", sequenceChunk)
                                                        if(timeForOneChunk!==null && sequenceChunk!==1){
                                                            setTimeout(()=>{
                                                                push()
                                                            },dynamicTimeout)
                                                        }
                                                    }
                                                }

                                                function endOfUpload(){

                                                    /*remove codeUpload from simultaneousUpload*/
                                                    simultaneousUpload.splice(simultaneousUpload.indexOf(index),1)
                                                    socketIO.off("error",handleErrorSocket)
                                                    socketIO.off("disconnect",handleDisconnectSocket)
                                                    socketIO.off("connect",handleConnectSocket)

                                                    let lastUpload = self.state.acceptedFile.length-1 === index
                                                    uploadEnded(socketIO, "sendChunk##"+codeUpload, {status:"ended",bearer:bearerJwt, lastUpload:lastUpload}).then(response => {                                                        bearerJwt = (response.jwt ? response.jwt : bearerJwt)
                                                        sessionStorage.setItem("KAGESECUR_jwt",bearerJwt)
                                                        self.setState({
                                                            jwt:bearerJwt
                                                        })
                                                        jwtDecoded = jwt_decode(bearerJwt)

                                                        let listRecepteurPreuveViaMail = []
                                                        if(self.state.sendProofDirect){
                                                            listRecepteurPreuveViaMail = self.state.listRecepteurPreuveViaMail
                                                        }

                                                        const data = {
                                                            allDatas: [dataFile],
                                                            signatairesList: self.state.listSignataire,
                                                            sendProofDirect: self.state.sendProofDirect,
                                                            listRecepteurPreuveViaMail,
                                                            checkedExterne:self.state.checkedExterne,
                                                            arrayBufferPiecesJointes:self.state.arrayBufferPiecesJointes,
                                                            messageClient: messageClient,
                                                            empreinte:jwtDecoded.empreinte,
                                                        }

                                                        self.setState({
                                                            ["progressUpload"+file.name]:100,
                                                            ["uploadEstimatedTime"+file.name]:0,
                                                            ["packetDebit"+file.name]:0,
                                                            surcharge:false
                                                        })
                                                        sendAxios(data)
                                                    })
                                                }

                                                function sendAxios(data){
                                                    const acceptedFileClone = self.state.acceptedFile.slice();
                                                    Axios.post(urlAxios + "writeProof", data, {headers: {Authorization: "Bearer " + self.state.jwt}}).catch((err) => {
                                                        console.log(err)
                                                    }).then((response) => {
                                                        self.setState({
                                                            acceptedFile:acceptedFileClone,
                                                            ["fileSent"+file.name]:response.data.resultat[file.name],
                                                        },()=>{
                                                            let countAF = 0
                                                            let processusDone = false

                                                            self.state.acceptedFile.forEach((elt,index)=>{
                                                                if(self.state["fileSent" + elt.name] !== "inProgress"){
                                                                    countAF++
                                                                }
                                                                if (self.state["fileSent" + elt.name] === "Ce document est déjà enregistré." && displayProblemToast!=="done") {
                                                                    displayProblemToast=true
                                                                }
                                                                if(index === self.state.acceptedFile.length-1){
                                                                    if(countAF===self.state.acceptedFile.length){
                                                                        processusDone=true
                                                                        self.setState({
                                                                            processusDone:true
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                            self.setState({totalFileDone:countAF})
                                                            if(displayProblemToast === true){
                                                                displayProblemToast = "done"
                                                                Swal.fire({
                                                                    icon: 'error',
                                                                    title:"Attention !",
                                                                    text: self.state.acceptedFile.length === 1 ? 'Le fichier envoyé est déjà présent dans notre blockchain':'Au moins un fichier (couleur rouge) parmi ceux envoyés est déjà présent dans notre blockchain'
                                                                }).then(()=>{
                                                                    if(!!self.state.pf && processusDone){
                                                                        setTimeout(()=>{
                                                                            let link = "/Kagesecur/TableauDeBord?pf="
                                                                            self.props.navigate(link+self.state.pf.replaceAll("/","prctslashprct") )
                                                                        },500)
                                                                    }
                                                                })
                                                            }else{
                                                                if(!!self.state.pf && processusDone){
                                                                    setTimeout(()=>{
                                                                        let link = "/Kagesecur/TableauDeBord?pf="
                                                                        self.props.navigate(link+self.state.pf.replaceAll("/","prctslashprct") )
                                                                    },500)
                                                                }
                                                            }

                                                            if(self.state.cancelUpload){
                                                                self.state.acceptedFile.forEach((elt,index)=>{
                                                                    self.setState({
                                                                    uploadingFile:false,
                                                                        processusDone:false,
                                                                        ["fileSent" + elt.name]:"inProgress",
                                                                        ["progressUpload"+elt.name]:0,
                                                                        ["uploadEstimatedTime"+elt.name]:0,
                                                                        ["packetDebit"+elt.name]:0,
                                                                    })
                                                                })
                                                            }
                                                            controller.close();
                                                        })
                                                    }).catch((err) => {
                                                        Swal.fire({
                                                            title: 'Attention',
                                                            text: "Votre session a expiré, veuillez vous reconnecter.",
                                                            icon: 'warning',
                                                            confirmButtonText: 'Ok'
                                                        })
                                                        //remove sessionStorage jwt
                                                        sessionStorage.removeItem("KAGESECUR_jwt")
                                                        self.props.navigate("/KageSecur/Accueil")
                                                    })
                                                }
                                            })
                                        }
                                        push();
                                    }
                                });
                            }
                        })

                    }else{
                        if(!self.state.cancelUpload){
                            setTimeout(()=>{
                                startUploadProcess()
                            },1000)
                        }
                    }
                })
            })
        }
    }

    deleteFromAcceptedFile(file){
        let acceptedFileClone = this.state.acceptedFile.slice();
        acceptedFileClone.splice(acceptedFileClone.indexOf(file),1)
        this.setState({
            acceptedFile:acceptedFileClone,
            ["cancel"+file.name]:true,
        })
    }

    verifyFileName(fileName){
        let canAppend = true
        this.state.acceptedFile.forEach(elt => {
            if (elt.name === fileName){
                canAppend = false
            }
        })
        return canAppend
    }

    getAllClient(){
        Axios.post(urlAxios+"getAllClients",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            let allClientsTemp = [], clientsOthersNodes = [];
            (response.data.resultat).forEach(client=>{
                if (client.thisSrv){
                    client.label = client.pseudo
                    allClientsTemp.push(client)
                }else{
                    client.label = client.pseudo
                    clientsOthersNodes.push(client)
                }
            })
            this.setState({
                allClients:allClientsTemp,
                allClientsDisplayed:allClientsTemp,
                allClientsOthersNodes:clientsOthersNodes
            })
        })
    }

    AddClientToSignataire(client){
        this.setState({clientChosenForSignataire:""})
        let listSignataireTemp = this.state.listSignataire
        if(!listSignataireTemp.includes(client)){
            listSignataireTemp.push(client)
            this.setState({
                listSignataire :listSignataireTemp
            })
        }else{
            Swal.fire({
                title: 'Utilisateur déjà présent !',
                text: "Vous ne pouvez pas ajouter deux fois le même utilisateur en tant que signataire.",
                target:"#dialogDisplayShare",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    RemoveClientToSignataire(client){
        let listSignataireTemp = this.state.listSignataire
        let indexClient = listSignataireTemp.findIndex(elt=>elt.pseudo === client)
        if(indexClient !== -1){
            listSignataireTemp.splice(indexClient,1)
            this.setState({
                listSignataire :listSignataireTemp
            })
        }
    }

    changeListAllClient(){ //Filtre
        let allClientToDisplay = []
        this.state.allClients.forEach(elt=>{
            if((elt.status === "client" && elt.abonnement === "Sans abonnement") && this.state.searchWithCSA){
                allClientToDisplay.push(elt)
            }else if ((elt.status === "client" && elt.abonnement !== "Sans abonnement") && this.state.searchWithC){
                allClientToDisplay.push(elt)
            }else if ((elt.status === "admin" || elt.status === "gestionnaire") && this.state.searchWithG){
                allClientToDisplay.push(elt)
            }
        })
        if (this.state.searchWithCSA === false && this.state.searchWithC === false && this.state.searchWithG === false){
            allClientToDisplay = this.state.allClients.slice()
        }
        this.setState({allClientsDisplayed:allClientToDisplay})
    }

    getContentTypeByExtension(extension) {
        extension= extension.toLowerCase()
        const contentType = {
            aac:"audio/aac",
            abw:"application/x-abiword",
            arc:"application/octet-stream",
            avi:"video/x-msvideo",
            azx:"application/vnd.amazon.ebook",
            bin:"application/octet-stream",
            bmp:"image/bmp",
            bz:"application/x-bzip",
            bz2:"application/x-bzip2",
            csh:"application/x-csh",
            css:"text/css",
            csv:"text/csv",
            doc:"application/msword",
            docx:"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            eot:"application/vnd.ms-fontobject",
            epub:"application/epub+zip",
            gif:"image/gif",
            htm:"text/html",
            html:"text/html",
            ico:"image/x-icon",
            ics:"text/calendar",
            jar:"application/java-archive",
            jpg:"image/jpeg",
            jpeg:"image/jpeg",
            js:"application/javascript",
            json:"application/json",
            mid:"audio/midi",
            midi:"audio/midi",
            mpeg:"video/mpeg",
            mpkg:"application/vnd.apple.installer+xml",
            odp:"application/vnd.oasis.opendocument.presentation",
            ods:"application/vnd.oasis.opendocument.spreadsheet",
            odt:"application/vnd.oasis.opendocument.text",
            oga:"audio/ogg",
            ogv:"video/ogg",
            ogx:"application/ogg",
            otf:"font/otf",
            png:"image/png",
            pdf:"application/pdf",
            ppt:"application/vnd.ms-powerpoint",
            pptx:"application/vnd.openxmlformats-officedocument.presentationml.presentation",
            rar:"application/x-rar-compressed",
            rtf:"application/rtf",
            mp4:"video/mp4",
            sh:"application/x-sh",
            svg:"image/svg+xml",
            swf:"application/x-shockwave-flash",
            tar:"application/x-tar",
            tif:"image/tiff",
            tiff:"image/tiff",
            ts:"application/typescript",
            ttf:"font/ttf",
            txt:"text/plain",
            vsd:"application/vnd.visio",
            wav:"audio/x-wav",
            weba:"audio/webm",
            webm:"video/webm",
            webp:"image/webp",
            woff:"font/woff",
            woff2:"font/woff2",
            xhtml:"application/xhtml+xml",
            xls:"application/vnd.ms-excel",
            xlsx:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            xml:"application/xml",
            xul:"application/vnd.mozilla.xul+xml",
            zip:"application/zip",
            "3gp":"video/3gpp",
            "3gp2":"video/3gpp2",
            "7z":"application/x-7z-compressed",
        }
        return contentType[extension] || 'application/octet-stream';
    }
    getIconForFileType = (fileName) => {
        const extension = fileName.split('.')[fileName.split('.').length - 1];
        const contentType = this.getContentTypeByExtension(extension).split('/')[0];

        if(extension === "zip"){
            return <FolderZip />;
        }else if(extension === "pdf"){
            return <img src={PdfIcon} style={{width:23}} alt="PDF Icon" />;
        }else{
            switch (contentType) {
                case 'image':
                    return <ImageIcon />;
                case 'video':
                    return <VideoIcon />;
                default:
                    return <DescriptionIcon />;
            }
        }
    }

    handleWindowDragEnter = (e) => {
        // Check if plain text is being dragged
        const hasText = Array.from(e.dataTransfer.types).includes("text/plain");
        if (!hasText) {
            if (!this.state.isDragging) {
                this.setState({ isDragging: true });
            }
        }
    };

    handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.isDragging) {
            this.setState({ isDragging: false });
        }
    }

    processDroppedFiles(acceptedFiles) {
        let acceptedFilesArray = []
        acceptedFiles.forEach((file)=>{
            // if(file.size >= sizeLimitFile){
            //     Toast.fire({
            //         icon: 'error',
            //         title: 'Le fichier est trop lourd (>10Go) !'
            //     })
            // }else
                if(file.name.includes("##")){
                Toast.fire({
                    icon: 'error',
                    title: 'Le nom du fichier ne doit pas comporter "##" !'
                })
            }else if (this.verifyFileName(file.name)){
                acceptedFilesArray.push(file)
                Toast.fire({
                    icon: 'success',
                    title: "Fichier(s) correctement déposé(s), prêt à l'enregistrement."
                })
            }
        })

        let finalAcceptedFiles = [...this.state.acceptedFile.slice(),...acceptedFilesArray]
        let self = this
        this.setState({acceptedFile:finalAcceptedFiles,virtualListHeight:finalAcceptedFiles.length*lineHeight, isDragging: false},()=>{
            let divScroll = document.getElementById("divScroll");
            if(divScroll){
                divScroll.addEventListener("scroll",function (scroll){
                    let firstVisibleIndex = parseInt(scroll.target.scrollTop / (self.state.uploadingFile ? lineHeight2 : lineHeight))
                    if(self.state.firstVisibleIndex !== firstVisibleIndex){
                        self.setState({firstVisibleIndex})
                    }
                })
            }

        })
    }

    addRecepteurPreuveviaMail(){
        if (this.state.recepteurProofName && this.state.recepteurProofEmail && (this.state.recepteurProofEmail).match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)) {
            let objectToPush = {recepteurName:this.state.recepteurProofName, recepteurEmail:this.state.recepteurProofEmail}
            let listRecepteurPreuveViaMailTemp = this.state.listRecepteurPreuveViaMail.slice()
            if(listRecepteurPreuveViaMailTemp.findIndex(recepteur => recepteur.recepteurName === this.state.recepteurProofName)=== -1 && listRecepteurPreuveViaMailTemp.findIndex(recepteur => recepteur.recepteurEmail === this.state.recepteurProofEmail)=== -1){
                listRecepteurPreuveViaMailTemp.push(objectToPush)
                this.setState({recepteurProofName:"",recepteurProofEmail:""})
            }else{
                Toast.fire({
                    title: "Ce récepteur est déjà présent dans la liste.",
                    target:"#dialogAddPreuveViaMail",
                    icon: 'warning'
                })
            }
            this.setState({listRecepteurPreuveViaMail:listRecepteurPreuveViaMailTemp})
        } else {
            Swal.fire({
                text: "Veuillez remplir les informations demandées correctement.",
                icon: 'warning',
                target:"#dialogAddPreuveViaMail",
                confirmButtonText: "Ok"
            }).then(() => {
                this.setState({recepteurProofName:"",recepteurProofEmail:""})
            })
        }
    }

    removeRecepteurPreuveviaMail(recepteurName){
        // let objectToPush = {recepteurName:this.state.recepteurProofName, recepteurEmail:this.state.recepteurProofEmail}
        let listRecepteurPreuveViaMailTemp = this.state.listRecepteurPreuveViaMail.slice()
        let indexToDelete = listRecepteurPreuveViaMailTemp.findIndex(recepteur => recepteur.recepteurName === recepteurName)
        listRecepteurPreuveViaMailTemp.splice(indexToDelete,1)
        this.setState({listRecepteurPreuveViaMail:listRecepteurPreuveViaMailTemp})
    }

    render() { //Affichage sur la page
        const classes = classesBar;
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                {(this.state.isDragging && !this.state.uploadingFile) && (
                    <Dropzone multiple={true} onDrop={(acceptedFiles) => this.processDroppedFiles(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                            <section style={{textAlign:"center", backgroundColor:"red"}}>
                                <div {...getRootProps()} onDragLeave={this.handleDragLeave} className="full-screen-message" style={{textAlign:"center"}}>
                                    <input {...getInputProps()} />
                                    {['top-left', 'top-right', 'bottom-left', 'bottom-right'].map(pos=>(
                                        <div style={{pointerEvents:"none"}} key={pos} className={"corner "+pos}/>
                                    ))}
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <p style={{fontSize:55, fontWeight:"bold",pointerEvents:"none", marginBottom:10}}>Déposez vos fichiers n'importe où sur l'écran</p>
                                        <p style={{fontSize:25, fontWeight:"bold",pointerEvents:"none"}}>(Dépôt d'un dossier dépose tous les fichiers à l'intérieur)</p>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}
                <Dialog
                    open={this.state.displayDialogSendProof}
                    onClose={()=>{this.setState({displayDialogSendProof: false,listRecepteurPreuveViaMail:[],phasePreuveViaMail:1})}}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                    id={"dialogAddPreuveViaMail"}
                >
                    <div style={{position:"relative", width:"100%"}}>
                        <Close onClick={()=>{this.setState({displayDialogSendProof: false,phasePreuveViaMail:1})}} style={{position:"absolute",right:0,cursor:"pointer"}} />
                    </div>
                    <DialogTitle>Envoyer le(s) fichier(s) par mail : </DialogTitle>
                    <DialogContent style={{display:this.state.showBrowserView&&"flex",flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
                        <form style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
                            {this.state.phasePreuveViaMail === 1 ? (
                                <>
                                    <TextField name={"recepteurProofName"} style={{marginBottom: 15,width:this.state.showBrowserView&&450}} value={this.state.recepteurProofName} onChange={(e)=>{this.handleChange(e)}} required={true} label="Nom du récepteur" variant="outlined" />
                                    <TextField name={"recepteurProofEmail"} style={{marginBottom: 15,width:this.state.showBrowserView&&450}} value={this.state.recepteurProofEmail} onChange={(e)=>{this.handleChange(e)}} required={true} label="Email du récepteur" variant="outlined" />
                                    <Button disabled={this.state.recepteurProofName === "" || this.state.recepteurProofEmail === ""} style={{backgroundColor:(this.state.recepteurProofName === "" || this.state.recepteurProofEmail === "" ? "#c7c7c7":"#52a8d9"),color:"white", margin:5}} variant={"contained"} onClick={()=>{this.addRecepteurPreuveviaMail()}}>Ajouter ce récepteur</Button>
                                    <h4 style={{marginTop:0}}>Liste des récepteurs :</h4>
                                    <TableContainer style={{maxHeight:200,overflowY:"auto"}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columnsListePreuveViaMail.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.listRecepteurPreuveViaMail.length === 0 && (
                                                    <p>Veuillez ajouter un récepteur ...</p>
                                                )}
                                                {this.state.listRecepteurPreuveViaMail.map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            {columnsListePreuveViaMail.map((column) => {
                                                                return (
                                                                    <TableCell key={column.id}>
                                                                        {column.id === "Action" ? (
                                                                            <Tooltip arrow={true} title={"Retirer ce récepteur."}>
                                                                                <Button onClick={()=>{this.removeRecepteurPreuveviaMail(row.recepteurName)}} name={row.recepteurName}> <CloseIcon/> </Button>
                                                                            </Tooltip>
                                                                        ):(
                                                                            row[column.id]
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div  className={"flexCenter"}>
                                        <Button disabled={this.state.listRecepteurPreuveViaMail.length === 0} style={{backgroundColor:"#52a8d9",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({phasePreuveViaMail:2})}}>Suivant</Button>
                                        <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({displayDialogSendProof: false})}}>Retour</Button>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className={"flexCenter"} >
                                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                            <h4 style={{marginTop:0}}>Pièces jointes (facultatif) :</h4>
                                            <Dropzone multiple={true} onDrop={(acceptedFiles) => {
                                                let size = 0;
                                                let arrayBufferPiecesJointes = this.state.arrayBufferPiecesJointes.slice()
                                                acceptedFiles.forEach((file,index) => {
                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        const binaryStr = reader.result
                                                        arrayBufferPiecesJointes.push({name:file.name,buffer:Buffer.from(binaryStr, "base64")})
                                                        if(acceptedFiles.length-1 === index){
                                                            this.setState({arrayBufferPiecesJointes:arrayBufferPiecesJointes},()=>{
                                                                arrayBufferPiecesJointes.forEach(elt=>{
                                                                    size+=elt.buffer.length
                                                                })
                                                                this.setState({totalSizePiecesJointes:size})
                                                            })
                                                        }
                                                    }
                                                    reader.readAsArrayBuffer(file)

                                                })
                                            }}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()} id={this.state.uploadingFile ? "nohover":"hoverMoving"} style={{padding:30,borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",marginBottom:5, color:"black", cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                            <input {...getInputProps()} />
                                                            <p>Déposez vos pièces jointes ici.</p>
                                                            <p>(factures / documents annexes)</p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    <div style={{width:this.state.showBrowserView&&450}}>
                                        {this.state.arrayBufferPiecesJointes.map(elt=>(
                                            <p className={"flexCenter"} style={{margin:3}}>{elt.name} <Close style={{cursor:"pointer"}} onClick={()=>{
                                                let arrayBufferPiecesJointes = this.state.arrayBufferPiecesJointes.slice()
                                                arrayBufferPiecesJointes.splice(arrayBufferPiecesJointes.findIndex(file => file.name === elt.name),1)
                                                this.setState({arrayBufferPiecesJointes:arrayBufferPiecesJointes})
                                            }}/></p>
                                        ))}
                                    </div>
                                    {this.state.gestionnaire === true && (
                                        <p style={{marginTop:0}}>Envoyer le pdf de publicité de Kagesecur<Checkbox
                                            checked={this.state.sendPub}
                                            onChange={()=>{this.setState({sendPub:!this.state.sendPub})}}
                                            style ={{color: "#52A8D9FF"}}
                                        /></p>
                                    )}
                                    <div  className={"flexCenter"}>
                                        <Button style={{backgroundColor:"#52a8d9",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({displayDialogSendProof:false})}}>Terminé</Button>
                                        <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({phasePreuveViaMail: 1})}}>Retour</Button>
                                    </div>
                                </>
                            )}
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayWhoSigns}
                    onClose={()=>{this.setState({displayWhoSigns: false,listSignataire:[],searchWithG:false,searchWithC:false,searchWithCSA:false})}}
                    id={"dialogDisplayShare"}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                >
                    <DialogTitle>Signataire(s) du document :</DialogTitle>
                    <DialogContent style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center",overflow:"hidden"}}>
                        <Paper style={{margin:10,padding:10, paddingTop:0,width:"100%"}}>
                            <div style={{width:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
                                <div style={{width:"100%", display:"flex", justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                    <Autocomplete
                                        disablePortal
                                        noOptionsText={'Aucun résultat'}
                                        ListboxProps={{ style: { maxHeight: 100 } }}
                                        options={this.state.allClientsDisplayed}
                                        value={this.state.clientChosenForSignataire}
                                        sx={{ width: "100%", marginBottom:0 }}
                                        onChange={(e, newval, reason) => {
                                            this.setState({clientChosenForSignataire:newval})
                                        }}
                                        filterOptions={filterOptions}
                                        renderInput={(params) =>
                                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                <TextField variant={"outlined"} style={{marginBottom:0,width:"80%"}} {...params} label="Rechercher un utilisateur..." />
                                                <Tooltip arrow={true} title={"Ecrivez dans ce champs de texte l'identifiant de la personne à qui vous voulez demander une signature"}>
                                                    <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                                </Tooltip>
                                            </div>
                                        }
                                    />
                                    <div style={{fontSize:12,marginBottom:0,marginTop:5,display:"flex",flexWrap:"wrap",width:"80%"}}>
                                        <p style={{margin:0,marginRight:3}}>Recherche parmi : </p>
                                        {((!this.state.searchWithG && !this.state.searchWithC && !this.state.searchWithCSA )|| (this.state.searchWithG && this.state.searchWithC && this.state.searchWithCSA)) ? <p style={{margin:0,fontWeight:"bold"}}>Tous les utilisateurs</p> :(
                                            <>
                                                <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{this.state.searchWithG && " Gestionnaires"}</p>
                                                <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{(this.state.searchWithG && this.state.searchWithC) ? " - Clients" : this.state.searchWithC && " Clients"}</p>
                                                <p style={{margin:0,fontWeight:"bold"}}>{((this.state.searchWithG || this.state.searchWithC) && this.state.searchWithCSA) ? " - Clients sans abonnements" : this.state.searchWithCSA && " Clients sans abonnements"}</p>
                                            </>
                                        )}
                                    </div>
                                    <div style={{width:"100%", display:"flex", justifyContent:"flex-start",alignItems:"center",flexWrap:"wrap"}}>
                                        <Button onClick={()=>{this.setState({searchWithG:!this.state.searchWithG},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithG ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 0" }}>Gestionnaires</Button>
                                        <Button onClick={()=>{this.setState({searchWithC:!this.state.searchWithC},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithC ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 2px" }}>Clients</Button>
                                        <Button onClick={()=>{this.setState({searchWithCSA:!this.state.searchWithCSA},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithCSA ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 0 10px 2px"}}>Clients sans abonnement</Button>
                                    </div>

                                </div>

                                <Button disabled={this.state.clientChosenForSignataire === null} style={{backgroundColor:this.state.clientChosenForSignataire === null ? "white":"#52A8D9FF",color:this.state.clientChosenForSignataire === null ? "lightgrey":"white", margin:5,height:47}} variant={"contained"} onClick={()=>{this.AddClientToSignataire(this.state.clientChosenForSignataire)}} >Ajouter</Button>
                            </div>

                            <hr style={{width:"100%",marginTop:0}}/>
                            <div style={{maxHeight:200,overflowY:"auto"}}>
                                <h3 style={{textAlign:"center",fontWeigth:"normal"}}>Liste des signataires :</h3>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableBody>
                                            {this.state.listSignataire.length === 0 && (
                                                <p style={{textAlign:"center"}}>Aucun signataire...</p>
                                            )}
                                            {this.state.listSignataire.map(elt=>(
                                                <TableRow hover role="checkbox" tabIndex={-1} key={elt.pseudo}>
                                                    {columnsClientShare.map((column) => {
                                                        return (
                                                            <>
                                                                {column.id === "Action" ?
                                                                    <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                        <Tooltip arrow={true} title={"Retirer cet utilisateur des signataires."}>
                                                                            <Button onClick={()=>{this.RemoveClientToSignataire(elt.pseudo)}}>
                                                                                <Close/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </TableCell>

                                                                    :
                                                                    <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                        {elt[column.id]}
                                                                    </TableCell>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                        <Button disabled={this.state.listSignataire.length === 0} variant={"contained"} style={{textTransform:"none",fontSize:14,backgroundColor:this.state.listSignataire.length ===0?"lightGrey":"#52A8D9FF",color:this.state.listSignataire.length ===0 ? "white":"white",margin:10 }} onClick={()=>{this.setState({displayWhoSigns:false})}} >
                            <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:2, fontSize:15, color:"#FFFFFF"}}>
                                Valider la liste
                            </p>
                        </Button>
                    </DialogContent>
                </Dialog>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                    {this.state.typeSignature ? (
                        <React.Fragment>
                            <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110, display:!this.state.showBrowserView&&"none"}} src={require("../assets/DocumentSecureOrange.png")} alt="imageDocument"/>
                            <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20, display:!this.state.showBrowserView&&"none"}} src={require("../assets/auction.png")} alt="imageAuction"/>
                            <h1 style={{marginTop:50, fontSize:!this.state.showBrowserView&&24,textAlign:!this.state.showBrowserView&&"center"}}>Enregistrez <span style={{color:"#52a8d9"}}>votre document</span> avec la <span style={{color:"#f18f29"}}>signature d'un tiers</span></h1>

                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110, display:!this.state.showBrowserView&&"none"}} src={require("../assets/document.png")} alt="imageDocument"/>
                            <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20, display:!this.state.showBrowserView&&"none"}} src={require("../assets/auction.png")} alt="imageAuction"/>
                            <h1 style={{marginTop:50, fontSize:!this.state.showBrowserView&&24,textAlign:!this.state.showBrowserView&&"center"}}>Enregistrez <span style={{color:"#52a8d9"}}>votre document</span> avec <span style={{color:"#f18f29"}}>votre signature</span></h1>

                        </React.Fragment>
                    )}
                    <div className={"flexCenter"} style={{width:"100%",height:"70%", justifyContent:!this.state.showBrowserView && "flex-start", flexDirection:!this.state.showBrowserView && "column"}}>
                        <div style={{width:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Dropzone disabled={this.state.uploadingFile} multiple={true} onDrop={(acceptedFiles) => this.processDroppedFiles(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section style={{textAlign:"center"}}>
                                        <div {...getRootProps()} id={this.state.uploadingFile ? "nohover":"hoverMoving"} style={{padding:"10vw", paddingTop:"8vw", paddingBottom:"8vw",borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold", cursor:this.state.uploadingFile ? "default":"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                            <input {...getInputProps()} />
                                            <>
                                                <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb"}}>Déposez vos fichiers ici.</h3>
                                                <p style={{textAlign:"center", color:this.state.uploadingFile && "#bbbbbb", fontSize:12, margin:0, fontWeight:"normal", fontStyle:"italic"}}>(Privilégiez les .pdf plutôt que les .docx)</p>
                                                <p style={{textAlign:"center", color:this.state.uploadingFile && "#bbbbbb", fontSize:12, margin:0, fontWeight:"normal", fontStyle:"italic"}}>(Taille maximum d'un fichier : 10Go)</p>
                                                <p style={{textAlign:"center", color:this.state.uploadingFile && "#bbbbbb", fontSize:12, margin:0, fontWeight:"normal", fontStyle:"italic"}}>Dépôt de dossier dépose tous les fichiers à l'intérieur</p>
                                            </>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div style={{width:this.state.showBrowserView ? "50%":"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            {this.state.acceptedFile.length>0 ?
                                <>
                                    {/*{this.state.uploadingFile && <p style={{fontStyle:"italic"}}>Le temps d'attente dépend de la taille du fichier et de votre connexion</p>}*/}
                                    <h2 style={{textAlign:"center"}}>Fichier(s) déposé(s) : {this.state.uploadingFile && (`${this.state.totalFileDone}/${this.state.acceptedFile.length}`)}</h2>
                                    <p style={{textAlign:"center", color:"#000", fontWeight:"bold", width:"85%", fontSize:14}}>Pensez à ne pas fermer le navigateur et à laisser la page en premier plan pour avoir des temps de chargements optimaux</p>
                                    {this.state.surcharge && <p style={{textAlign:"center", color:"#ff9b04", fontWeight:"bold", width:"85%", fontSize:13}}>En raison d'une forte activité sur la blockchain Kagesecur, le traitement de vos fichiers peut être légèrement prolongée.</p>}
                                    <div id={"divScroll"} style={{height:"100%", overflowY:"auto", width:"85%"}}>
                                        <div className={"flexCenter"} style={{minHeight:this.state.acceptedFile.length*(this.state.uploadingFile ? lineHeight2:lineHeight), position:"relative", width:"100%"}}>
                                            {this.state.acceptedFile.map((file,index)=>(
                                                <>
                                                    {this.state.firstVisibleIndex >= index-12 && this.state.firstVisibleIndex <= index+2 && (
                                                        <div className={"flexCenter"} style={{flexDirection:"column",padding:10, top:index*(this.state.uploadingFile ? lineHeight2:lineHeight), position:"absolute",width:"90%"}}>
                                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                                <div style={{marginRight:5}}>
                                                                    {this.getIconForFileType(file.name)}
                                                                </div>
                                                                    <p style={{margin:0, maxWidth:"98%", overflow:"hidden",textOverflow:"ellipsis"}}>{file.name} <span style={{marginBottom:0,fontSize:11}}>({(file.size/1000000).toFixed(2)}Mo)</span></p>                                                                {!this.state.uploadingFile && (
                                                                    <Tooltip placement="left" arrow={true} title={"Retirer ce document"}>
                                                                        <Close onClick={()=>{this.deleteFromAcceptedFile(file)}} style={{cursor:"pointer",marginLeft:10}}/>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                            {this.state.uploadingFile && (
                                                                <div className={"flexCenter"} style={{width:'100%'}}>
                                                                    <div style={{display:"flex",justifyContent:"center",flexDirection:"column",width:"100%"}}>
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: this.state["fileSent" + file.name] === true ? '#9de88c' : (this.state["fileSent" + file.name] !== "inProgress" ? (this.state["fileSent" + file.name] === undefined ? '#87CEFA' : '#ec6f6f') : '#87CEFA'),
                                                                                margin: 25,
                                                                                marginTop: 5,
                                                                                marginBottom: 10,
                                                                                height: 20,
                                                                                borderRadius: 10,
                                                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                                                overflow: 'hidden'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: `${Math.round(parseFloat(this.state["progressUpload" + file.name])) || 0}%`,
                                                                                    height: '100%',
                                                                                    backgroundColor: this.state["fileSent" + file.name] === true ? '#2ed000' : (this.state["fileSent" + file.name] !== "inProgress" ? (this.state["fileSent" + file.name] === undefined ? '#4a5cc2' : '#ff0f0f') : '#4a5cc2'),
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {this.state["uploadEstimatedTime"+file.name] === undefined ? (
                                                                            <p style={{fontSize:12,fontStyle:"italic",marginTop:0, textAlign:"center"}}>L'upload va bientôt démarrer...</p>
                                                                        ):(
                                                                            <>
                                                                                {(this.state["uploadEstimatedTime"+file.name] === 0 && this.state["packetDebit"+file.name] === 0) ? (
                                                                                    <p style={{fontSize:12,fontStyle:"italic",marginTop:0, textAlign:"center"}}>Upload du fichier terminé {this.state["fileSent" + file.name] === "inProgress" && ("- Vérification en cours...")}</p>
                                                                                ):(
                                                                                    <div className={"flexCenter"}>
                                                                                        <p style={{fontSize:12,fontStyle:"italic",marginTop:0}}>Temps restant ≈ {this.state["uploadEstimatedTime"+file.name]>60 ? (parseInt(this.state["uploadEstimatedTime"+file.name]/60)+"m"):this.state["uploadEstimatedTime"+file.name]+"s"}</p>
                                                                                        <p style={{fontSize:12,fontStyle:"italic",marginTop:0, marginLeft:5}}>Vitesse d'upload ≈ {(this.state["packetDebit"+file.name])} Mo/s</p>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}



                                                                    </div>
                                                                    <p style={{margin:25, marginTop:5, marginLeft:5,marginRight:5}} >{Math.round(parseFloat(this.state["progressUpload" + file.name])) || 0}%</p>
                                                                    {this.state["fileSent" + file.name] ?
                                                                        <>
                                                                            {this.state["fileSent" + file.name] === "inProgress" ?
                                                                                <CircularProgress variant={"indeterminate"} disableShrink={true} style={{margin:25, marginTop:5,marginLeft:5, display:"inherit"}} size={30}/>
                                                                                :
                                                                                <>
                                                                                    {this.state["fileSent" + file.name] === true ?
                                                                                        <Tooltip placement="left"  arrow={true} title={"Votre fichier à bien été enregistré dans la blockchain."}>
                                                                                            <Check style={{margin:25, marginTop:5,marginLeft:5, color:"#00E331FF"}}/>
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip placement="left" arrow={true} title={this.state["fileSent"+file.name]}>
                                                                                            <Close style={{margin:25, marginTop:5,marginLeft:5, color:"#e30000FF"}}/>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <CircularProgress variant={"indeterminate"} disableShrink={true} style={{margin:25, marginTop:5,marginLeft:5, display:"inherit"}} size={30}/>
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    {this.state.uploadingFile ? (
                                        <>
                                            {this.state.processusDone ?
                                                <Button style={{backgroundColor:"#52a8d9", marginTop:10, textTransform:"none", color:"#FFFFFF"}} onClick={()=>{
                                                    this.setState({...this.setInitialStates()})
                                                }}>Recharger la page <Refresh/></Button>
                                                :
                                                <Button style={{backgroundColor:"#52a8d9", marginTop:10, textTransform:"none", color:"#FFFFFF"}} onClick={()=>{
                                                    this.setState({cancelUpload:true, uploadingFile:false,isDragging:false, totalFileDone:0,acceptedFile:[]})
                                                }}>Arrêter le processus<Cancel style={{marginLeft:5}} /></Button>
                                            }
                                        </>
                                    ):(
                                        <>
                                            {this.state.typeSignature ? (
                                                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start",justifyContent:"center", width:"80%"}}>
                                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"flex-start"}}>
                                                        <Button variant={"contained"} style={{marginTop:5,backgroundColor:"#52a8d9", marginBottom:10, textTransform:"none"}} onClick={()=>{this.setState({displayWhoSigns:true})}} >
                                                            <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>
                                                                Gérer les signataires
                                                            </p>
                                                        </Button>
                                                        <p style={{margin:5, fontSize:20, fontWeight:"600"}}>Signataire : </p>
                                                        {this.state.listSignataire.length===0 && (<p style={{margin:0, marginLeft:5, fontSize:20, fontWeight:"600"}}> ... </p>)}
                                                        {this.state.listSignataire.map((elt,index)=>(
                                                            <>
                                                                <h3 style={{fontWeight:100, margin:5}}> {elt.pseudo} </h3>
                                                                <h3 style={{fontWeight:100, margin:5}}>{index !== this.state.listSignataire.length-1 && ("-")}</h3>
                                                            </>
                                                        ))}
                                                    </div>
                                                    <div style={{width:"100%", display:"flex",alignItems:"center"}}>
                                                        <p style={{margin:0, fontSize:20, fontWeight:"600"}}>Envoyer ce(s) fichier(s) par email directement : </p>
                                                        <label style={{marginLeft:5}} className="switchKS">
                                                            <input checked={this.state.sendProofDirect === true} onChange={()=>{
                                                                let newProofDirect = !this.state.sendProofDirect
                                                                this.setState({sendProofDirect:newProofDirect,checkedExterne:(newProofDirect===true ? true : this.state.checkedExterne)})
                                                            }} type="checkbox"/>
                                                            <span className="sliderKS round"/>
                                                        </label>
                                                    </div>
                                                    {this.state.sendProofDirect && (
                                                        <div className={"flexCenter"} style={{width:"100%", justifyContent:"flex-start"}}>
                                                            <Button variant={"contained"} style={{width:"fit-content",marginTop:10,backgroundColor:"#52a8d9", textTransform:"none"}} onClick={()=>{this.setState({displayDialogSendProof:true, phasePreuveViaMail:1})}}>
                                                                <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>
                                                                    Gérer l'envoi de l'email
                                                                </p>
                                                            </Button>
                                                            <div className={"flexCenter"} style={{flexWrap:"wrap"}}>
                                                                <h3 style={{margin:5, fontWeight:"600"}}>Récepteurs : </h3>
                                                                {this.state.listRecepteurPreuveViaMail.length===0 && (<p style={{margin:0, marginLeft:5, fontSize:16, fontWeight:"100"}}> ... </p>)}
                                                                {this.state.listRecepteurPreuveViaMail.map((elt, index)=>(
                                                                    <>
                                                                        <h3 style={{fontWeight:100, margin:5}}>{elt.recepteurName}</h3>
                                                                        <h3 style={{fontWeight:100, margin:5}}>{index< (this.state.listRecepteurPreuveViaMail.length-1) && ("-")}</h3>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.listSignataire.length> 0 && (
                                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", width:"80%", marginTop:15}}>
                                                            <Button disabled={this.state.uploadingFile} variant={"contained"} style={{marginTop:5,visibility:this.state.acceptedFile.length>0 ? "visible":"hidden",backgroundColor:(this.state.uploadingFile) ? "#A9A9A9":"#52a8d9", textTransform:"none", marginBottom:!this.state.showBrowserView && 20}} onClick={()=>{this.sendEmpreinte()}}>
                                                                <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:18, color:"#FFFFFF"}}>
                                                                    Enregistrer {this.state.sendProofDirect && "et envoyer"} le(s) document(s)
                                                                </p>
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            ):(
                                                <div style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", width:"80%"}}>
                                                    <div style={{width:"100%", display:"flex",alignItems:"center", marginBottom:10}}>
                                                        {this.state.pf ===""&&this.state.pf ===""&&(
                                                            <>
                                                                <p style={{margin:0, fontSize:20, fontWeight:"600"}}>Archiver le(s) fichier(s) sur les serveurs : </p>
                                                                <label style={{marginLeft:5}} className="switchKS">
                                                                    <input checked={this.state.checkedExterne === true} onChange={()=>{
                                                                        let newChecked = !this.state.checkedExterne
                                                                        this.setState({checkedExterne:newChecked, sendProofDirect:(newChecked===false ? false : this.state.sendProofDirect) })
                                                                    }} type="checkbox"/>
                                                                    <span className="sliderKS round"/>
                                                                </label>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div style={{width:"100%", display:"flex",alignItems:"center"}}>
                                                        <p style={{margin:0, fontSize:20, fontWeight:"600"}}>Envoyer ce(s) fichier(s) par email directement : </p>
                                                        <label style={{marginLeft:5}} className="switchKS">
                                                            <input checked={this.state.sendProofDirect === true} onChange={()=>{
                                                                let newProofDirect = !this.state.sendProofDirect
                                                                this.setState({sendProofDirect:newProofDirect,checkedExterne:(newProofDirect===true ? true : this.state.checkedExterne)})
                                                            }} type="checkbox"/>
                                                            <span className="sliderKS round"/>
                                                        </label>
                                                    </div>
                                                    {this.state.sendProofDirect && (
                                                        <div className={"flexCenter"} style={{width:"100%", justifyContent:"flex-start"}}>
                                                            <Button variant={"contained"} style={{width:"fit-content",marginTop:10,backgroundColor:"#52a8d9", textTransform:"none"}} onClick={()=>{this.setState({displayDialogSendProof:true, phasePreuveViaMail:1})}}>
                                                                <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>
                                                                    Gérer l'envoi de l'email
                                                                </p>
                                                            </Button>
                                                            <div className={"flexCenter"} style={{flexWrap:"wrap"}}>
                                                                <h3 style={{margin:5, fontWeight:"600"}}>Récepteurs : </h3>
                                                                {this.state.listRecepteurPreuveViaMail.length===0 && (<p style={{margin:0, marginLeft:5, fontSize:16, fontWeight:"100"}}> ... </p>)}
                                                                {this.state.listRecepteurPreuveViaMail.map((elt, index)=>(
                                                                    <>
                                                                        <h3 style={{fontWeight:100, margin:5}}>{elt.recepteurName}</h3>
                                                                        <h3 style={{fontWeight:100, margin:5}}>{index< (this.state.listRecepteurPreuveViaMail.length-1) && ("-")}</h3>
                                                                    </>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    )}
                                                    <Button disabled={this.state.uploadingFile} variant={"contained"} style={{width:"100%",marginTop:10,visibility:this.state.acceptedFile.length>0 ? "visible":"hidden",backgroundColor:(this.state.uploadingFile) ? "#A9A9A9":"#52a8d9", textTransform:"none", marginBottom:!this.state.showBrowserView && 20}} onClick={()=>{this.sendEmpreinte()}}>
                                                        <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>
                                                            Enregistrer {this.state.sendProofDirect && "et envoyer"} le(s) document(s)
                                                        </p>
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                                :
                                <>
                                    {this.state.pf!==""? (
                                        <>
                                            <h3>Vos fichiers seront archivés dans le dossier : </h3>
                                            <h4 style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <p style={{ fontStyle: "italic", fontWeight: 500 }}>... </p>
                                                {this.state.pf.split('/').slice(-2).map((item, index) => (
                                                    <span className={"flexCenter"} key={index} style={{ maxWidth: "45%", width: "45%", overflow: "hidden", textOverflow: "ellipsis", justifyContent: "flex-start", whiteSpace: "nowrap" }}>
                                                        <span style={{ margin: 5 }}>/</span>
                                                        <FolderIcon style={{ marginRight: 5 }} />
                                                        <span className={"flexCenter"} style={{ maxWidth: "95%", width: "95%", overflow: "hidden", textOverflow: "ellipsis", justifyContent: "flex-start", whiteSpace: "nowrap" }}>
                                                            {item}
                                                        </span>
                                                    </span>
                                                ))}
                                            </h4>
                                            <Button style={{backgroundColor:"#52a8d9", marginTop:10, textTransform:"none", color:"#FFFFFF"}} onClick={()=>{
                                                this.props.navigate("/Kagesecur/TableauDeBord?pf="+this.state.pf)
                                            }}>Retour en arrière <KeyboardReturn/></Button>
                                        </>

                                    ):(
                                        <>
                                            {this.state.typeSignature ? (
                                                <>
                                                    <h3>Fonctionnement de l'enregistrement avec la signature d'un tiers :</h3>
                                                    <p style={{fontSize:17, margin:20, marginRight: 80}}>Une fois vos documents déposés, vous pourrez les transférer au serveur qui les stockera de manière <span style={{color:"#52a8d9", fontWeight:"bold"}}>sécurisée</span>. Ces fichiers auront une valeur <span style={{color:"#52a8d9", fontWeight:"bold"}}>probatoire</span> une fois qu'un (ou plusieurs) signataire les aura validés. Vous pourrez alors envoyer un certificat prouvant votre possession de ce document.</p>
                                                </>
                                            ):(
                                                <>
                                                    <h3>Fonctionnement de l'enregistrement avec votre signature :</h3>
                                                    <p style={{fontSize:17, margin:20, marginRight: 80}}>Une fois vos documents déposés, vous pourrez les transférer au serveur qui les stockera de manière <span style={{color:"#52a8d9", fontWeight:"bold"}}>sécurisée</span>. Ces fichiers n'auront <span style={{color:"#52a8d9", fontWeight:"bold"}}>aucune</span> valeur probatoire puisqu'aucun signataire ne le vérifiera, vous pourrez toujours demander une validation ultérieurement.</p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UploadFile)