import Axios from "axios";
import React, {Component} from "react";
import {
    Autocomplete,
    Breadcrumbs,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
    AccountTree,
    ArrowBack,
    ArrowDownward,
    ArrowUpward,
    Check,
    Close,
    CloudUpload,
    CreateNewFolder,
    Delete,
    Description,
    DriveFolderUpload,
    Edit,
    FactCheck,
    FolderShared,
    FolderSpecial,
    FolderZip,
    Gavel,
    Mail,
    Menu as MenuIcon,
    Notifications,
    QuestionMark,
    Save,
    Visibility,
    VisibilityOff
} from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import PdfIcon from '../assets/PDFIcon.png';
import DescriptionIcon from "@mui/icons-material/Description";
import SecurityIcon from "@mui/icons-material/Security";
import {baseUrl, urlAxios, urlAxiosCore} from './GetURL';

import Swal from "sweetalert2";
import {isMobile} from "react-device-detect";
import Dropzone from "react-dropzone";
import socketInstance from "./SocketClient";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {createFilterOptions} from '@mui/material/Autocomplete';
import jwt_decode from "jwt-decode";
import {askPermToUpload, uploadChunk} from "../KageSecur/SharedUploadFunctions";
import {withRouter} from '../shared/NavigationUtils';

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const columnsListeSignature = [
        { id: 'Date', label: 'Date', minWidth: 75 },
        { id: 'pseudo', label: 'Signataire', minWidth: 150 },
        { id: 'Action', label: 'Action', minWidth: 20 }
    ],
    columnsListePreuveViaMail = [
        { id: 'recepteurName', label: 'Identifiant', minWidth: 75 },
        { id: 'recepteurEmail', label: 'Email', minWidth: 150 },
        { id: 'Action', label: 'Action', minWidth: 20 }
    ],
    columnsClientShare =[
        { id: 'pseudo', label: 'Nom', minWidth: 120 },
        { id: 'Action', label: 'Supprimer', minWidth: 50,maxWidth:50 },
    ]

let socketIO, oldResearch = "";

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    ignoreAccents: true,
    stringify: (option) => `${option.pseudo} ${option.email}`,
    trim: true,
});

let jwtDecoded = {}

class FileManagerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            archiFiles:[],
            toDisplay:[],
            recherche:'',
            rechercheDisplay:"",
            breadcrumbsDisplay:[],
            selectedFiles:[],
            clear:"none",
            searchFinished:true,
            downloadFinished:true,
            listNodes:[],
            previsuFile:null,
            dialogPrevisuOpen:false,
            displayDialogInfo:false,
            showBrowserView:true,
            emailProofClient:"",
            nomProofClient:"",
            passSearchAnyway:false,
            anchorEl: null,
            allValidateurs:[],
            validateursOthersNodes:[],
            messageNewSignature:"",
            displayCreateFolderLine:false,
            newFolderName:"",
            newFolderRename:"",
            ctrlPressed:false,
            xPressed:false,
            vPressed:false,
            fileSelectedIsFolder:false,
            displayMoveFileChoice:false,
            fileClipboardToMove:{},
            fileClipboardToRename:{},
            fileClipboardToShare:{},
            displayShareFolder:false,
            allClients:[],
            clientChosenForShare:null,
            contentOfSharedFolder:null,
            freeClient:false,
            displaySkeleton:true,
            recepteurProofEmail:"",
            recepteurProofName:"",
            displayDialogSendProof:false,
            sendPub:false,
            arrayBufferPiecesJointes:[],
            totalSizePiecesJointes:0,
            displayDialogUploadFile:false,
            disableMenu:false,
            disableButtonMenu:false,
            progressDownloadFile:0,
            showSaveFilePickerCanceled:false,
            listSignataire :[],
            listShare:[],
            searchWithG:false,
            searchWithC:false,
            searchWithCSA:false,
            allClientsDisplayed:[],
            allClient:[],
            listAllSignatureEnCours:[],
            notifications:[],
            notReadNotif:0,
            showNotifDiv:false,
            lastDisplayOfNotif:0,
            clientChosenForSignataire:null,
            listRecepteurPreuveViaMail:[],
            phasePreuveViaMail:1,
            displayDialogUploadArchivage:false,
            reUploadingFile:false,
            reUploadingSignatureFile:false,
            resultatReSend:"",
            resultatSignatureReSend:"",
            fileToResend:null,
            acceptedFileArchive:null,
            acceptedFileSignature:null,
            dialogDemandeSignaturePhase:1,
            anonyme:false,
            sortStatus:"nom-down",
            firstVisibleIndex:0,
            virtualListHeight:0,
            onlyClient:true
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    handleContextMenu = (e) => {
        e.preventDefault()
        const contextMenu = document.getElementById("menuRightClick")
        const {clientX : mouseX, clientY:mouseY} = e
        let trueX, trueY
        // e.view.innerHeight / innerWidth = taille de l'écran
        // contextMenu.offsetHeight / offsetWidth = taille du menu
        trueY = mouseY+contextMenu.offsetHeight > e.view.innerHeight ? mouseY-(mouseY+contextMenu.offsetHeight-e.view.innerHeight):mouseY
        trueX = mouseX+contextMenu.offsetWidth > e.view.innerWidth ? mouseX-(mouseX+contextMenu.offsetWidth-e.view.innerWidth):mouseX
        //On vérifie qu'on clique bien sur une ligne
        if(typeof e.target.className === "string" && e.target.className.includes("divClickDeselect")){
            this.state.selectedFiles.forEach(elt=>{
                this.setState({
                    ["selected"+elt.path]:false
                })
            })
            if(e.target.offsetParent !== contextMenu){
                contextMenu.style.transform = `scale(0)`
                contextMenu.style.visibility = `hidden`
            }
            this.setState({selectedFiles:[],fileSelectedIsFolder:false})
        }
        if(e.target.closest(".selectableLineTDB") !== null || e.target.id.includes("divScroll")){
            contextMenu.style.transition = `transform .3s cubic-bezier(.25,.8,.25,1);`
            contextMenu.style.top = `${trueY}px`
            contextMenu.style.left = `${trueX}px`
            contextMenu.style.transform = `scale(1)`
            contextMenu.style.visibility = `visible`
        }
    }

    handleAllClick = (e) => {
        const contextMenu = document.getElementById("menuRightClick")
        if(contextMenu){
            if(e.target.offsetParent !== contextMenu){
                contextMenu.style.transform = `scale(0)`
                contextMenu.style.visibility = `hidden`
            }
            if(typeof e.target.className === "string" && e.target.className.includes("divClickDeselect")){
                this.state.selectedFiles.forEach(elt=>{
                    this.setState({
                        ["selected"+elt.path]:false
                    })
                })
                this.setState({selectedFiles:[],fileSelectedIsFolder:false})
            }
        }

    }

    updateJwt(jwt){
        jwtDecoded = jwt_decode(jwt)
        if(jwtDecoded.gestionnaire && jwtDecoded.thisSrv === false){
            jwtDecoded.gestionnaire = false //Si c'est pas notre serveur on fait comme si le admin est un client.
            jwtDecoded.displayHeaderGestionnaire = true
            Toast.fire({
                title: "Connexion sur un autre node : affichage client.",
                icon: 'info',
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizePage)
        window.removeEventListener("click", this.handleAllClick)
        window.removeEventListener("contextmenu", this.handleContextMenu);
    }


    componentDidMount() {

        let self = this;
        let divScroll = document.getElementById("divScroll");
        // let allDisplayedFiles
        divScroll.addEventListener("scroll",function (scroll){
            // Utile (à tester) pour gérer des lignes de hauteur variable, mais ici toutes les lignes font 54px
            // if(scroll.target.firstChild.children !== allDisplayedFiles){ //Pour ne pas passer 50x dans cette fonction dès qu'on scroll
            //     allDisplayedFiles = scroll.target.firstChild.children
            //     for (let i = 0; i < allDisplayedFiles.length; i++) {
            //         if(allDisplayedFiles[i].offsetHeight > 54){
            //             self.setState({virtualListHeight:self.state.virtualListHeight+(allDisplayedFiles[i].offsetHeight-54)})
            //         }
            //     }
            // }
            let firstVisibleIndex = parseInt(scroll.target.scrollTop / 54)
            if(self.state.firstVisibleIndex !== firstVisibleIndex){
                self.setState({firstVisibleIndex})
            }
        })
        jwtDecoded=sessionStorage.getItem(this.props.app+"_jwt") ? jwt_decode(sessionStorage.getItem(this.props.app+"_jwt")) : {}
        if(jwtDecoded.gestionnaire && jwtDecoded.thisSrv === false){
            jwtDecoded.gestionnaire = false //Si c'est pas notre serveur on fait comme si le admin est un client.
            jwtDecoded.displayHeaderGestionnaire = true
            Toast.fire({
                title: "Connexion sur un autre node : affichage client.",
                icon: 'info',
            })
        }
        if(isMobile || document.body.offsetWidth <= 1150){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        window.addEventListener("contextmenu", this.handleContextMenu); //Right click
        window.addEventListener("click", this.handleAllClick); //Left click

        //Gestion de la touche ctrl pour selectionner des fichiers
        let oldKey = null
        let shiftPressed = false
        let ctrlPressed = false
        let xPressed = false
        let vPressed = false

        document.addEventListener('keydown', (event) => {
            if(event.keyCode === 16){
                if(oldKey !== event.keyCode){
                    this.setState({shiftPressed:true})
                    shiftPressed = true
                    oldKey = event.keyCode
                }
            }
            if(event.keyCode === 17){
                if(oldKey !== event.keyCode){
                    this.setState({ctrlPressed:true})
                    ctrlPressed = true
                    oldKey = event.keyCode
                }
            }
            if(event.keyCode === 88){
                if(oldKey !== event.keyCode){
                    this.setState({xPressed:true})
                    xPressed = true
                    oldKey = event.keyCode
                }
            }
            if(event.keyCode === 86){
                if(oldKey !== event.keyCode){
                    this.setState({vPressed:true})
                    vPressed = true
                    oldKey = event.keyCode
                }
            }
            if(ctrlPressed && xPressed){ //CTRL X
                if(!(this.state.selectedFiles.length===0 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient || (this.state.selectedFiles.findIndex(elt=>elt.name === "#shared#Dossiers partagés") !== -1) || this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés"))){
                    this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles,["selected"+this.state.selectedFiles[0].path]:this.state["selected" + this.state.selectedFiles[0].path] !== true})
                    Toast.fire({
                        title: "Naviguez dans le dossier de votre choix (double clic) et cliquez sur 'Déplacer ici'.",
                        icon: 'info',
                    })
                }
            }
            if(ctrlPressed && vPressed){ //CTRL V
                if(this.state.displayMoveFileChoice){
                    this.moveFileOrFolder()
                }
            }
        })
        document.addEventListener('keyup', (event) => {
            if(event.keyCode === 16){
                oldKey=null
                this.setState({shiftPressed:false})
                shiftPressed = false
            }
            if(event.keyCode === 17){
                oldKey=null
                this.setState({ctrlPressed:false})
                ctrlPressed = false
            }
            if(event.keyCode === 88){
                oldKey=null
                this.setState({xPressed:false})
                xPressed = false
            }
            if(event.keyCode === 86){
                oldKey=null
                this.setState({vPressed:false})
                vPressed = false
            }
        })

        let pf = new URLSearchParams(window.location.search).get("pf");
        if(!!pf){
            //oracle 2
            pf = pf.replaceAll("prctslashprct","/")
            this.setState({pf})
        }

        this.setState({
            jwt:sessionStorage.getItem(this.props.app+"_jwt"),
        },()=>{
            Axios.post(urlAxiosCore+"verifyJwt",{
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(async response=>{
                if(response.data.jwt){
                    sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }
                if (response.data.resultat === false){
                    window.location.replace("/KageSecur/Accueil")
                }else{
                    socketIO = await socketInstance.connectionSocket(this.state.jwt)
                    //onlyClient = true si jwtDecoded.admin & jwtDecoded.gestionnaire sont false
                    let onlyClient = !jwtDecoded.admin && !jwtDecoded.gestionnaire
                    let gestionnaire = jwtDecoded.admin || jwtDecoded.gestionnaire
                    if(jwtDecoded.admin){
                        jwtDecoded.gestionnaire = true
                    }
                    this.setState({admin:jwtDecoded.admin,gestionnaire,onlyClient, freeClient:jwtDecoded.freeClient}, () => {
                        global.emitter.emit("onRouteChangedKageSecur", {
                            user: jwtDecoded.gestionnaire ? "gestionnaire" : (jwtDecoded.displayHeaderGestionnaire ? "gestionnaire":"client"),
                            pseudo: jwtDecoded.pseudo
                        })
                        // if(jwtDecoded.admin){
                        //     this.requeteAxiosGetNodes()
                        // }
                        this.getListeAllFiles()
                        this.getAllClients()
                        this.notifLoop()

                    })
                }
            })
        })
    }

    notifLoop(){
        Axios.post(urlAxios+"notifHistory",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })

            }
            if(response.data.resultat === "done"){
                let lastDisplayOfNotif = sessionStorage.getItem("KAGESECUR_notifTS"), tempNotReadNotif = 0
                response.data.synthese.forEach((elt)=>{
                    elt.ts = parseInt(elt.date)
                    if(elt.date > lastDisplayOfNotif){
                        tempNotReadNotif ++
                    }
                    elt.date = (new Date(parseInt(elt.date))).toLocaleString("fr-FR").replace(","," à")
                })
                this.setState({notifications:response.data.synthese, notReadNotif:tempNotReadNotif,lastDisplayOfNotif:lastDisplayOfNotif})
            }
        }).catch(error => {
            console.log(error);
        });
        setTimeout(()=>{this.notifLoop()},480000) //Actualisation toutes les 10min
    }

    sortArrayJson(prop){
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    sortListFiles(){
        return function(a, b) {
            if (a.type === "dossier" && b.type === "dossier"){ // 2 dossiers
                if (a.name > b.name) {
                    return 1;
                } else if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }else if (a.type === "dossier"){ // a = dossier et b = fichier
                return -1
            }else if (b.type === "dossier"){ // b = dossier et a = fichier
                return 1
            }else{
                if (!a.path.includes("notArchived/") && !b.path.includes("notArchived/")){ // 2 fichiers archivés
                    if (a.name.split("##")[1] > b.name.split("##")[1]) {
                        return 1;
                    } else if (a.name.split("##")[1] < b.name.split("##")[1]) {
                        return -1;
                    }
                    return 0;
                }else if (!a.path.includes("notArchived/")){ // a = archivé et b = non archivé
                    return -1
                }else if (!b.path.includes("notArchived/")) { // b = archivé et a = non archivé
                    return 1
                }else{ // 2 fichiers non archivés
                    if (a.name.split("##")[1] > b.name.split("##")[1]) {
                        return 1;
                    } else if (a.name.split("##")[1] < b.name.split("##")[1]) {
                        return -1;
                    }
                    return 0;
                }
            }
        }
    }

    getListeAllFiles (){
        Axios.post(urlAxios+"getArchiFiles",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            let displayClient = response.data.displayClient

            if(displayClient){
                let base=null
                if(!!this.state.pf){
                    base= response.data.resultat
                    this.state.pf.split("/").forEach(pf=>{
                        if(base[base.findIndex(elt => elt.name === pf)]){
                            base = base[base.findIndex(elt => elt.name === pf)].content
                        }
                    })
                }
                this.setState({displaySkeleton:false,archiFiles : response.data.resultat,toDisplay:base ? base:response.data.resultat.sort(this.sortListFiles()),breadcrumbsDisplay:base ? this.state.pf.split("/"):[]})
            }else{
                //Un admin démarre dans le bon fichier :
                let breadcrumbsDisplay = [baseUrl.split("/")[2],jwtDecoded.pseudo], toDisplay = [], nodeIndex = response.data.resultat.findIndex(elt => elt.name === baseUrl.split("/")[2])
                if (!!response.data.resultat[nodeIndex] && !!response.data.resultat[nodeIndex].content){
                    this.setState({nodeIndex:nodeIndex})
                    const index = response.data.resultat[nodeIndex].content.findIndex(elt => elt.name === jwtDecoded.pseudo)
                    if (index !== -1){
                        if  (!!response.data.resultat[nodeIndex].content[response.data.resultat[nodeIndex].content.findIndex(elt => elt.name === jwtDecoded.pseudo)].content){

                            toDisplay = response.data.resultat[nodeIndex].content[response.data.resultat[nodeIndex].content.findIndex(elt => elt.name === jwtDecoded.pseudo)].content
                            let base = null
                            let pfFound = false
                            if(!!this.state.pf){
                                base= toDisplay
                                this.state.pf.split("/").forEach(pf=>{
                                    if(base[base.findIndex(elt => elt.name === pf)]){
                                        pfFound=true
                                        base = base[base.findIndex(elt => elt.name === pf)].content
                                    }
                                })
                                if(!pfFound){
                                    base = null
                                    this.setState({pf:""})
                                    this.props.navigate("/Kagesecur/TableauDeBord", { replace: true });
                                }
                            }
                            // Cas qui marche
                            this.setState({displaySkeleton:false,breadcrumbsDisplay:base ? breadcrumbsDisplay.concat(this.state.pf.split("/")):breadcrumbsDisplay,archiFiles : response.data.resultat,toDisplay:base ? base.sort(this.sortListFiles()):toDisplay.sort(this.sortListFiles())})
                        }else{
                            this.setState({displaySkeleton:false,breadcrumbsDisplay:breadcrumbsDisplay,archiFiles : response.data.resultat,toDisplay:toDisplay})
                        }
                    }else{
                        this.setState({displaySkeleton:false,breadcrumbsDisplay:breadcrumbsDisplay,archiFiles : response.data.resultat,toDisplay:toDisplay})
                    }
                }else{
                    this.setState({displaySkeleton:false,breadcrumbsDisplay:breadcrumbsDisplay,archiFiles : response.data.resultat,toDisplay:toDisplay})
                }
            }
        })
        // if(!jwtDecoded.admin){
        //     Axios.post(urlAxios"clientGetHisFiles",{},
        //         {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
        //         if(response.data.jwt){
        //             this.setState({
        //                 jwt:response.data.jwt
        //             },()=>{
        //                 this.updateJwt(response.data.jwt)
        //             })
        //         }
        //         let base=null
        //         if(!!this.state.pf){
        //             base= response.data.resultat
        //             let pfFound = false
        //             this.state.pf.split("/").forEach(pf=>{
        //                 if(base[base.findIndex(elt => elt.name === pf)]){
        //                     pfFound = true
        //                     base = base[base.findIndex(elt => elt.name === pf)].content
        //                 }
        //             })
        //             if(!pfFound){
        //                 base = null
        //                 this.setState({pf:""})
        //                 this.props.navigate("/Kagesecur/TableauDeBord");
        //             }
        //         }
        //         this.setState({displaySkeleton:false,archiFiles : response.data.resultat,toDisplay:base ? base:response.data.resultat.sort(this.sortListFiles()),breadcrumbsDisplay:base ? this.state.pf.split("/"):[]})
        //     })
        // }else{
        //
        // }
    }

    clickDownload(zip) {
        if(zip){
            console.log(this.state.selectedFiles[0])
            const elt = this.state.selectedFiles[0]
            // folderPath oracle
            Axios.post(urlAxios+"generateKSnonce",{idToken:elt.name.split("##").shift()},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                if (response.data.resultat === "done"){
                    Toast.fire({
                        title: "Téléchargement démarré",
                        icon: 'info',
                    })
                    console.log(response.data)
                    window.open(`${urlAxios}downloadFolderAsZip?tokenJwt=${this.state.jwt}&folderPath=${encodeURIComponent(this.state.selectedFiles[0].path)}&nonce=${response.data.nonce}&file=${encodeURIComponent(elt.name.replace("##", "pourcentdiese"))}`)
                }else{
                    Toast.fire({
                        title: "Impossible de démarrer le téléchargement",
                        icon: 'warning',
                    })
                }
            })
        }else{
            this.state.selectedFiles.forEach(elt=>{
                if(elt.infos.archived && elt.type !== "folder"){
                    let filePath;
                    if (elt.path.includes("ciphered##")){
                        filePath = elt.path
                        filePath = filePath.replace("ciphered##","").replaceAll("##", "pourcentdiese")
                    }else{
                        filePath = elt.path.split("/")
                        filePath.splice(0,3)
                        filePath[filePath.length-1] = filePath[filePath.length-1].replaceAll("##","pourcentdiese")
                        filePath = filePath.join("/")
                    }

                    function displayToast(){
                        Toast.fire({
                            title: "Téléchargement démarré",
                            icon: 'info',
                        })
                    }

                    if (this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")){
                        const index = this.state.breadcrumbsDisplay.indexOf("#shared#Dossiers partagés"),
                            sharedFolder = this.state.breadcrumbsDisplay[index+1]
                        displayToast()
                        Axios.post(urlAxios+"generateKSnonce",{idToken:elt.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                            if (response.data.resultat === "done"){
                                displayToast()
                                window.open(`${urlAxios}downloadFileInSharedFolder?tokenJwt=${this.state.jwt}&sharedFolder=${sharedFolder}&path=${encodeURIComponent(filePath)}&previsu=false&ciphered=${elt.path.includes("ciphered##")}&idToken=${elt.idToken}&nonce=${response.data.nonce}`)
                            }else{
                                Toast.fire({
                                    title: "Impossible de démarrer le téléchargement",
                                    icon: 'warning',
                                })
                            }
                        })
                    }else{
                        Axios.post(urlAxios+"generateKSnonce",{idToken:elt.name.split("##").shift()},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                            if (response.data.resultat === "done"){
                                displayToast()

                                const file = encodeURIComponent(elt.name.replaceAll("##", "pourcentdiese")),
                                    url = urlAxios+`downloadKSFile?tokenJwt=${this.state.jwt}&nonce=${response.data.nonce}&file=${file}&path=${encodeURIComponent(filePath)}&previsu=false`
                                window.open(url)
                            }else{
                                Toast.fire({
                                    title: "Impossible de démarrer le téléchargement",
                                    icon: 'warning',
                                })
                            }
                        })

                            // window.open(urlAxios+"KSFile/"+filePath+"?jwt="+this.state.jwt)
                    }
                }
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeFolder(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value.replaceAll("/","")
        })
    }

    handleChangeSelect(e){
        if (e.currentTarget.textContent !== "Signataires du node :" && e.currentTarget.textContent !== "Signataires d'autres nodes :"){
            this.setState({
                validateurChosen:e.currentTarget.textContent,
                readyToSend:true
            })
        }
    }

    getContentTypeByExtension(extension) {
        extension= extension.toLowerCase()
        const contentType = {
            aac:"audio/aac",
            abw:"application/x-abiword",
            arc:"application/octet-stream",
            avi:"video/x-msvideo",
            azx:"application/vnd.amazon.ebook",
            bin:"application/octet-stream",
            bmp:"image/bmp",
            bz:"application/x-bzip",
            bz2:"application/x-bzip2",
            csh:"application/x-csh",
            css:"text/css",
            csv:"text/csv",
            doc:"application/msword",
            docx:"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            eot:"application/vnd.ms-fontobject",
            epub:"application/epub+zip",
            gif:"image/gif",
            htm:"text/html",
            html:"text/html",
            ico:"image/x-icon",
            ics:"text/calendar",
            jar:"application/java-archive",
            jpg:"image/jpeg",
            jpeg:"image/jpeg",
            js:"application/javascript",
            json:"application/json",
            mid:"audio/midi",
            midi:"audio/midi",
            mpeg:"video/mpeg",
            mpkg:"application/vnd.apple.installer+xml",
            odp:"application/vnd.oasis.opendocument.presentation",
            ods:"application/vnd.oasis.opendocument.spreadsheet",
            odt:"application/vnd.oasis.opendocument.text",
            oga:"audio/ogg",
            ogv:"video/ogg",
            ogx:"application/ogg",
            otf:"font/otf",
            png:"image/png",
            pdf:"application/pdf",
            ppt:"application/vnd.ms-powerpoint",
            pptx:"application/vnd.openxmlformats-officedocument.presentationml.presentation",
            rar:"application/x-rar-compressed",
            rtf:"application/rtf",
            mp4:"video/mp4",
            sh:"application/x-sh",
            svg:"image/svg+xml",
            swf:"application/x-shockwave-flash",
            tar:"application/x-tar",
            tif:"image/tiff",
            tiff:"image/tiff",
            ts:"application/typescript",
            ttf:"font/ttf",
            txt:"text/plain",
            vsd:"application/vnd.visio",
            wav:"audio/x-wav",
            weba:"audio/webm",
            webm:"video/webm",
            webp:"image/webp",
            woff:"font/woff",
            woff2:"font/woff2",
            xhtml:"application/xhtml+xml",
            xls:"application/vnd.ms-excel",
            xlsx:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            xml:"application/xml",
            xul:"application/vnd.mozilla.xul+xml",
            zip:"application/zip",
            "3gp":"video/3gpp",
            "3gp2":"video/3gpp2",
            "7z":"application/x-7z-compressed",
        }
        return contentType[extension] || 'application/octet-stream';
    }
    getIconForFileType = (fileName) => {
        const extension = fileName.split('.')[fileName.split('.').length - 1];
        const contentType = this.getContentTypeByExtension(extension).split('/')[0];

        if(extension === "zip"){
            return <FolderZip />;
        }else if(extension === "pdf"){
            return <img src={PdfIcon} style={{width:23}} alt="PDF Icon" />;
        }else{
            switch (contentType) {
                case 'image':
                    return <ImageIcon />;
                case 'video':
                    return <VideoIcon />;
                default:
                    return <DescriptionIcon />;
            }
        }
    }

    openFileOrFolder(fileClicked){
        /*On vérifie que l'utilisateur ne soit pas en train de renommer le fichier pour lui permettre de double click pour sélectionner le nom du fichier*/
        if(this.state.fileClipboardToRename.path !== fileClicked.path){
            let mimeType = this.getContentTypeByExtension(fileClicked.name.split(".")[fileClicked.name.split(".").length-1])
            let breadcrumbsDisplay = this.state.breadcrumbsDisplay.slice()
            breadcrumbsDisplay.push(fileClicked.name)
            this.setState({fileSelectedIsFolder:false})
            if(fileClicked.type === "dossier"){
                if(!!this.state.pf){
                    this.props.navigate("/Kagesecur/TableauDeBord", { replace: true });
                }
                if(fileClicked !== this.state.fileClipboardToMove){
                    this.setState({
                        breadcrumbsDisplay:breadcrumbsDisplay,
                        toDisplay:fileClicked.content.sort(this.sortListFiles()),
                        rechercheDisplay:"",
                        sortStatus:"nom-down",
                    },()=>{
                        this.state.selectedFiles.forEach(elt=>{
                            this.setState({
                                ["selected"+elt.path]:false
                            })
                        })
                        this.setState({selectedFiles:[],virtualListHeight:this.state.toDisplay.length*54})
                        this.setState({disableButtonMenu:breadcrumbsDisplay.includes("#shared#Dossiers partagés")})
                        let divScroll = document.getElementById("divScroll");
                        divScroll.scrollTo({top: 0})
                    })
                }else{
                    Toast.fire({
                        title: "Vous ne pouvez pas ouvrir un dossier en cours de déplacement.",
                        icon: 'warning',
                    })
                }

            }else if (fileClicked.type === "zip"){
                Toast.fire({
                    title: "L'ouverture des fichiers zip est indisponible pour le moment.",
                    icon: 'info',
                })
            }else if (mimeType.split("/")[0] ==="image" ||fileClicked.name.split(".")[fileClicked.name.split(".").length-1] ==="pdf" ||mimeType.split("/")[0] ==="video" || fileClicked.name.split(".")[fileClicked.name.split(".").length-1] ==="txt"){
                if(!fileClicked.path.includes("notArchived") && fileClicked.infos.archived === true){
                    let filePath;
                    if (fileClicked.path.includes("ciphered##")){
                        filePath = fileClicked.path
                        filePath = filePath.replace("ciphered##","").replaceAll("##", "pourcentdiese")
                    }else{
                        filePath = fileClicked.path.split("/")
                        filePath.splice(0,3)
                        filePath[filePath.length-1] = filePath[filePath.length-1].replaceAll("##","pourcentdiese")
                        filePath = filePath.join("/")
                    }

                    if (this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")){
                        const index = this.state.breadcrumbsDisplay.indexOf("#shared#Dossiers partagés"),
                            sharedFolder = this.state.breadcrumbsDisplay[index+1]

                        Axios.post(urlAxios+"generateKSnonce",{idToken:fileClicked.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                            if (response.data.resultat === "done"){
                                Toast.fire({
                                    title: "Téléchargement démarré",
                                    icon: 'info',
                                })
                                window.open(`${urlAxios}downloadFileInSharedFolder?tokenJwt=${this.state.jwt}&sharedFolder=${sharedFolder}&path=${encodeURIComponent(filePath)}&previsu=true&ciphered=${fileClicked.path.includes("ciphered##")}&idToken=${fileClicked.idToken}&nonce=${response.data.nonce}`)
                            }else{
                                Toast.fire({
                                    title: "Impossible de démarrer le téléchargement",
                                    icon: 'warning',
                                })
                            }
                        })
                    }else{
                        Axios.post(urlAxios+"generateKSnonce",{idToken:fileClicked.name.split("##").shift()},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                            if (response.data.resultat === "done"){
                                Toast.fire({
                                    title: "Téléchargement démarré",
                                    icon: 'info',
                                })
                                const file = encodeURIComponent(fileClicked.name.replaceAll("##", "pourcentdiese")),
                                    url = urlAxios+`downloadKSFile?tokenJwt=${this.state.jwt}&nonce=${response.data.nonce}&file=${file}&path=${encodeURIComponent(filePath)}&previsu=true`
                                window.open(url)
                            }else{
                                Toast.fire({
                                    title: "Impossible de démarrer le téléchargement",
                                    icon: 'warning',
                                })
                            }
                        })
                    }
                }else{
                    Toast.fire({
                        title: "Vous ne pouvez pas visualiser un fichier non stocké dans nos serveurs.",
                        icon: 'warning',
                    })
                }
            }else{
                if(!fileClicked.path.includes("notArchived") && fileClicked.infos.archived === true){
                    Toast.fire({
                        title: "Pour visualiser ce fichier, cliquez sur le bouton télécharger en haut à droite.",
                        icon: 'info',
                    })
                }else{
                    Toast.fire({
                        title: "Vous ne pouvez pas visualiser un fichier non stocké dans nos serveurs.",
                        icon: 'warning',
                    })
                }
            }
        }
    }

    selectFileOrFolder(fileClicked,click){
        let selectedFiles = this.state.selectedFiles.slice() //Pour ne pas que les fichiers restent sélectionnés même après changement de fichier
        //Si on click droit sur un fichier alors qu'on en a déjà selectionné d'autre alors ça déselectionne rien sauf si on clique sur un fichier pas déjà inclu dans la liste
        if(!(selectedFiles.length>=1 && click ==="rightClick") || selectedFiles.findIndex(file=>file.name === fileClicked.name) === -1){
            if(this.state.ctrlPressed){ //ctrl pressé ?
                let indexOfClickedFile = selectedFiles.findIndex(file=>file.name === fileClicked.name)
                if(indexOfClickedFile !== -1){
                    selectedFiles.splice(indexOfClickedFile, 1)
                }else{
                    selectedFiles.push(fileClicked)
                }
                this.setState({
                    ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                })
            }else if (this.state.shiftPressed && selectedFiles.length===1){ //Click shift avec au moins un fichier déjà selectionné
                /*select all files in toDisplay from the previous selectefile to the fileClicked*/
                let indexOfClickedFile = this.state.toDisplay.findIndex(file=>file.name === fileClicked.name)
                let indexOfLastSelectedFile = this.state.toDisplay.findIndex(file=>file.name === selectedFiles[selectedFiles.length-1].name)
                if(indexOfClickedFile !== -1 && indexOfLastSelectedFile !== -1){
                    let filesToSelect = this.state.toDisplay.slice(Math.min(indexOfClickedFile,indexOfLastSelectedFile),Math.max(indexOfClickedFile,indexOfLastSelectedFile)+1)
                    filesToSelect.forEach(file=>{
                        this.setState({
                            ["selected"+file.path]:true,
                        })
                    })
                    selectedFiles = filesToSelect
                }else{
                    this.setState({
                        ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                    })
                    selectedFiles = [fileClicked]
                }
            } else{ //click normal
                if(this.state.selectedFiles.length ===0){
                    this.setState({
                        ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                    })
                }else{
                    this.state.selectedFiles.forEach(elt=>{
                        this.setState({
                            ["selected"+elt.path]:false
                        },()=>{
                            this.setState({
                                ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                            })
                        })
                    })
                }
                selectedFiles = [fileClicked]
            }
            let fileSelectedIsFolder
            if(selectedFiles.length === 1){
                fileSelectedIsFolder = selectedFiles[0].type === "dossier" || selectedFiles[0].type === "sharedFolder"
            }else if (selectedFiles.length > 1){
                /*vérifier si un des selectedFile est de type fichier, auquel cas fileSelectedIsFolder est false*/
                fileSelectedIsFolder = true
                selectedFiles.forEach(file=>{
                    if((file.type !== "dossier" && file.type !== "sharedFolder") && fileSelectedIsFolder === true){
                        fileSelectedIsFolder = false
                    }
                })
            }
            this.setState({
                selectedFiles:selectedFiles,
                fileSelectedIsFolder
            })
            if(selectedFiles.findIndex(file => file.name === "#shared#Dossiers partagés") !== -1 || selectedFiles.length > 1 && selectedFiles.findIndex(file => file.type === "dossier") !== -1){ //A modifier si déplacement de plusieurs dossiers
                //Si on a sélectionné plusieurs fichiers dont un dossier ou un dossier partagé
                this.setState({disableMenu :this.state.onlyClient})
            }else{
                this.setState({disableMenu :false})
            }
        }
    }

    selectBreadcrumbs(breadcrumbs, content, breadcrumbsList){
        this.setState({sortStatus:"nom-down", fileSelectedIsFolder:false})
        if(!!this.state.pf){
            this.props.navigate("/Kagesecur/TableauDeBord", { replace: true });
        }
        if(breadcrumbsList.includes("#shared#Dossiers partagés")){
            if(this.state.contentOfSharedFolder.length === 0 ){ //Dans le cas d'un admin avec un dossier partagé vide
                for (let i = 0; i<content.length; i++){
                    if (content[i].name === breadcrumbsList[0]){
                        content = content[i].content
                    }
                }
            }else{
                for (let i = 0; i<this.state.contentOfSharedFolder.length; i++){
                    if (this.state.contentOfSharedFolder[i].name === breadcrumbsList[0]){
                        content = this.state.contentOfSharedFolder[i].content
                    }else if (breadcrumbsList[0] === "#shared#Dossiers partagés" || breadcrumbsList[3] === "#shared#Dossiers partagés"){
                        content = this.state.contentOfSharedFolder
                    }else{ //Dans le cas d'un admin
                        for (let i = 0; i<content.length; i++){
                            if (content[i].name === breadcrumbsList[0]){
                                content = content[i].content
                            }
                        }
                    }
                }
            }
            this.setState({disableButtonMenu:this.state.onlyClient})
        }else{
            for (let i = 0; i<content.length; i++){
                if (content[i].name === breadcrumbsList[0]){
                    content = content[i].content
                }
            }
            this.setState({disableButtonMenu:false})
        }

        if (breadcrumbsList[0] === breadcrumbs){
            let newBreadcrumbsDisplay = this.state.breadcrumbsDisplay.slice()
            newBreadcrumbsDisplay.length = this.state.breadcrumbsDisplay.indexOf(breadcrumbs)+1
            this.setState({
                toDisplay:content.sort(this.sortListFiles()),
                breadcrumbsDisplay:newBreadcrumbsDisplay,
                recherche:""
            },()=>{
                this.state.selectedFiles.forEach(elt=>{
                    this.setState({
                        ["selected"+elt.path]:false
                    })
                })
                this.setState({selectedFiles:[],virtualListHeight:this.state.toDisplay.length*54})
                let divScroll = document.getElementById("divScroll");
                divScroll.scrollTo({top: 0})
            })
        }else{
            if(breadcrumbs === undefined){
                this.setState({
                    toDisplay: this.state.archiFiles.sort(this.sortListFiles()),
                    breadcrumbsDisplay:[]
                },()=>{
                    this.setState({virtualListHeight:this.state.toDisplay.length*54})
                    let divScroll = document.getElementById("divScroll");
                    divScroll.scrollTo({top: 0})
                })
            }else{
                breadcrumbsList.shift()
                this.selectBreadcrumbs(breadcrumbs, content, breadcrumbsList)
            }
        }
    }

    goToRoot(){
        let toDisplay
        try{
            toDisplay = this.state.admin ? this.state.archiFiles[this.state.nodeIndex].content[this.state.archiFiles[this.state.nodeIndex].content.findIndex(elt => elt.name === jwtDecoded.pseudo)].content:this.state.archiFiles
        }catch(err){
            /*ce catch n'arrive que si on a pas de dossier représentant le node (localhost par exemple) et qu'on est admin*/
            toDisplay = this.state.archiFiles /*toDisplay est faux avec ça mais pas de bugs*/
        }
        let breadcrumbsDisplay = this.state.admin ? [baseUrl.split("/")[2],jwtDecoded.pseudo]:[]
        this.setState({
            toDisplay:toDisplay.sort(this.sortListFiles()),
            breadcrumbsDisplay:breadcrumbsDisplay,
            rechercheDisplay:"",
            disableButtonMenu:false,
            sortStatus:"nom-down"
        },()=>{

            this.state.selectedFiles.forEach(elt=>{
                this.setState({
                    ["selected"+elt.path]:false
                })
            })
            if(!!this.state.pf){
                this.props.navigate("/Kagesecur/TableauDeBord", { replace: true });
            }
            this.setState({selectedFiles:[],clear:"none",virtualListHeight:this.state.toDisplay.length*54})
            let divScroll = document.getElementById("divScroll");
            divScroll.scrollTo({top: 0})
        })
    }
    clearFilterAndGoRoot(){
        oldResearch = ""
        if(!!this.state.pf){
            this.props.navigate("/Kagesecur/TableauDeBord", { replace: true });
        }
        this.setState({
            rechercheDisplay:"",
            recherche:"",
            clear:"none",
            searchFinished:true,
            disableButtonMenu:false,
        },()=>{this.goToRoot()})
    }

    handleClickRecherche(e){
        e.preventDefault();
        this.setState({disableButtonMenu:true})
        if (JSON.stringify(this.state.breadcrumbsDisplay).includes("#shared#Dossiers partagés")){ //Si on fait une recherche dans un dossier ptg : on ne check que les noms
            let toKeep = [], toSearch = this.state.recherche.toLowerCase();
            function loopOnFolders(folders){
                return new Promise(async resolve => {
                    if (folders.length === 0){
                        resolve()
                    }else{
                        const folder = folders[0]
                        if (folder.name.split("##")[1].toLowerCase().includes(toSearch)){
                            toKeep.push(folder)
                        }
                        if (folder.type === "dossier" && folder.content.length > 0){
                            loopOnFiles(folder.content.slice()).then(()=>{
                                folders.shift()
                                resolve(loopOnFolders(folders))
                            })
                        }else{
                            folders.shift()
                            resolve(loopOnFolders(folders))
                        }
                    }
                })
            }
            function loopOnFiles(files){
                return new Promise(async resolve => {
                    if (files.length === 0){
                        resolve()
                    }else{
                        const file = files[0]
                        if (file.name.split("##")[1].toLowerCase().includes(toSearch)){
                            toKeep.push(file)
                        }
                        if (file.type === "dossier" && file.content.length > 0){
                            loopOnFiles(file.content.slice()).then(()=>{
                                files.shift()
                                resolve(loopOnFolders(files))
                            })
                        }else{
                            files.shift()
                            resolve(loopOnFolders(files))
                        }
                    }
                })
            }

            this.setState({
                rechercheDisplay:toSearch,
                clear:"flex",
                searchFinished:false,
            },()=> {
                loopOnFolders(this.state.toDisplay.slice()).then(() => {
                    this.state.selectedFiles.forEach(elt=>{
                        this.setState({
                            ["selected"+elt.path]:false
                        })
                    })
                    this.setState({selectedFiles:[],toDisplay:toKeep.sort(this.sortListFiles()),searchFinished:true},()=>{
                        this.setState({virtualListHeight:this.state.toDisplay.length*54})
                    })
                })
            })
        }else{
            if(this.state.recherche !== ""){
                if(oldResearch !== this.state.recherche.toLowerCase()||this.state.passSearchAnyway){
                    if(this.state.searchFinished){
                        this.setState({
                            rechercheDisplay:this.state.recherche.toLowerCase(),
                            clear:"flex",
                            searchFinished:false,
                            toDisplay:[],
                        },()=>{
                            let pathRequested = "./Q_file/stockageSecure/"
                            if (this.state.admin){
                                pathRequested = pathRequested + this.state.breadcrumbsDisplay.join("/")
                            }else{
                                pathRequested = pathRequested + baseUrl.split("/")[2]+"/"+jwtDecoded.pseudo+"/"+this.state.breadcrumbsDisplay.join("/")
                            }
                            Axios.post(urlAxios+"checkIfPresent",{pseudo:jwtDecoded.pseudo, pathRequested:pathRequested,searchedWord:this.state.rechercheDisplay},{headers:{Authorization:"Bearer "+ this.state.jwt}}).then((response)=>{
                                oldResearch = this.state.recherche.toLowerCase()
                                if(response.data.jwt){
                                    sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                                    this.setState({
                                        jwt:response.data.jwt
                                    },()=>{
                                        this.updateJwt(response.data.jwt)
                                    })
                                }
                                this.state.selectedFiles.forEach(elt=>{
                                    this.setState({
                                        ["selected"+elt.path]:false
                                    })
                                })
                                this.setState({selectedFiles:[],toDisplay:response.data.resultat.sort(this.sortListFiles()),searchFinished:true},()=>{
                                    this.setState({virtualListHeight:this.state.toDisplay.length*54})
                                })
                                setTimeout(()=>{this.setState({passSearchAnyway:true})},3000)//On attend 3s avant de laisser l'utilisateur effectuer la même recherche (sécurité anti spam)
                            }).catch(err=>{
                                console.log(err)
                            })
                        })
                    }else{
                        Toast.fire({
                            title: "Veuillez patienter, une recherche est déjà en cours...",
                            icon: 'warning',
                        })
                    }
                }else{
                    Toast.fire({
                        title: "Veuillez patienter quelques instants avant de relancer une recherche.",
                        icon: 'warning',
                    })
                }
            }
        }
    }

    async sendProofMail(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            let cancelProof = false
            this.state.selectedFiles.forEach(elt => {
                if (elt.type === "dossier") {
                    cancelProof = true
                }
            })
            if (cancelProof) {
                Swal.fire({
                    text: "Vous ne pouvez pas envoyer un dossier.",
                    icon: 'warning',
                    confirmButtonText: "Ok"
                })
            } else {
                // if (this.state.selectedFiles.length > 1) {
                //     Swal.fire({
                //         text: "Vous ne pouvez pas envoyer plusieurs fichiers à la fois.",
                //         icon: 'warning',
                //         confirmButtonText: "Ok"
                //     })
                // } else {
                this.setState({displayDialogSendProof: true})
                // }
            }
        }
    }

    validateProofViaMail(e){
        if(this.state.totalSizePiecesJointes > 23000000){
            this.setState({displayDialogSendProof:false})
            Swal.fire({
                text: "La taille totale de vos pièces jointes ne doit pas dépasser 23MB.",
                icon: 'warning',
                confirmButtonText: "Ok"
            }).then(()=>{
                this.setState({displayDialogSendProof:true,arrayBufferPiecesJointes:[]})
            })
        }else{
            Swal.fire({
                title: "Insérez un commentaire à joindre dans le mail :",
                html:
                    '<textarea style="resize: none;width:98%;font-family: Segoe UI; font-size: 17px" rows = "8" cols = "50" maxlength="350" id="swal-messageAP">',
                showCancelButton: true,
                showDenyButton: true,
                showLoaderOnConfirm: true,
                cancelButtonColor: '#d33',
                denyButtonColor: '#787878',
                confirmButtonText: 'Envoyer',
                cancelButtonText:"Annuler",
                denyButtonText: `Sans commentaire`,
                customClass: 'swal-offset-top',
            }).then(inputValue => {
                if (inputValue.isConfirmed || inputValue.isDenied) {
                    let messageClient = document.getElementById('swal-messageAP').value
                    if (inputValue.isDenied) {
                        messageClient = ""
                    }
                    if(this.state.listRecepteurPreuveViaMail.length !== 0){
                        this.setState({displayDialogSendProof: false})
                        Axios.post(`${urlAxios}sendMailForPreuve`, {
                            listFiles:this.state.selectedFiles,
                            listRecepteurPreuveViaMail: this.state.listRecepteurPreuveViaMail,
                            arrayBufferPiecesJointes:this.state.arrayBufferPiecesJointes,
                            pub:this.state.onlyClient === false ? this.state.sendPub:true,
                            messageClient:messageClient
                        }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
                            this.setState({phasePreuveViaMail:1})
                            if (response.data.jwt) {
                                sessionStorage.setItem(this.props.app + "_jwt", response.data.jwt)
                                this.setState({
                                    jwt: response.data.jwt
                                },()=>{
                                    this.updateJwt(response.data.jwt)
                                })
                            }
                            if (response.data.resultat === "Impossible de trouver le(s) document(s).") {
                                Toast.fire({
                                    icon: 'error',
                                    title: 'Une erreur est survenue, ce(s) fichier(s) ne vous appartiennent pas.'
                                })
                            } else if (response.data.resultat === "envoyé" && (response.data.wrongFiles && response.data.wrongFiles.length>0)) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: 'Une erreur est survenue, le(s) fichier(s) suivant(s) ne vous appartiennent pas : '+response.data.wrongFiles.join(", ")
                                })
                            }
                            else if (response.data.resultat === "envoyé") {
                                if(response.data.link){
                                    //Swal to copy the link manually
                                    Swal.fire({
                                        title: 'Succès!',
                                        showCancelButton:true,
                                        cancelButtonText:"Fermer",
                                        html:
                                            '<p style="margin-bottom: 0px; display: flex;align-items: flex-start;font-weight: normal; justify-content: center" >Email correctement envoyé</p>' +
                                            '<button disabled type="button" style="cursor: default; margin-bottom:0px; margin-top: 10px" id="buttonCopyClipboardPreuve" class="swal2-confirm swal2-styled">Chargement...</button>',
                                        didRender: () => {
                                            setTimeout(()=>{
                                                let button = document.getElementById('buttonCopyClipboardPreuve');
                                                button.textContent = 'Copier le lien manuellement 📋';
                                                button.disabled=false
                                                button.style.cursor="pointer"
                                                button.onclick = () => {
                                                    navigator.clipboard.writeText(response.data.link);
                                                    button.textContent = 'Lien copié ✔';
                                                    button.style.backgroundColor = '#4BB543FF';
                                                };
                                            },500)
                                        },
                                        icon: 'success',
                                        showConfirmButton: false,
                                    });
                                }else{
                                    Toast.fire({
                                        icon: 'success',
                                        title: 'Email correctement envoyé.'
                                    })
                                }
                                this.setState({recepteurProofName:"",recepteurProofEmail:"",arrayBufferPiecesJointes:[],listRecepteurPreuveViaMail:[]})
                            }else{
                                Swal.fire({
                                    text: "La taille totale de vos pièces jointes ne doit pas dépasser 23MB.",
                                    icon: 'warning',
                                    confirmButtonText: "Ok"
                                }).then(()=>{
                                    this.setState({displayDialogSendProof:true,arrayBufferPiecesJointes:[]})
                                })
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
            })
        }
    }

    handleMenu(event){
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseMenu (){
        this.setState({ anchorEl: null });
        const contextMenu = document.getElementById("menuRightClick")
        contextMenu.style.transform = `scale(0)`
        contextMenu.style.visibility = `hidden`
    };

    createCertificate(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            if (this.state.selectedFiles.length === 1) {
                if (this.state.selectedFiles[0].type === "dossier") {
                    Swal.fire({
                        text: "Vous ne pouvez pas créer un certificat pour un dossier !",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    window.open('/KageSecur/Verification?hf=' + this.state.selectedFiles[0].infos.hashFile, "_blank")
                }
            } else {
                Swal.fire({
                    text: "Vous ne pouvez pas créer plusieurs certificats en même temps !",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }

    goToTraceability(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            if (this.state.selectedFiles.length === 1) {
                if (this.state.selectedFiles[0].type === "dossier") {
                    Swal.fire({
                        text: "Vous ne pouvez pas tracer un dossier !",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    window.open('/KageSecur/Tracabilite?hf=' + this.state.selectedFiles[0].infos.hashFile, "_blank")
                }
            } else {
                Swal.fire({
                    text: "Vous ne pouvez pas tracer plusieurs fichiers en même temps !",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }

    sendNewDemandeSignature(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            Axios.post(`${urlAxios}remettreEnSignature`, {
                listSignataire: this.state.listSignataire,
                hashFile: this.state.selectedFiles[0].infos.hashFile,
                signed: this.state.selectedFiles[0].infos.signed,
                fileName: this.state.selectedFiles[0].name,
                path: this.state.selectedFiles[0].path,
                messageClient: this.state.messageNewSignature
            }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
                if (response.data.jwt) {
                    sessionStorage.setItem(this.props.app + "_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }
                if (response.data.resultat === "done") {
                    let archiFileTemp = this.state.archiFiles.slice()
                    let splittedPath = this.state.selectedFiles[0].path.split("/")
                    if(!this.state.selectedFiles[0].infos.archived){
                        if(this.state.admin){
                            splittedPath.shift()
                            splittedPath.unshift(baseUrl.split("/")[2])
                        }else{
                            splittedPath = splittedPath.slice(2)
                        }
                    }else{
                        this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                    }

                    function editArchi(archi, splittedPath, tableauIndex) {
                        archi.forEach((elt, index) => {
                            if (elt.name === splittedPath[0]) {
                                tableauIndex.push(index)
                                splittedPath.shift()
                                if (splittedPath.length !== 0) {
                                    archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                } else {
                                    archi[index].infos.signed = (archi[index].infos.signed === true ? "intermediate" : "beingSigned")
                                }
                            }
                        })
                        return archi
                    }

                    this.setState({listSignataire:[],resultatSignatureReSend:"",openSignatureDialog: false,dialogDemandeSignaturePhase:1, archiFiles: editArchi(archiFileTemp, splittedPath, [])})
                    Swal.fire({
                        text: "Le document est soumis à signature.",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    this.setState({openSignatureDialog: false,dialogDemandeSignaturePhase:1,listSignataire:[]})
                    Swal.fire({
                        text: response.data.resultat,
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            })
        }
    }

    shareFilesByMail(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            Swal.fire({
                title: 'Partager le fichier par mail',
                html:
                    '<p style="margin-bottom: 0" >Email du récepteur</p>' +
                    '<input id="swal-input2" class="swal2-input">',
                focusConfirm: false,
                confirmButtonText: "Envoyer",
                showCancelButton: true,
                cancelButtonText: "Annuler",
                preConfirm: () => {
                    if (document.getElementById('swal-input2').value && (document.getElementById('swal-input2').value).match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)) {
                        let allPaths = []
                        this.state.selectedFiles.forEach(elt => {
                            allPaths.push({path: elt.path})
                        })
                        Axios.post(`${urlAxios}sharedFilesByMail`, {
                            allPaths: allPaths,
                            emailShare: document.getElementById('swal-input2').value,
                        }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
                            if (response.data.jwt) {
                                sessionStorage.setItem(this.props.app + "_jwt", response.data.jwt)
                                this.setState({
                                    jwt: response.data.jwt
                                },()=>{
                                    this.updateJwt(response.data.jwt)
                                })
                            }
                            if (response.data.resultat === "done") {
                                Toast.fire({
                                    icon: 'success',
                                    title: 'Email correctement envoyé.'
                                })
                            } else {
                                Toast.fire({
                                    icon: 'error',
                                    title: response.data.resultat
                                })
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
            })
        }
    }

    changeArchivedStatus(folder){
        if(this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")){
            Swal.fire({
                text: "Vous ne pouvez pas changer le stockage d'un fichier qui vous a été partagé.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else{
            if (this.state.freeClient){
                Swal.fire({
                    text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }else {
                if (folder.infos.archived) {
                    if(folder.infos.signed === "beingSigned" || folder.infos.signed === "intermediate"){
                        Swal.fire({
                            text: "Vous ne pouvez pas désarchiver un document en cours de signature.",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        })
                    }else{
                        let verifBool = false
                        Swal.fire({
                            title: 'Attention!',
                            html:
                                '<p style="margin-bottom: 0" >Pour retirer ce document de nos serveurs, veuillez taper le mot "désarchiver" :</p>' +
                                '<input id="swal-input2-desarchivage" class="swal2-input">',
                            icon: 'warning',
                            confirmButtonText: "Valider",
                            confirmButtonColor: "red",
                            showCancelButton: true,
                            cancelButtonText: "Retour",
                            cancelButtonColor: "#369DD7",
                            preConfirm: () => {
                                if (document.getElementById('swal-input2-desarchivage').value && (document.getElementById('swal-input2-desarchivage').value === "désarchiver" || document.getElementById('swal-input2-desarchivage').value === "desarchiver")) {
                                    verifBool = true
                                }else{
                                    Toast.fire({
                                        icon: "error",
                                        title: "Vérification incorrecte."
                                    })
                                }
                            }
                        }).then((result) => {
                            if (result.isConfirmed && verifBool) {
                                Axios.post(urlAxios+"deleteFileFromStockageSecure", {
                                    fileName: folder.name,
                                    nodeToCheck:baseUrl.split("/")[2],
                                    hashFile: folder.infos.hashFile,
                                }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then((response) => {
                                    if (response.data.jwt) {
                                        sessionStorage.setItem(this.props.app+"_jwt", response.data.jwt)
                                        this.setState({
                                            jwt: response.data.jwt
                                        },()=>{
                                            this.updateJwt(response.data.jwt)
                                        })
                                    }
                                    if (response.data.resultat === "done") {
                                        let archiFileTemp = this.state.archiFiles.slice()
                                        let splittedPath = folder.path.split("/")
                                        if(!this.state.selectedFiles[0].infos.archived){
                                            if(this.state.admin){
                                                splittedPath.shift()
                                                splittedPath.unshift(baseUrl.split("/")[2])
                                            }else{
                                                splittedPath = splittedPath.slice(2)
                                            }
                                        }else{
                                            this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                                        }
                                        function editArchi(archi, splittedPath, tableauIndex) {
                                            archi.forEach((elt, index) => {
                                                if (elt.name === splittedPath[0]) {
                                                    tableauIndex.push(index)
                                                    splittedPath.shift()
                                                    if (splittedPath.length !== 0) {
                                                        archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                                    } else {
                                                        /*splice this file from archi*/
                                                        archi.splice(archi.findIndex(elt => elt.name === folder.name), 1)
                                                        // /*from archiFileTemp go to folder "Non Archivés" and push this file in the content*/
                                                    }
                                                }
                                            })
                                            return archi
                                        }
                                        Toast.fire({
                                            icon: "success",
                                            title: "Le fichier a correctement été retiré des serveurs."
                                        })
                                        this.setState({archiFiles: editArchi(archiFileTemp, splittedPath, [])},()=>{
                                            let archiFileTemp2 = this.state.archiFiles.slice();
                                            let nestedContent, nonArchiveFound = false

                                            if (this.state.admin) { //Si on est admin on se met dans le bon dossier
                                                const tempPath = folder.path.split("/");
                                                nestedContent = tempPath.slice(3, 5).reduce((acc, curr) => {
                                                    const found = acc.find((elt) => elt.name === curr);
                                                    return found ? found.content : acc;
                                                }, archiFileTemp2);
                                            }
                                            //On modifie le fichier pour le mettre dans le dossier "non archivés"
                                            folder.infos.archived = false;
                                            folder.infos.size = 0;
                                            let tempPath = folder.path.split("/");
                                            folder.path = tempPath.slice(0, 5).join("/") + "/Non archivés/" + folder.name;
                                            /*Si le dossier Non archivés existe déjà*/
                                            (nestedContent || archiFileTemp2).forEach((elt, index) => {
                                                if (elt.name === "Non archivés") {
                                                    (nestedContent || archiFileTemp2)[index].content.push(folder);
                                                    nonArchiveFound = true
                                                }
                                            });

                                            /*if nonArchiveFound === false create the folder in nestedContent or archiFileTemp2 (depending on the situation)*/
                                            /*(si on doit créer le dossier Non archivé)*/
                                            if(!nonArchiveFound){
                                                let objectFolder = {
                                                    type: "dossier",
                                                    name: "Non archivés",
                                                    content: [folder],
                                                    shared: false,
                                                    path: tempPath.slice(0, 5).join("/") + "/Non archivés"
                                                }
                                                if(nestedContent){
                                                    nestedContent.push(objectFolder);
                                                }else{
                                                    archiFileTemp2.push(objectFolder);
                                                }
                                            }
                                            /*Mis à jour des states*/
                                            this.setState({
                                                archiFiles: archiFileTemp2,
                                                toDisplay: this.state.admin
                                                    ? this.state.breadcrumbsDisplay[this.state.breadcrumbsDisplay.length - 1] === jwtDecoded.pseudo
                                                        ? nestedContent
                                                        : this.state.toDisplay
                                                    : this.state.breadcrumbsDisplay.length === 0
                                                        ? archiFileTemp2
                                                        : this.state.toDisplay,
                                            });

                                        })
                                    } else {
                                        Toast.fire({
                                            icon: "warning",
                                            title: response.data.resultat
                                        })
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                    Toast.fire({
                                        icon: "error",
                                        title: "Le serveur ne réponds pas."
                                    })
                                })
                            }
                        })
                    }
                } else {
                    this.setState({displayDialogUploadArchivage:true,fileToResend:folder})
                }
            }
        }
    }

    CreateFolder(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.setState({
                    displayCreateFolderLine: false,
                    newFolderName: "",
                })
            })
        }else {
            let path = `./Q_file/stockageSecure/${!this.state.admin ? `${baseUrl.split("/")[2]}/${jwtDecoded.pseudo}${this.state.breadcrumbsDisplay.length > 0 ? "/" + this.state.breadcrumbsDisplay.join("/") : ""}` : this.state.breadcrumbsDisplay.join("/")}`
            Axios.post(urlAxios+"createFolder", {
                folderName: this.state.newFolderName,
                path: path
            }, {headers: {Authorization: "Bearer "+ this.state.jwt}}).then((response) => {
                if (response.data.jwt) {
                    sessionStorage.setItem(this.props.app + "_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }
                if (response.data.resultat === "created") {
                    if (response.data.userFolder) { //Si le dossier client vient de se créer : on redemande au back l'archi
                        this.setState({displayCreateFolderLine: false, newFolderName: ""})
                        this.getListeAllFiles()
                    } else {
                        let objectFolder = {
                            type: "dossier",
                            name: response.data.newFolderName,
                            content: [],
                            shared: false,
                            path: path + "/" + response.data.newFolderName
                        }
                        let self = this
                        let archiFileTemp = this.state.archiFiles.slice()
                        let splittedPath = path.split("/")
                        this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)

                        function editArchi(archi, splittedPath, tableauIndex) {
                            if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                                archi.push(objectFolder)
                                self.setState({toDisplay: archi.sort(self.sortListFiles())})
                            } else {
                                archi.forEach((elt, index) => {
                                    if (elt.name === splittedPath[0]) {
                                        tableauIndex.push(index)
                                        splittedPath.shift()
                                        if (splittedPath.length !== 0) {
                                            archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                        } else {
                                            archi[index].content.unshift(objectFolder)
                                        }
                                    }
                                })
                            }
                            return archi
                        }
                        Toast.fire({
                            title: "Nouveau dossier créé !",
                            icon: 'success',
                        })

                        this.setState({
                            displayCreateFolderLine: false,
                            newFolderName: "",
                            archiFiles: editArchi(archiFileTemp, splittedPath, [])
                        })
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    RenameFolder(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.setState({
                    displayRenameFolder: false,
                    fileClipboardToRename:{},
                    newFolderRename:""
                })
            })
        }else if(this.state.fileClipboardToRename.path.split("/")[4] !== jwtDecoded.pseudo){ /*if we are not in the client folder, deny*/
            Swal.fire({
                text: "Vous ne pouvez pas renommer un fichier qui ne vous appartient pas.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.setState({
                    displayRenameFolder: false,
                    fileClipboardToRename:{},
                    newFolderRename:""
                })
            })
        }else{
            let newName = this.state.newFolderRename
            let oldName = this.state.fileClipboardToRename.name

            let isFolder = this.state.fileClipboardToRename.type === "dossier"

            let path = `./Q_file/stockageSecure/${!this.state.admin ? `${baseUrl.split("/")[2]}/${jwtDecoded.pseudo}${this.state.breadcrumbsDisplay.length > 0 ? "/" + this.state.breadcrumbsDisplay.join("/") : ""}` : this.state.breadcrumbsDisplay.join("/")}`
            path+="/"+oldName

            if(!isFolder){
                if(newName !== ""){
                    newName = newName.split(".")
                    if(newName.length === 1){
                        newName.push("."+oldName.split("##")[1].split(".")[oldName.split("##")[1].split(".").length-1])
                        newName=newName.join("")
                    }else{
                        newName=newName.join(".")
                    }
                }
            }
            Axios.post(urlAxios+"renameFileAndFolder", {
                newName: newName,
                folder:this.state.fileClipboardToRename.type === "dossier",
                path: path,
                shared:this.state.fileClipboardToRename.shared ? this.state.fileClipboardToRename.shared:false
            }, {headers: {Authorization: "Bearer "+ this.state.jwt}}).then((response) => {
                if (response.data.jwt) {
                    sessionStorage.setItem(this.props.app + "_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }
                if (response.data.resultat === "modified") {
                    let objectFolder = {
                        type: this.state.fileClipboardToRename.type,
                        name: response.data.entityName,
                        content: this.state.fileClipboardToRename.content ? this.state.fileClipboardToRename.content:null,
                        shared: this.state.fileClipboardToRename.shared ? this.state.fileClipboardToRename.shared:null,
                        path: path + "/" + response.data.entityName
                    }
                    let self = this
                    let archiFileTemp = this.state.archiFiles.slice()
                    let splittedPath = path.split("/")
                    this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                    splittedPath.splice(-1)

                    function editArchi(archi, splittedPath, tableauIndex) {
                        if (splittedPath.length === 0) { //Si on est à la racine d'un utilisateur (seulement possible dans le cas d'un client)
                            //Dans ce cas on va directement chercher à la racine le fichier à modifier, donc on findIndex sur archi et on modifie
                            //Cette fonction est identique dans le fonctionnement à celle du else
                            let i=archi.findIndex(elt => elt.name === self.state.fileClipboardToRename.name)
                            archi[i].name = response.data.entityName
                            /*replace the last element of archi[index].content[i].path.split("/") by response.data.entityName*/
                            let pathSplitted = archi[i].path.split("/")
                            pathSplitted.splice(-1)
                            pathSplitted.push(response.data.entityName)
                            archi[i].path = pathSplitted.join("/")

                            if(archi[i].infos){
                                archi[i].infos.fileName = response.data.entityName
                            }
                            if(response.data.folderContent){
                                archi[i].content = response.data.folderContent
                            }
                            self.setState({toDisplay: archi.sort(self.sortListFiles())})
                        } else {
                            archi.forEach((elt, index) => {
                                if (elt.name === splittedPath[0]) {
                                    tableauIndex.push(index)
                                    splittedPath.shift()
                                    if (splittedPath.length !== 0) {
                                        archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                    } else {
                                        let i=archi[index].content.findIndex(elt => elt.name === self.state.fileClipboardToRename.name)
                                        archi[index].content[i].name = response.data.entityName
                                        /*replace the last element of archi[index].content[i].path.split("/") by response.data.entityName*/
                                        let pathSplitted = archi[index].content[i].path.split("/")
                                        pathSplitted.splice(-1)
                                        pathSplitted.push(response.data.entityName)
                                        archi[index].content[i].path = pathSplitted.join("/")

                                        if(archi[index].content[i].infos){
                                            archi[index].content[i].infos.fileName = response.data.entityName
                                        }
                                        if(response.data.folderContent){
                                            archi[index].content[i].content = response.data.folderContent
                                        }
                                    }
                                }
                            })
                        }
                        return archi
                    }
                    Toast.fire({
                        title: "Dossier renommé !",
                        icon: 'success',
                    })

                    this.setState({
                        displayRenameFolder: false,
                        newFolderRename: "",
                        archiFiles: editArchi(archiFileTemp, splittedPath, []),
                        fileClipboardToRename: {},
                    })
                }else{
                    Toast.fire({
                        title: response.data.resultat,
                        icon: 'warning',
                    })
                }
            }).catch(err => {
                console.log(err)
                Swal.fire({
                    title:"Erreur",
                    text: "Une erreur est survenue lors du renommage du dossier.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.setState({fileClipboardToRename: {}})
            })
        }
    }

    deleteFolder(){
        if(!this.state.selectedFiles[0]){
            return
        }
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            let path = `./Q_file/stockageSecure/${!this.state.admin ? `${baseUrl.split("/")[2]}/${jwtDecoded.pseudo}${this.state.breadcrumbsDisplay.length > 0 ? "/" + this.state.breadcrumbsDisplay.join("/") + "/" + this.state.selectedFiles[0].name : ""}` : this.state.breadcrumbsDisplay.join("/") + "/" + this.state.selectedFiles[0].name}`
            let self = this

            function getContent(archi, splittedPath, content) {
                if (splittedPath.length === 0) { //Si on est à la racine d'un utilisateur (seulement possible dans le cas d'un client)
                    content = archi[archi.findIndex(elt => elt.path === self.state.selectedFiles[0].path)]
                } else {
                    archi.forEach((elt, index) => {
                        if (elt.name === splittedPath[0]) {
                            splittedPath.shift()
                            if (splittedPath.length !== 0) {
                                content = getContent(elt.content, splittedPath, content)
                            } else {
                                content = archi[index]
                            }
                        }
                    })
                }
                return content
            }

            if (this.state.selectedFiles.length === 1 && this.state.selectedFiles[0].type === "dossier") {
                if(!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")){
                    let splittedPath = path.split("/")
                    this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                    let fileToDelete = getContent(this.state.archiFiles.slice(), splittedPath, [])
                    if (fileToDelete.content.length !== 0) {
                        Swal.fire({
                            text: "Vous ne pouvez pas supprimer un dossier contant un ou plusieurs fichiers.",
                            icon: 'warning',
                            confirmButtonText: "Ok",
                        })
                    } else {
                        Axios.post(urlAxios+"deleteFolder", {
                            path: this.state.selectedFiles[0].path
                        }, {headers: {Authorization: "Bearer "+this.state.jwt}}).then((response) => {
                            if (response.data.jwt) {
                                sessionStorage.setItem(this.props.app + "_jwt", response.data.jwt)
                                this.setState({
                                    jwt: response.data.jwt
                                },()=>{
                                    this.updateJwt(response.data.jwt)
                                })
                            }
                            if (response.data.resultat === "deleted") {
                                let archiFileTemp = this.state.archiFiles.slice()
                                let splittedPath = path.split("/")
                                splittedPath.pop()
                                this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                                function editArchi(archi, splittedPath, tableauIndex) {
                                    if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                                        archi.splice(archi.findIndex(elt => elt.path === self.state.selectedFiles[0].path), 1)
                                        self.setState({toDisplay: archi.sort(self.sortListFiles())})
                                    } else {
                                        archi.forEach((elt, index) => {
                                            if (elt.name === splittedPath[0]) {
                                                tableauIndex.push(index)
                                                splittedPath.shift()
                                                if (splittedPath.length !== 0) {
                                                    archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                                } else {
                                                    archi[index].content.splice(archi[index].content.findIndex(elt => elt.name === fileToDelete.name), 1)
                                                    self.setState({toDisplay: archi[index].content.sort(self.sortListFiles())})
                                                }
                                            }
                                        })
                                    }
                                    return archi
                                }

                                this.state.selectedFiles.forEach((elt) => {
                                    this.setState({
                                        ["selected" + elt.path]: false
                                    })
                                })
                                this.setState({
                                    selectedFiles: [],
                                    fileSelectedIsFolder: false,
                                    archiFiles: editArchi(archiFileTemp, splittedPath, [])
                                })
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }else{
                    Swal.fire({
                        text: "Vous ne pouvez pas supprimer un dossier qui vous a été partagé.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        }
    }

    moveFileOrFolder(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.setState({
                    displayMoveFileChoice: false
                })
            })
        }else {
            if (!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")) {
                let path = `./Q_file/stockageSecure/${!this.state.admin ? `${baseUrl.split("/")[2]}/${jwtDecoded.pseudo}${this.state.breadcrumbsDisplay.length > 0 ? "/" + this.state.breadcrumbsDisplay.join("/") : ""}` : this.state.breadcrumbsDisplay.join("/")}`
                if (this.state.breadcrumbsDisplay[this.state.breadcrumbsDisplay.length - 1] === this.state.fileClipboardToMove[0].path.split("/")[this.state.fileClipboardToMove[0].path.split("/").length - 2]) { // On vérifie que le déplacement soit utile (pas le meme fichier au départ et à l'arrivé
                    //sameFolder
                } else {
                    if (path.split("/")[4] === jwtDecoded.pseudo) { //On vérifie que l'utilisateur déplace le fichier ou le dossier dans un endroit où il est autorisé (un de ses dossiers)
                        let filesToMove = []
                        this.setState({movingFile:true})
                        this.state.fileClipboardToMove.forEach(file=>{
                            filesToMove.push({
                                folder:file.type === "dossier",
                                oldPath: file.path.includes("notArchived") ? "notArchived/notArchived/notArchived/"+file.path:file.path, //Si c'est un ancien fichier non archivé (pas enregistré) alors on manipule le path pour avoir le pseudo en 5ème position
                                newPath: path + "/" + file.name,
                            })
                        })

                        Axios.post(urlAxios+"moveFile", {
                            pseudo: jwtDecoded.pseudo,
                            client: this.state.onlyClient,
                            filesToMove,
                            node: baseUrl.split("/")[2]
                        }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then((response) => {
                            this.setState({movingFile:false})
                            if (response.data.jwt) {
                                sessionStorage.setItem(this.props.app + "_jwt", response.data.jwt)
                                this.setState({
                                    jwt: response.data.jwt
                                },()=>{
                                    this.updateJwt(response.data.jwt)
                                })
                            }
                            if (response.data.resultat === "moved") {
                                let self = this
                                function updateArchi(archi, splittedPath, content) {
                                    if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                                        content = archi
                                    } else {
                                        archi.forEach((elt, index) => {
                                            if (elt.name === splittedPath[0]) {
                                                splittedPath.shift()
                                                if (splittedPath.length !== 0) {
                                                    content = updateArchi(elt.content, splittedPath, content)
                                                } else {
                                                    content = archi[index]
                                                }
                                            }
                                        })
                                    }
                                    return !!content.content ? content.content : content
                                }

                                let splittedPath = path.split("/")
                                this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)

                                this.setState({
                                    displayMoveFileChoice: false,
                                    archiFiles: response.data.archi
                                }, () => {
                                    this.setState({
                                        toDisplay: updateArchi(response.data.archi, splittedPath, []).sort(self.sortListFiles())
                                    })
                                })
                            }
                        }).catch(err => {
                            this.setState({movingFile:false})
                            console.log(err)
                        })
                    } else {
                        //Pas autorisé car pas dans le bon dossier
                        Swal.fire({
                            text: "Vous n'êtes pas autorisé à déplacer un fichier/dossier ici.",
                            icon: 'warning',
                            confirmButtonText: "Ok",
                        })
                    }
                }
            } else {
                //Pas autorisé car dans le dossier shared
                Swal.fire({
                    text: "Vous n'êtes pas autorisé à déplacer un fichier/dossier ici.",
                    icon: 'warning',
                    confirmButtonText: "Ok",
                })
            }
        }
    }

    AddClientToShare(client){
        this.setState({clientChosenForShare:null})
        let listShareTemp = this.state.listShare
        if(!listShareTemp.includes(client)){
            listShareTemp.push(client)
            this.setState({
                listShare :listShareTemp
            })
        }else{
            Swal.fire({
                title: 'Utilisateur déjà présent !',
                text: "Vous ne pouvez pas ajouter deux fois le même utilisateur dans un partage.",
                target:"#dialogDisplayShare",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    RemoveClientToShare(client){
        let listShareTemp = this.state.listShare
        if(listShareTemp.includes(client)){
            listShareTemp.pop(client)
            this.setState({
                listShare :listShareTemp
            })
        }
    }

    ShareFolderWithClient(){
        let listShareTemp = this.state.listShare.slice()
        let goodFormatListShare = []
        listShareTemp.forEach(elt=> goodFormatListShare.push({pseudo:elt.pseudo,empreinte:elt.empreinte}))
        Axios.post(urlAxios+"shareFolderToClient",{listClient:goodFormatListShare, anonyme:this.state.anonyme, pathToShare:this.state.fileClipboardToShare.path},{headers:{Authorization:"Bearer "+this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            if(response.data.resultat==="done") {
                if(response.data.failedClients.length>0){
                    Swal.fire({
                        title: 'Certains clients ont déjà été ajouté.',
                        text: "Les clients sont : "+response.data.failedClients.join(" "),
                        target:"#dialogDisplayShare",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
                let self = this
                let archiFileTemp = this.state.archiFiles.slice()
                let breadCrumbs = this.state.breadcrumbsDisplay.length >= 1 ? (this.state.breadcrumbsDisplay.join("/")+"/") : ""
                let splittedPath = ("./Q_file/stockageSecure/"+breadCrumbs+this.state.fileClipboardToShare.name).split("/")
                this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(3, splittedPath.length - 1)

                function editArchi(archi, splittedPath,tableauIndex){
                    archi.forEach((elt,index) => {
                        if (elt.name === splittedPath[0]){
                            tableauIndex.push(index)
                            splittedPath.shift()
                            if (splittedPath.length !== 0){
                                archi[index].content = editArchi(elt.content, splittedPath,tableauIndex)
                            }else{
                                if(archi[index].shared === false){
                                    archi[index].shared = goodFormatListShare
                                }else{
                                    goodFormatListShare.forEach((elt)=>{
                                        if(archi[index].shared.findIndex(cl => cl.empreinte === elt.empreinte) === -1){
                                            archi[index].shared.push(elt)
                                        }
                                    })
                                }
                                self.setState({clientChosenForShare:"", listShare:[]});
                            }
                        }
                    })
                    return archi
                }
                this.setState({archiFiles:editArchi(archiFileTemp, splittedPath,[])})

            }else{
                Swal.fire({
                    title: 'Erreur !',
                    text: response.data.resultat,
                    target:"#dialogDisplayShare",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.setState({clientChosenForShare:"", listShare:[]});
            }
        })
    }

    UnshareFolderWithClient(client){
        Axios.post(urlAxios+"cancelSharedFolderToClient",{cancelAll:false, pseudoToDelete:client, path:this.state.fileClipboardToShare.path},{headers:{Authorization:"Bearer "+this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            if(response.data.resultat==="canceled") {
                let archiFileTemp = this.state.archiFiles.slice()
                let breadCrumbs = this.state.breadcrumbsDisplay.length >= 1 ? (this.state.breadcrumbsDisplay.join("/")+"/") : ""
                let splittedPath = ("./Q_file/stockageSecure/"+breadCrumbs+this.state.fileClipboardToShare.name).split("/")
                this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(3, splittedPath.length - 1)
                function editArchi(archi, splittedPath,tableauIndex){
                    archi.forEach((elt,index) => {
                        if (elt.name === splittedPath[0]){
                            tableauIndex.push(index)
                            splittedPath.shift()
                            if (splittedPath.length !== 0){
                                archi[index].content = editArchi(elt.content, splittedPath,tableauIndex)
                            }else{
                                if(archi[index].shared.length === 1){
                                    archi[index].shared = false
                                }else{
                                    archi[index].shared.splice(archi[index].shared.findIndex(elt => elt.pseudo === client),1)
                                }
                            }
                        }
                    })
                    return archi
                }
                this.setState({archiFiles:editArchi(archiFileTemp, splittedPath,[])})
            }
        })
    }

    UnshareFolder(fileStopShare){
        Axios.post(urlAxios+"cancelSharedFolderToClient",{cancelAll:true, path:fileStopShare.path},{headers:{Authorization:"Bearer "+ this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            if(response.data.resultat==="canceled") {
                let archiFileTemp = this.state.archiFiles.slice()
                let breadCrumbs = this.state.breadcrumbsDisplay.length >= 1 ? (this.state.breadcrumbsDisplay.join("/")+"/") : ""
                let splittedPath = ("./Q_file/stockageSecure/"+breadCrumbs+this.state.fileClipboardToShare.name).split("/")
                this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(3, splittedPath.length - 1)
                function editArchi(archi, splittedPath,tableauIndex){
                    archi.forEach((elt,index) => {
                        if (elt.name === splittedPath[0]){
                            tableauIndex.push(index)
                            splittedPath.shift()
                            if (splittedPath.length !== 0){
                                archi[index].content = editArchi(elt.content, splittedPath,tableauIndex)
                            }else{
                                archi[index].shared = false
                            }
                        }
                    })
                    return archi
                }
                this.setState({archiFiles:editArchi(archiFileTemp, splittedPath,[])})
            }
        })
    }

    openSharedFolder(){
        if(!!this.state.pf){
            this.props.navigate("/Kagesecur/TableauDeBord", { replace: true });
        }
        if(this.state.contentOfSharedFolder === null){
            Axios.post(urlAxios+"getSharedFolder",{},{headers:{Authorization:`Bearer `+ this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }

                this.setState({contentOfSharedFolder:response.data.resultat, toDisplay:response.data.resultat.sort(this.sortListFiles())})
            })
        }else{
            this.setState({toDisplay:this.state.contentOfSharedFolder.sort(this.sortListFiles())})
        }
        this.state.selectedFiles.forEach(elt=>{
            this.setState({
                ["selected"+elt.path]:false
            })
        })
        let breadcrumbsDisplay = this.state.breadcrumbsDisplay.slice()
        breadcrumbsDisplay.push("#shared#Dossiers partagés")
        this.setState({breadcrumbsDisplay:breadcrumbsDisplay,selectedFiles:[],fileSelectedIsFolder:false,disableMenu:false,disableButtonMenu:true})
    }

    uploadFileInFolder(avecValidation){
        this.setState({displayDialogUploadFile:false})
        let copyBreadcrumbs = this.state.breadcrumbsDisplay.slice()
        if (this.state.admin){
            copyBreadcrumbs.splice(0,2)
        }
        //oracle
        let pathInUrl = copyBreadcrumbs.join("prctslashprct")
        window.location.replace("/KageSecur/"+(avecValidation?"Avec":"Sans")+"Validation?pf="+pathInUrl)
    }

    getAllClients(){
        Axios.post(urlAxios+"getAllClients",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            let allClientsTemp = [], clientsOthersNodes = [];
            if(response.data.resultat !== "Sans abonnement à KageSecur, cette fonctionnalité n'est pas disponible."){
                (response.data.resultat).forEach(client=>{
                    if (client.thisSrv){
                        client.label = client.pseudo
                        allClientsTemp.push(client)
                    }else{
                        client.label = client.pseudo
                        clientsOthersNodes.push(client)
                    }
                })
            }

            this.setState({
                allClients:allClientsTemp,
                allClientsDisplayed:allClientsTemp,
                allClientsOthersNodes:clientsOthersNodes
            })
        })
    }

    AddClientToSignataire(client){
        this.setState({clientChosenForSignataire:null})
        let listSignataireTemp = this.state.listSignataire
        if(!listSignataireTemp.includes(client)){
            listSignataireTemp.push(client)
            this.setState({
                listSignataire :listSignataireTemp
            })
        }else{
            Swal.fire({
                title: 'Utilisateur déjà présent !',
                text: "Vous ne pouvez pas ajouter deux fois le même utilisateur en tant que signataire.",
                target:"#dialogDisplaySignature",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    RemoveClientToSignataire(client){
        let listSignataireTemp = this.state.listSignataire
        let indexClient = listSignataireTemp.findIndex(elt=>elt.pseudo === client)
        if(indexClient !== -1){
            listSignataireTemp.splice(indexClient,1)
            this.setState({
                listSignataire :listSignataireTemp
            })
        }
    }

    changeListAllClient(){ //Filtre
        let allClientToDisplay = []
        this.state.allClients.forEach(elt=>{
            if((elt.status === "client" && elt.abonnement === "Sans abonnement") && this.state.searchWithCSA){
                allClientToDisplay.push(elt)
            }else if ((elt.status === "client" && elt.abonnement !== "Sans abonnement") && this.state.searchWithC){
                allClientToDisplay.push(elt)
            }else if ((elt.status === "gestionnaire" || elt.status === "admin") && this.state.searchWithG){
                allClientToDisplay.push(elt)
            }
        })
        if (this.state.searchWithCSA === false && this.state.searchWithC === false && this.state.searchWithG === false){
            allClientToDisplay = this.state.allClients.slice()
        }
        this.setState({allClientsDisplayed:allClientToDisplay})
    }

    getSignEnCours(){
        Axios.post(urlAxios+"getSignEnCours",{hashFile:this.state.selectedFiles[0].infos.hashFile},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            this.setState({listAllSignatureEnCours:response.data.allSignataires})
        })
    }

    cancelThisValidation(signature){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            Swal.fire({
                title: 'Attention!',
                text: "Voulez-vous vraiment annuler la demande de signature de ce document ?",
                icon: 'warning',
                target:"#openGestionSignatureDialog",
                confirmButtonText: "Oui j'annule la demande",
                confirmButtonColor: "red",
                showCancelButton: true,
                cancelButtonText: "Retour",
                cancelButtonColor: "#369DD7"
            }).then((result) => {
                if (result.isConfirmed) {
                    Axios.post(urlAxios+"cancelSignature",{hashFile:this.state.selectedFiles[0].infos.hashFile,empreinteSignataire:signature.signataireEmpreinte},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                        if(response.data.jwt){
                            sessionStorage.setItem(this.props.app+"_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                this.updateJwt(response.data.jwt)
                            })
                        }
                        let listAllSignatureEnCoursTemp = this.state.listAllSignatureEnCours
                        listAllSignatureEnCoursTemp.splice(listAllSignatureEnCoursTemp.findIndex(elt => signature.signataireEmpreinte === elt.signataireEmpreinte),1)
                        this.setState({listAllSignatureEnCours:listAllSignatureEnCoursTemp})
                        if(response.data.newStatus !== undefined){ //Si on doit changer de pastille
                            let archiFileTemp = this.state.archiFiles.slice()
                            let splittedPath = this.state.selectedFiles[0].path.split("/")
                            if(!this.state.selectedFiles[0].infos.archived){
                                if(this.state.admin){
                                    splittedPath.shift()
                                    splittedPath.unshift(baseUrl.split("/")[2])
                                }else{
                                    splittedPath = splittedPath.slice(2)
                                }
                            }else{
                                this.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                            }
                            function editArchi(archi, splittedPath, tableauIndex) {
                                archi.forEach((elt, index) => {
                                    if (elt.name === splittedPath[0]) {
                                        tableauIndex.push(index)
                                        splittedPath.shift()
                                        if (splittedPath.length !== 0) {
                                            archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                        } else {
                                            archi[index].infos.signed = response.data.newStatus
                                        }
                                    }
                                })
                                return archi
                            }

                            this.setState({archiFiles: editArchi(archiFileTemp, splittedPath, [])})
                        }
                    })
                }
            })
        }
    }

    addRecepteurPreuveviaMail(){
        if (this.state.recepteurProofName && this.state.recepteurProofEmail && (this.state.recepteurProofEmail).match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)) {
            let objectToPush = {recepteurName:this.state.recepteurProofName, recepteurEmail:this.state.recepteurProofEmail}
            let listRecepteurPreuveViaMailTemp = this.state.listRecepteurPreuveViaMail.slice()
            if(listRecepteurPreuveViaMailTemp.findIndex(recepteur => recepteur.recepteurName === this.state.recepteurProofName)=== -1 && listRecepteurPreuveViaMailTemp.findIndex(recepteur => recepteur.recepteurEmail === this.state.recepteurProofEmail)=== -1){
                listRecepteurPreuveViaMailTemp.push(objectToPush)
                this.setState({recepteurProofName:"",recepteurProofEmail:""})
            }else{
                Toast.fire({
                    title: "Ce récepteur est déjà présent dans la liste.",
                    target:"#dialogAddPreuveViaMail",
                    icon: 'warning'
                })
            }
            this.setState({listRecepteurPreuveViaMail:listRecepteurPreuveViaMailTemp})
        } else {
            Swal.fire({
                text: "Veuillez remplir les informations demandées correctement.",
                icon: 'warning',
                target:"#dialogAddPreuveViaMail",
                confirmButtonText: "Ok"
            }).then(() => {
                this.setState({recepteurProofName:"",recepteurProofEmail:""})
            })
        }
    }

    removeRecepteurPreuveviaMail(recepteurName){
        // let objectToPush = {recepteurName:this.state.recepteurProofName, recepteurEmail:this.state.recepteurProofEmail}
        let listRecepteurPreuveViaMailTemp = this.state.listRecepteurPreuveViaMail.slice()
        let indexToDelete = listRecepteurPreuveViaMailTemp.findIndex(recepteur => recepteur.recepteurName === recepteurName)
        listRecepteurPreuveViaMailTemp.splice(indexToDelete,1)
        this.setState({listRecepteurPreuveViaMail:listRecepteurPreuveViaMailTemp})
    }

    reSendArchive(){
        this.setState({progressReUploadArchive:0,estimatedTimeReUploadArchive:0,packedDebitReUploadArchive:0,reUploadingFile:true, cancelReSend:false}, ()=>{
            let userDisconnected = false
            let fileToResend = this.state.fileToResend
            let errorOccured = false
            let handleErrorSocket = (error) => {
                errorOccured = true
                this.setState({
                    reUploadingFile:false,
                    cancelReSend:true,
                    displayDialogUploadArchivage:false,
                    fileToResend:null,
                    acceptedFileArchive:null,
                })
                Swal.fire({
                    title:"Erreur",
                    text:error,
                    icon:"error",
                    confirmButtonText: 'Ok'
                })
                console.error("Socket error:", error);
            }
            socketIO.on("error", handleErrorSocket);
            const data = {toArchive:true, fileName:fileToResend.name, codeUpload:fileToResend.path, bearer:this.state.jwt}
            askPermToUpload(socketIO,"askPermToReUpload", data).then(response => {
                let codeUpload = response.codeUpload
                if(response.isAuthorized && !errorOccured) {
                    let stream = this.state.acceptedFileArchive.stream()
                    let re = stream.getReader()
                    let self = this
                    let totalSizeAlreadySent = 0, totalSize = this.state.acceptedFileArchive.size
                    let initializeHandles = true
                    let handleConnectSocket,handleDisconnectSocket
                    new ReadableStream({
                        start(controller) {
                            let chunksToSend = []

                            if(initializeHandles){
                                initializeHandles = false

                                handleConnectSocket = () => {
                                    // Ne log que quand l'user s'est reconnecté puisque ce listener n'est activé que ici
                                    console.log("Reconnected to socket");
                                    Toast.fire({
                                        title: 'Reconnexion au serveur.',
                                        icon: 'success',
                                    })
                                    if(userDisconnected){
                                        userDisconnected = false
                                        const data = {
                                            bearer:self.state.jwt,
                                            codeUpload,
                                            empreinte:jwtDecoded.empreinte,
                                            toArchive:true,
                                            fileName:fileToResend.name
                                        }
                                        askPermToUpload(socketIO,"askPermToReUpload", data).then(response => {
                                            if (response.isAuthorized){
                                                //Vider le tableau chunksToSend avant de reprendre la lecture

                                                function sendMissingChunks(){
                                                    return new Promise(async resolve => {
                                                        if (chunksToSend.length === 0){
                                                            resolve()
                                                        }else{
                                                            const data = {
                                                                buffer:chunksToSend[0],
                                                                status:"upload",
                                                                bearer:self.state.jwt
                                                            }
                                                            uploadChunk(socketIO, "reSendChunk##"+codeUpload, data).then(responseSC => {
                                                                chunksToSend.shift()
                                                                resolve(sendMissingChunks())
                                                            })
                                                        }
                                                    })
                                                }
                                                sendMissingChunks().then(()=>{
                                                    push()
                                                })
                                            }
                                        })
                                    }
                                }

                                handleDisconnectSocket = (reason) => {
                                    console.log("Disconnected from the server:", reason);
                                    userDisconnected = true
                                    self.setState({userDisconnected:true})
                                    Toast.fire({
                                        title: 'Problème réseau, reconnexion dès que possible.',
                                        icon: 'error',
                                    })
                                }


                                socketIO.on("connect",handleConnectSocket);
                                socketIO.on("disconnect",handleDisconnectSocket);
                            }
                            function push() {
                                const packetStartTime = Date.now()
                                re.read().then( ({done, value}) => {
                                    if (done) {
                                        self.setState({
                                            progressReUploadArchive:100,
                                            estimatedTimeReUploadArchive:0,
                                            packedDebitReUploadArchive:0
                                        })
                                        const data={
                                            status:"ended",
                                            bearer:self.state.jwt
                                        }
                                        uploadChunk(socketIO, "reSendChunk##"+codeUpload, data).then(responseSC => {
                                            if(responseSC.resultat === "done"){
                                                socketIO.off("error",handleErrorSocket)
                                                socketIO.off("disconnect",handleDisconnectSocket)
                                                socketIO.off("connect",handleConnectSocket)
                                                let archiFileTemp = self.state.archiFiles.slice()
                                                let splittedPath = self.state.fileToResend.path.split("/")
                                                self.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                                                function editArchi(archi, splittedPath, tableauIndex) {
                                                    archi.forEach((elt, index) => {
                                                        if (elt.name === splittedPath[0]) {
                                                            tableauIndex.push(index)
                                                            splittedPath.shift()
                                                            if (splittedPath.length !== 0) {
                                                                archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                                            } else {
                                                                archi[index].infos.archived = true
                                                                archi[index].infos.size = responseSC.size
                                                                archi[index].path = responseSC.path
                                                            }
                                                        }
                                                    })
                                                    return archi
                                                }

                                                self.setState({
                                                    archiFiles: editArchi(archiFileTemp, splittedPath, [])
                                                })
                                            }

                                            self.setState({
                                                resultatReSend:responseSC.resultat,
                                                reUploadingFile:false,
                                                displayDialogUploadArchivage:false,
                                                fileToResend:null,
                                                acceptedFileArchive:null,
                                            }, ()=>{
                                                Swal.fire({
                                                    text: responseSC.resultat === "done" ? "Le fichier a été stocké dans le serveur !" : responseSC.resultat,
                                                    icon: responseSC.resultat === "done" ? "success":"error",
                                                    confirmButtonText: 'Ok'
                                                }).then(()=>{
                                                    self.setState({resultatReSend:""})
                                                })
                                            })
                                            controller.close();
                                        })
                                    }else{
                                        const data={
                                            buffer:value,
                                            status:"upload",
                                            bearer:self.state.jwt
                                        }
                                        uploadChunk(socketIO, "reSendChunk##"+codeUpload, data).then(responseSC => {
                                            if(responseSC.isAuthorized){
                                                totalSizeAlreadySent += value.length;
                                                // let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                // SHA.update(wordBuffer);

                                                /*The amount of time it took to send this chunk (in s)*/
                                                const packetTime = (Date.now() - packetStartTime) / 1000;
                                                /*débit d'upload en Mbytes/s*/
                                                let packetDebit = ((value.length / packetTime) / (1024 * 1024))
                                                /*the estimated time of the upload in s)*/
                                                const estimatedTime = Math.floor(((totalSize - totalSizeAlreadySent) / (1024 * 1024)) / packetDebit);

                                                const progressUploadEnCours = Math.round((totalSizeAlreadySent / totalSize) * 100);
                                                if(estimatedTime !== self.state.estimatedTimeReUploadArchive){
                                                    self.setState({
                                                        estimatedTimeReUploadArchive: estimatedTime
                                                    })
                                                }
                                                if(progressUploadEnCours !== self.state.progressReUploadArchive){
                                                    self.setState({
                                                        progressReUploadArchive:progressUploadEnCours
                                                    })
                                                }
                                                if(packetDebit != self.state.packedDebitReUploadArchive){
                                                    self.setState({
                                                        packedDebitReUploadArchive:packetDebit
                                                    })
                                                }
                                                if (!self.state.cancelReSend){
                                                    push();
                                                }else{
                                                    socketIO.off("error",handleErrorSocket)
                                                    socketIO.off("disconnect",handleDisconnectSocket)
                                                    socketIO.off("connect",handleConnectSocket)
                                                    self.setState({reUploadingFile:false})
                                                    socketIO.emit("reSendChunk##"+codeUpload, {status:"cancel", bearer:self.state.jwt}, () => {
                                                        Toast.fire({
                                                            icon: 'info',
                                                            title: 'Upload annulé !'
                                                        })
                                                    })
                                                }
                                            }else{
                                                Swal.fire({
                                                    title: 'Erreur!',
                                                    text: "Vous n'êtes pas autorisé à envoyer un fichier.",
                                                    icon: 'error',
                                                    confirmButtonText: 'Ok'
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                            push();
                        }
                    });
                }
            })
        })
    }

    sendFileForSignature(){
        this.setState({progressReUploadSignature:0,estimatedTimeReUploadSignature:0,packetDebitReUploadSignature:0,reUploadingSignatureFile:true, cancelSignatureReSend:false}, ()=>{
            let userDisconnected = false
            let fileToResend = this.state.selectedFiles[0]
            let errorOccured = false
            let handleErrorSocket = (error) => {
                errorOccured = true
                this.setState({
                    reUploadingFile:false,
                    cancelReSend:true,
                    displayDialogUploadArchivage:false,
                    fileToResend:null,
                    acceptedFileArchive:null,
                })
                Swal.fire({
                    title:"Erreur",
                    text:error,
                    icon:"error",
                    confirmButtonText: 'Ok'
                })
                console.error("Socket error:", error);
            }
            socketIO.on("error", handleErrorSocket);
            const data = {toArchive:false, fileName:fileToResend.name, bearer:this.state.jwt}
            askPermToUpload(socketIO,"askPermToReUpload", data).then(response => {
                if(response.isAuthorized === true) {
                    let stream = this.state.acceptedFileSignature.stream()
                    let re = stream.getReader()
                    let self = this
                    let codeUpload = response.codeUpload
                    let totalSizeAlreadySent = 0, totalSize = this.state.acceptedFileSignature.size
                    let initializeHandles = true
                    let handleConnectSocket,handleDisconnectSocket
                    new ReadableStream({
                        start(controller) {
                            let chunksToSend = []
                            if(initializeHandles){
                                initializeHandles = false
                                handleConnectSocket = () => {
                                    // Ne log que quand l'user s'est reconnecté puisque ce listener n'est activé que ici
                                    console.log("Reconnected to socket");
                                    Toast.fire({
                                        title: 'Reconnexion au serveur.',
                                        icon: 'success',
                                    })
                                    if(userDisconnected){
                                        userDisconnected = false
                                        const data = {
                                            toArchive:false,
                                            fileName:fileToResend.name,
                                            bearer:self.state.jwt
                                        }
                                        askPermToUpload(socketIO,"askPermToReUpload", data).then(response => {
                                            if (response.isAuthorized){
                                                //Vider le tableau chunksToSend avant de reprendre la lecture

                                                function sendMissingChunks(){
                                                    return new Promise(async resolve => {
                                                        if (chunksToSend.length === 0){
                                                            resolve()
                                                        }else{
                                                            const data = {
                                                                buffer:chunksToSend[0],
                                                                status:"upload",
                                                                bearer:self.state.jwt
                                                            }
                                                            uploadChunk(socketIO, "reSendChunk##"+codeUpload, data).then(responseSC => {
                                                                chunksToSend.shift()
                                                                resolve(sendMissingChunks())
                                                            })
                                                        }
                                                    })
                                                }
                                                sendMissingChunks().then(()=>{
                                                    push()
                                                })
                                            }
                                        })
                                    }
                                }

                                handleDisconnectSocket = (reason) => {
                                    console.log("Disconnected from the server:", reason);
                                    userDisconnected = true
                                    self.setState({userDisconnected:true})
                                    Toast.fire({
                                        title: 'Problème réseau, reconnexion dès que possible.',
                                        icon: 'error',
                                    })
                                }


                                socketIO.on("connect",handleConnectSocket);
                                socketIO.on("disconnect",handleDisconnectSocket);
                            }
                            function push() {
                                const packetStartTime = Date.now()
                                re.read().then( ({done, value}) => {
                                    if (done) {
                                        self.setState({
                                            progressReUploadSignature:100,
                                            estimatedTimeReUploadSignature:0,
                                            packetDebitReUploadSignature:0
                                        })
                                        const data={status:"ended", bearer:self.state.jwt}
                                        uploadChunk(socketIO, "reSendChunk##"+codeUpload, data).then(responseSC => {
                                            if(responseSC.resultat === "done"){
                                                let archiFileTemp = self.state.archiFiles.slice()
                                                let splittedPath = fileToResend.path.split("/")
                                                if(!fileToResend.infos.archived){
                                                    if(self.state.admin){
                                                        splittedPath.shift()
                                                        splittedPath.unshift(baseUrl.split("/")[2])
                                                    }else{
                                                        splittedPath = splittedPath.slice(2)
                                                    }
                                                }else{
                                                    self.state.admin ? splittedPath = splittedPath.splice(3, splittedPath.length - 2) : splittedPath = splittedPath.splice(5, splittedPath.length - 2)
                                                }
                                                function editArchi(archi, splittedPath, tableauIndex) {
                                                    archi.forEach((elt, index) => {
                                                        if (elt.name === splittedPath[0]) {
                                                            tableauIndex.push(index)
                                                            splittedPath.shift()
                                                            if (splittedPath.length !== 0) {
                                                                archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                                            } else {
                                                                archi[index].infos.signed = (archi[index].infos.signed === false ? "beingSigned":"intermediate")
                                                            }
                                                        }
                                                    })
                                                    return archi
                                                }
                                                self.setState({
                                                    archiFiles: editArchi(archiFileTemp, splittedPath, [])
                                                })
                                                self.sendNewDemandeSignature()
                                            }
                                            self.setState({
                                                resultatSignatureReSend:responseSC.resultat,
                                                reUploadingSignatureFile:false,
                                                openSignatureDialog:false,
                                                dialogDemandeSignaturePhase:1,
                                                clientChosenForSignataire:null,
                                                acceptedFileSignature:null,
                                                listSignataire:[]
                                            },()=>{
                                                Swal.fire({
                                                    text: responseSC.resultat === "done" ? "La demande de signature a été envoyée !" : responseSC.resultat,
                                                    icon: responseSC.resultat === "done" ? "success":"error",
                                                    confirmButtonText: 'Ok'
                                                }).then(()=>{
                                                    self.setState({resultatSignatureReSend:""})
                                                })
                                            })
                                            controller.close();
                                        })
                                    }else{
                                        const data= {buffer:value, status:"upload", bearer:self.state.jwt}
                                        uploadChunk(socketIO, "reSendChunk##"+codeUpload, data).then(responseSC => {
                                            if(responseSC.isAuthorized && !errorOccured){
                                                totalSizeAlreadySent += value.length;
                                                // let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                // SHA.update(wordBuffer);

                                                /*The amount of time it took to send this chunk (in s)*/
                                                const packetTime = (Date.now() - packetStartTime) / 1000;
                                                /*débit d'upload en Mbytes/s*/
                                                let packetDebit = ((value.length / packetTime) / (1024 * 1024))
                                                /*the estimated time of the upload in s)*/
                                                const estimatedTime = Math.floor(((totalSize - totalSizeAlreadySent) / (1024 * 1024)) / packetDebit);

                                                const progressUploadEnCours = Math.round((totalSizeAlreadySent / totalSize) * 100);
                                                if(estimatedTime !== self.state.estimatedTimeReUploadSignature){
                                                    self.setState({
                                                        estimatedTimeReUploadSignature: estimatedTime
                                                    })
                                                }
                                                if(progressUploadEnCours !== self.state.progressReUploadSignature){
                                                    self.setState({
                                                        progressReUploadSignature:progressUploadEnCours
                                                    })
                                                }
                                                if(packetDebit != self.state.packetDebitReUploadSignature){
                                                    self.setState({
                                                        packetDebitReUploadSignature:packetDebit
                                                    })
                                                }
                                                setTimeout(()=>{
                                                    if (!self.state.cancelReSend){
                                                        push();
                                                    }else{
                                                        socketIO.off("error",handleErrorSocket)
                                                        socketIO.off("disconnect",handleDisconnectSocket)
                                                        socketIO.off("connect",handleConnectSocket)
                                                        self.setState({reUploadingSignatureFile:false})
                                                        socketIO.emit("reSendChunk##"+codeUpload, {status:"cancel", bearer:self.state.jwt}, () => {
                                                            Toast.fire({
                                                                icon: 'info',
                                                                title: 'Upload annulé !'
                                                            })
                                                        })
                                                    }
                                                },value.length/1000000)
                                            }else{
                                                Swal.fire({
                                                    title: 'Erreur!',
                                                    text: "Vous n'êtes pas autorisé à envoyer un fichier.",
                                                    icon: 'error',
                                                    confirmButtonText: 'Ok'
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                            push();
                        }
                    });
                }else if(response.isAuthorized === "noUpload"){
                    this.setState({
                        resultatSignatureReSend:"",
                        reUploadingSignatureFile:false,
                        progressReUploadSignature:0,
                        estimatedTimeReUploadSignature:0,
                        openSignatureDialog:false,
                        dialogDemandeSignaturePhase:1,
                        clientChosenForSignataire:null,
                        acceptedFileSignature:null,
                    })
                    this.sendNewDemandeSignature()
                }
            })
        })
    }

    changeSortStatus(toChange){
        let newValueSort = toChange
        let sortStatusTemp = this.state.sortStatus.split("-")
        if(sortStatusTemp[0]===toChange){
            newValueSort += sortStatusTemp[1]==="down" ? "-up":"-down"
        }else{
            newValueSort +="-down"
        }
        this.setState({sortStatus:newValueSort})
        let toDisplayTemp = this.state.toDisplay.slice()
        if(toChange === "date"){
            toDisplayTemp.sort(compareDate)
            function compareDate( a, b ) {
                let compareA = a.infos ? parseInt(a.infos.idToken.substring(0,13)):0
                let compareB = b.infos ? parseInt(b.infos.idToken.substring(0,13)):0
                if(newValueSort.split("-")[1] === "up"){
                    return compareB - compareA
                }else{
                    return compareA - compareB
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }
        if(toChange === "nom"){
            toDisplayTemp.sort(compareAlphabetical)
            function compareAlphabetical( a, b ) {
                //On sépare l'id token du nom si besoin
                let compareA = a.name.includes("##") ? a.name.split("##")[1]:a.name
                let compareB = b.name.includes("##") ? b.name.split("##")[1]:b.name
                if(newValueSort.split("-")[1] === "up"){
                    return compareB.localeCompare(compareA)
                }else{
                    return compareA.localeCompare(compareB)
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }
        if(toChange === "poids"){
            toDisplayTemp.sort(compareSize)
            function compareSize( a, b ) {
                //non-archivé = 0 | dossier = -1
                let compareA = a.infos ? (a.infos.size ? a.infos.size:0):-1
                let compareB = b.infos ? (b.infos.size ? b.infos.size:0):-1
                if(newValueSort.split("-")[1] === "up"){
                    return (compareB - compareA)
                }else{
                    return (compareA - compareB)
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }

        if(toChange === "archivage"){
            toDisplayTemp.sort(compareArchived)
            function compareArchived( a, b ) {
                //archivé = 1 | non-archivé = 0 | dossier = -1
                let compareA = a.infos ? (a.infos.archived ? 1:0):-1
                let compareB = b.infos ? (b.infos.archived ? 1:0):-1
                if(newValueSort.split("-")[1] === "up"){
                    return (compareB - compareA)
                }else{
                    return (compareA - compareB)
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }

        if(toChange === "signature"){
            toDisplayTemp.sort(compareSignature)
            function compareSignature( a, b ) {
                //signé = 2 | semi-signé = 1 | en cours de signature = 0 | sans signature = -1 | dossier = -2
                let compareA = a.infos ? (a.infos.signed.toString() === "false" ? -1:(a.infos.signed.toString()==="true" ? 2:(a.infos.signed==="beingSigned" ? 0:(a.infos.signed==="intermediate" ? 1:-2)))):-2
                let compareB = b.infos ? (b.infos.signed.toString() === "false" ? -1:(b.infos.signed.toString()==="true" ? 2:(b.infos.signed==="beingSigned" ? 0:(b.infos.signed==="intermediate" ? 1:-2)))):-2
                if(newValueSort.split("-")[1] === "up"){
                    return (compareB - compareA)
                }else{
                    return (compareA - compareB)
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }
    }

    displayFileCreation(){
        this.setState({displayCreateFolderLine:true})
        let divScroll = document.getElementById("divScroll");
        divScroll.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }


    render(){ //Affichage sur la page
        return (
            <div className={"flexCenter divClickDeselect"} style={{height:"100%",width:"100%",flexDirection:"column"}}>
                <div id={"menuRightClick"}>
                    {this.state.selectedFiles.length===0 ? (
                        <>
                            <button className={"options"} onClick={()=>{this.handleCloseMenu();!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")?
                                (this.setState({displayDialogUploadFile:true})
                                ):(
                                    Swal.fire({
                                        title: 'Erreur!',
                                        text: "Vous ne pouvez enregistrer un document dans vos dossiers partagés.",
                                        icon: 'warning',
                                        confirmButtonText: 'Ok'
                                    })
                                )}}>&nbsp;<CloudUpload style={{marginRight:2}}/>&nbsp;Enregistrer un document dans ce dossier</button>
                        </>
                    ):(
                        this.state.fileSelectedIsFolder ? (
                            <>
                                {!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés") && !(this.state.selectedFiles[0] && this.state.selectedFiles[0].name.includes("#shared#Dossiers partagés")) ? (
                                    <>
                                        <button className={"options"} disabled={this.state.freeClient} onClick={()=>{
                                            this.handleCloseMenu();
                                            this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                            Toast.fire({
                                                title: "Naviguez dans le dossier de votre choix (double clic) et cliquez sur 'Déplacer ici'.",
                                                icon: 'info',
                                            })
                                        }}>
                                            &nbsp;<DriveFolderUpload style={{marginRight:2}}/>&nbsp;Déplacer
                                        </button>
                                        <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient} onClick={()=>{
                                            this.handleCloseMenu();
                                            this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name})
                                        }}>
                                            &nbsp;<Edit style={{marginRight:2}}/>&nbsp;Renommer
                                        </button>
                                        <button className={"options"} disabled={this.state.selectedFiles.length!==1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip")} onClick={()=>{
                                            this.handleCloseMenu();
                                            this.clickDownload(true)
                                        }}>
                                            &nbsp;<GetAppIcon style={{marginRight:2}}/>&nbsp;Télécharger (.zip)
                                        </button>
                                    </>
                                ):(
                                    <>
                                        <button className={"options"} disabled={true}>&nbsp;<DriveFolderUpload style={{marginRight:2}}/>&nbsp;Déplacer</button>
                                        <button className={"options"} disabled={true}>&nbsp;<Edit style={{marginRight:2}}/>&nbsp;Renommer</button>
                                        <button className={"options"} disabled={this.state.selectedFiles.length!==1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].name === "#shared#Dossiers partagés")} onClick={()=>{
                                            this.handleCloseMenu();
                                            this.clickDownload(true)
                                        }}>
                                            &nbsp;<GetAppIcon style={{marginRight:2}}/>&nbsp;Télécharger (.zip)
                                        </button>
                                    </>
                                )}
                                {!this.state.onlyClient && (
                                    <>
                                        <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés") || (this.state.selectedFiles[0] && this.state.selectedFiles[0].name.includes("#shared#Dossiers partagés"))} onClick={()=>{this.handleCloseMenu();this.state.allClients.length === 0 && this.getAllClients();this.setState({displayShareFolder:true,fileClipboardToShare:this.state.selectedFiles[0]})}}>&nbsp;<FolderShared style={{marginRight:2}}/>&nbsp;{(this.state.selectedFiles[0] &&this.state.selectedFiles[0].shared === false) ? "Partager le dossier":"Gérer le partage"}</button>
                                        {(this.state.selectedFiles[0] &&this.state.selectedFiles[0].shared !== false) &&(
                                            <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés") || (this.state.selectedFiles[0] && this.state.selectedFiles[0].name.includes("#shared#Dossiers partagés"))} onClick={()=>{this.handleCloseMenu();this.setState({fileClipboardToShare:this.state.selectedFiles[0]});this.UnshareFolder(this.state.selectedFiles[0]);}}>&nbsp;<Close style={{marginRight:2}}/>&nbsp;Arreter le partage</button>
                                        )}
                                    </>
                                )}
                            </>
                        ):(
                            <>
                                <button className={"options"} disabled={(this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient ||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{this.handleCloseMenu();this.sendProofMail()}}>&nbsp; <Mail style={{marginRight:2}}/> &nbsp; Envoyer le fichier/preuve</button>
                                {this.state.selectedFiles[0] && this.state.selectedFiles[0].infos &&( this.state.selectedFiles[0].infos.signed === "beingSigned" || this.state.selectedFiles[0].infos.signed === "intermediate") &&
                                    (
                                        <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")}
                                                onClick={()=>{
                                                    this.getSignEnCours()
                                                    this.handleCloseMenu()
                                                    this.setState({openGestionSignatureDialog:true})
                                                }}
                                        >
                                            <>&nbsp;<FactCheck style={{marginRight:2}}/>&nbsp;Gérer les signatures</>
                                        </button>
                                    )
                                }
                                <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && (this.state.selectedFiles[0].status === "inZip" || (this.state.selectedFiles[0].infos && (!this.state.selectedFiles[0].infos.archived)))) || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")}
                                        onClick={()=>{
                                            this.getSignEnCours()
                                            this.handleCloseMenu()
                                            this.setState({openSignatureDialog:true})
                                        }}
                                >
                                    <>&nbsp;<Gavel style={{marginRight:2}}/>&nbsp;Demande de signature</>
                                </button>
                                <button className={"options"} disabled={this.state.selectedFiles.length>1 || this.state.selectedFiles.type !== "dossier" && this.state.selectedFiles.findIndex(elt => (elt.infos && elt.infos.archived === false)) !== -1} onClick={()=>{this.handleCloseMenu();this.openFileOrFolder(this.state.selectedFiles[0])}}>&nbsp;<Visibility style={{marginRight:2}}/>&nbsp;Prévisualiser</button>
                                <button className={"options"} disabled={this.state.selectedFiles.type !== "dossier" && this.state.selectedFiles.findIndex(elt => (elt.infos && elt.infos.archived === false)) !== -1} onClick={()=>{this.handleCloseMenu();this.clickDownload()}}>&nbsp;<GetAppIcon style={{marginRight:2}}/>&nbsp;Télécharger</button>
                                <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{this.handleCloseMenu();this.createCertificate()}}>&nbsp;<Description style={{marginRight:2}}/>&nbsp;Créer un certificat</button>
                                <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{this.handleCloseMenu();this.goToTraceability()}}>&nbsp;<AccountTree style={{marginRight:2}}/>&nbsp;Traçabilité</button>
                                <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{
                                    this.handleCloseMenu();
                                    this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name.split("##")[1]})
                                }}>
                                    &nbsp;<Edit style={{marginRight:2}}/>&nbsp;Renommer
                                </button>
                                {!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")  && (
                                    <button className={"options"} disabled={this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{
                                        this.handleCloseMenu();
                                        this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                        Toast.fire({
                                            title: "Naviguez dans le dossier de votre choix et cliquez sur 'Déplacer ici'.",
                                            icon: 'info',
                                        })
                                    }}>&nbsp;<DriveFolderUpload style={{marginRight:2}}/>&nbsp;Déplacer</button>
                                )}
                            </>
                        )
                    )}
                </div>
                <Dialog
                    open={this.state.dialogPrevisuOpen}
                    onClose={()=>{this.setState({dialogPrevisuOpen: false},()=>{this.setState({previsuFile:null})})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                    fullScreen={!this.state.showBrowserView}
                    style={{height:"calc(100% - 110px)",width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                >
                    <DialogTitle>{this.state.previsuFile &&(
                        <div className={"flexCenter"}>
                            {!this.state.showBrowserView && (
                                <ArrowBack style={{cursor:"pointer"}} onClick={()=>{this.setState({dialogPrevisuOpen: false})}} />
                            )}
                            {this.state.fileNameCiphered ? (this.state.previsuFile.name.split("##")[1].split(".")[0].slice(0,4)+"..."+this.state.previsuFile.name.split("##")[1].split(".")[0].slice(-4)+"."+this.state.previsuFile.name.split("##")[1].split(".")[1]):this.state.previsuFile.name.split("##")[1]}
                        </div>
                    )}
                    </DialogTitle>
                    <DialogContent style={{padding:0,paddingLeft:8,paddingRight:8}}>
                        {this.state.previsuFile &&(
                            <img alt={"Document"} style={{maxWidth: 700, maxHeight: 500}} src={`data:${"image"+this.state.previsuFile.name.split(".")[1]};base64,${Buffer.from(this.state.previsuFile.buffer).toString("base64")}`} />
                        )}
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayDialogInfo}
                    onClose={()=>{this.setState({displayDialogInfo: false, fileInfo:null})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                    fullScreen={!this.state.showBrowserView}
                    style={{height:"calc(100% - 110px)",width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                >
                    <div style={{position:"absolute",width:"100%", textAlign:"right"}}>
                        <Close onClick={()=>{this.setState({displayDialogInfo: false, fileInfo:null})}} style={{margin:5,cursor:"pointer"}}/>
                    </div>
                    <DialogTitle>
                        Informations du fichier :
                    </DialogTitle>
                    <DialogContent style={{maxWidth:450}}>
                        {this.state.fileInfo && (
                            <>
                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <p style={{margin:0}}>Nom : <span style={{fontWeight:"bold"}}>{this.state.fileInfo && this.state.fileInfo.name.split("##")[1]}</span></p>
                                    <p style={{margin:0}}>Hash : </p>
                                    <p style={{margin:0, fontWeight:"bold"}}>{this.state.fileInfo && this.state.fileInfo.infos.hashFile.slice(0,32)}..</p>
                                    <p style={{margin:0, fontWeight:"bold"}}>..{this.state.fileInfo && this.state.fileInfo.infos.hashFile.slice(32)}</p>
                                    <p style={{margin:0}}>Date : <span style={{fontWeight:"bold"}}>{this.state.fileInfo && new Date (parseInt(this.state.fileInfo.infos.idToken.substring(0,13))).toLocaleString()}</span></p>

                                </div>
                                <div className={"flexCenter"}>
                                    <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
                                        borderColor:this.state.fileInfo.infos.archived === true ? "#219400":"#AB1212FF",
                                        borderRadius: 40, color:"#FFFFFF", margin:10}}>
                                        <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                                            borderColor:this.state.fileInfo.infos.archived === true ? "#2ECE00":"#FF2222FF",
                                            borderRadius: 40, color:"#FFFFFF"}}>
                                            <div className={"flexCenter"}>
                                                <Save style={{color:"#FFFFFF",width:18,marginTop:0}} />
                                                <div style={{position:"relative", display:this.state.fileInfo.infos.archived === true ? "none":"block"}}>
                                                    <div style={{top:-2,left:-22,minWidth:22,minHeight:2,backgroundColor:"white",border:"0.5px solid #FF2222FF", transform:"rotate(45deg)",display:"block", position:"absolute"}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Cette icône indique que votre fichier {this.state.fileInfo.infos.archived === true ? "est stocké dans nos serveurs et utilise "+(this.state.fileInfo.infos.size/1000000).toFixed(3)+"MB d'espace sur les serveurs.":"n'est pas stocké dans nos serveurs."}</p>
                                </div>
                                <div className={"flexCenter"}>
                                    <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
                                        borderRightColor:this.state.fileInfo.infos.signed === true ? "#219400":this.state.fileInfo.infos.signed ==="beingSigned" ? "#C18A00":this.state.fileInfo.infos.signed ==="intermediate" ?"#219400":"#336480",
                                        borderBottomColor:this.state.fileInfo.infos.signed === true ? "#219400":this.state.fileInfo.infos.signed ==="beingSigned" ? "#C18A00":this.state.fileInfo.infos.signed ==="intermediate" ?"#219400":"#336480",
                                        borderLeftColor:this.state.fileInfo.infos.signed === true ? "#219400":this.state.fileInfo.infos.signed ==="beingSigned" ? "#C18A00":this.state.fileInfo.infos.signed ==="intermediate" ?"#c18a00":"#336480",
                                        borderTopColor:this.state.fileInfo.infos.signed === true ? "#219400":this.state.fileInfo.infos.signed ==="beingSigned" ? "#C18A00":this.state.fileInfo.infos.signed ==="intermediate" ?"#c18a00":"#336480",
                                        borderRadius: 40, color:"#FFFFFF", margin:10}}>
                                        <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                                            borderRightColor:this.state.fileInfo.infos.signed === true ? "#2ECE00":this.state.fileInfo.infos.signed ==="beingSigned" ? "#FFB600":this.state.fileInfo.infos.signed ==="intermediate"?"#2ece00":"#52a8d9",
                                            borderBottomColor:this.state.fileInfo.infos.signed === true ? "#2ECE00":this.state.fileInfo.infos.signed ==="beingSigned" ? "#FFB600":this.state.fileInfo.infos.signed ==="intermediate"?"#2ece00":"#52a8d9",
                                            borderLeftColor:this.state.fileInfo.infos.signed === true ? "#2ECE00":this.state.fileInfo.infos.signed ==="beingSigned" ? "#FFB600":this.state.fileInfo.infos.signed ==="intermediate"?"#ffb600":"#52a8d9",
                                            borderTopColor:this.state.fileInfo.infos.signed === true ? "#2ECE00":this.state.fileInfo.infos.signed ==="beingSigned" ? "#FFB600":this.state.fileInfo.infos.signed ==="intermediate"?"#ffb600":"#52a8d9",
                                            borderRadius: 40, color:"#FFFFFF"}}>
                                            <p style={{fontSize:12,margin:0,marginLeft:5,marginRight:5}} ><SecurityIcon style={{color:"#FFFFFF",width:18,marginTop:4}} /></p>
                                        </div>
                                    </div>
                                    <p>Cette icône indique que ce fichier {this.state.fileInfo.infos.signed === true ? "a été signé par plusieurs utilisateurs":this.state.fileInfo.infos.signed ==="beingSigned" ? "est en attente de signature par un ou plusieurs utilisateurs":this.state.fileInfo.infos.signed ==="intermediate"?"a été signé par un ou plusieurs utilisateurs mais est encore en attente de signature pour d'autres.":"n'a été signé que par le déposant."}</p>
                                </div>
                            </>
                        )}
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayDialogUploadArchivage}
                    onClose={()=>{this.setState({displayDialogUploadArchivage: false,cancelReSend:true,acceptedFileArchive:null})}}
                    id={"dialogArchive"}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:"calc(100% - 110px)",width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                >
                    <div style={{position:"absolute",width:"100%", textAlign:"right"}}>
                        <Close onClick={()=>{this.setState({displayDialogUploadArchivage: false,cancelReSend:true,acceptedFileArchive:null})}} style={{margin:5,cursor:"pointer"}}/>
                    </div>
                    <DialogTitle>
                        Stocker ce fichier sur les serveurs :
                    </DialogTitle>
                    <DialogContent className={"flexCenter"} style={{flexDirection:"column"}}>
                        <Dropzone disabled={this.state.reUploadingFile || this.state.resultatReSend !==""} multiple={false} onDrop={(acceptedFiles) => {
                            Toast.fire({
                                icon: 'success',
                                target:"#dialogArchive",
                                title: "Fichier correctement déposé, prêt à l'enregistrement."
                            })
                            this.setState({acceptedFileArchive:acceptedFiles[0]})
                        }}>
                            {({getRootProps, getInputProps}) => (
                                <section style={{textAlign:"center"}}>
                                    <div {...getRootProps()} id={this.state.acceptedFileArchive ? "nohover":"hoverMoving"} style={{padding:"10vw",borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold", cursor:this.state.acceptedFileArchive ? "default":"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                        <input {...getInputProps()} />
                                        <>
                                            {this.state.acceptedFileArchive ? (
                                                <>
                                                    {this.state.reUploadingFile ? (
                                                        <>
                                                            <h3 style={{textAlign:"center", margin:0}}>Stockage en cours... {this.state.progressReUploadArchive}%</h3>
                                                            <h3 style={{textAlign:"center", margin:0}}>Vitesse d'upload : {this.state.packedDebitReUploadArchive.toFixed(2)}MB/s</h3>
                                                            <h3 style={{textAlign:"center", margin:0}}>Temps restant : {this.state.estimatedTimeReUploadArchive>60 ? (parseInt(this.state.estimatedTimeReUploadArchive/60)+"m"):this.state.estimatedTimeReUploadArchive+"s"}</h3>
                                                            {this.state.progressReUploadArchive === 100 && (
                                                                <h4 className={"loaderTextKS"} style={{textAlign:"center", margin:0}}>Vérifications en cours, ne quittez pas</h4>
                                                            )}
                                                        </>
                                                    ):(
                                                        <>
                                                            {this.state.resultatReSend === "" && <h3 style={{textAlign:"center", margin:0}}>Fichier correctement déposé.</h3>}
                                                        </>
                                                    )}
                                                </>
                                            ):(
                                                <h3 style={{textAlign:"center", margin:0}}>Déposez le fichier à stocker ici.</h3>
                                            )}
                                        </>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        {this.state.resultatReSend === "" ? (
                            <Button disabled={!this.state.acceptedFileArchive || this.state.reUploadingFile} style={{backgroundColor:!this.state.acceptedFileArchive || this.state.reUploadingFile ? "white":"#52A8D9FF",color:!this.state.acceptedFileArchive || this.state.reUploadingFile ? "lightgrey":"white", margin:5, marginTop:15,height:47}} variant={"contained"} onClick={()=>{this.reSendArchive()}} >Stocker le document</Button>
                        ):(
                            <Button style={{backgroundColor:"#52A8D9FF",color:"white", margin:5, marginTop:15,height:47}} variant={"contained"} onClick={()=>{this.setState({displayDialogUploadArchivage:false,resultatReSend:"",fileToResend:null,acceptedFileArchive:null})}} >Retour</Button>
                        )}
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openSignatureDialog}
                    onClose={()=>{this.setState({openSignatureDialog: false,messageNewSignature:"",listSignataire:[],searchWithG:false,searchWithC:false,searchWithCSA:false,dialogDemandeSignaturePhase:1})}}
                    id={"dialogDisplaySignature"}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                >
                    <div style={{position:"absolute",width:"100%", textAlign:"right"}}>
                        <Close onClick={()=>{this.setState({openSignatureDialog: false,messageNewSignature:"",listSignataire:[],searchWithG:false,searchWithC:false,searchWithCSA:false,dialogDemandeSignaturePhase:1})}} style={{margin:5,cursor:"pointer"}}/>
                    </div>
                    <DialogTitle>Demande de signature à :</DialogTitle>
                    <DialogContent className={"flexCenter"} style={{flexDirection:"column",overflow:"hidden"}}>
                        {this.state.dialogDemandeSignaturePhase === 1 ? (
                            <>
                                <div className={"flexCenter"} style={{width:"100%"}}>
                                    <div className={"flexCenter"} style={{width:"100%",flexDirection:"column"}}>
                                        <Autocomplete
                                            disablePortal
                                            noOptionsText={'Aucun résultat'}
                                            ListboxProps={{ style: { maxHeight: 100 } }}
                                            options={this.state.allClientsDisplayed}
                                            sx={{ width: "100%", marginBottom:0 }}
                                            value={this.state.clientChosenForSignataire}
                                            onChange={(e, newval, reason) => {
                                                this.setState({clientChosenForSignataire:newval})
                                            }}
                                            filterOptions={filterOptions}
                                            renderInput={(params) => (
                                                <div className={"flexCenter"}>
                                                    <TextField variant={"outlined"} style={{marginBottom:0,width:"80%"}} {...params} label="Rechercher un utilisateur..." />
                                                    <Tooltip arrow={true} title={"Ecrivez dans ce champs de texte l'identifiant de la personne à qui vous voulez demander une signature"}>
                                                        <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                                    </Tooltip>
                                                </div>
                                            )
                                            }
                                        />
                                        <div style={{fontSize:12,marginBottom:0,marginTop:5,display:"flex",flexWrap:"wrap",width:"80%"}}>
                                            <p style={{margin:0,marginRight:3}}>Recherche parmi : </p>
                                            {((!this.state.searchWithG && !this.state.searchWithC && !this.state.searchWithCSA )|| (this.state.searchWithG && this.state.searchWithC && this.state.searchWithCSA)) ? <p style={{margin:0,fontWeight:"bold"}}>Tous les utilisateurs</p> :(
                                                <>
                                                    <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{this.state.searchWithG && " Gestionnaires"}</p>
                                                    <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{(this.state.searchWithG && this.state.searchWithC) ? " - Clients" : this.state.searchWithC && " Clients"}</p>
                                                    <p style={{margin:0,fontWeight:"bold"}}>{((this.state.searchWithG || this.state.searchWithC) && this.state.searchWithCSA) ? " - Clients sans abonnements" : this.state.searchWithCSA && " Clients sans abonnements"}</p>
                                                </>
                                            )}
                                        </div>
                                        <div style={{width:"100%", display:"flex", justifyContent:"flex-start",alignItems:"center",flexWrap:"wrap"}}>
                                            <Button onClick={()=>{this.setState({searchWithG:!this.state.searchWithG},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithG ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 0" }}>Gestionnaires</Button>
                                            <Button onClick={()=>{this.setState({searchWithC:!this.state.searchWithC},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithC ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 2px" }}>Clients</Button>
                                            <Button onClick={()=>{this.setState({searchWithCSA:!this.state.searchWithCSA},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithCSA ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 0 10px 2px"}}>Clients sans abonnement</Button>
                                        </div>

                                    </div>
                                    <Button disabled={this.state.clientChosenForSignataire === null} style={{backgroundColor:this.state.clientChosenForSignataire === null ? "white":"#52A8D9FF",color:this.state.clientChosenForSignataire === null ? "lightgrey":"white", margin:5,height:47}} variant={"contained"} onClick={()=>{this.AddClientToSignataire(this.state.clientChosenForSignataire)}} >Ajouter</Button>
                                </div>
                                <hr style={{width:"100%",marginTop:0}}/>
                                <div style={{maxHeight:200,overflowY:"auto"}}>
                                    <h3 style={{textAlign:"center",fontWeigth:"normal"}}>Liste des signataires :</h3>
                                    <TableContainer>
                                        <Table stickyHeader>
                                            <TableBody>
                                                {this.state.listSignataire.length === 0 && (
                                                    <p style={{textAlign:"center"}}>Aucun signataire...</p>
                                                )}
                                                {this.state.listSignataire.map(elt=>(
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={elt.pseudo}>
                                                        {columnsClientShare.map((column) => {
                                                            return (
                                                                <>
                                                                    {column.id === "Action" ?
                                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                            <Tooltip arrow={true} title={"Retirer cet utilisateur des signataires."}>
                                                                                <Button onClick={()=>{this.RemoveClientToSignataire(elt.pseudo)}}>
                                                                                    <Close/>
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </TableCell>

                                                                        :
                                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                            {elt[column.id]}
                                                                        </TableCell>
                                                                    }
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <TextField style={{marginBottom: 15,marginTop:15,width:this.state.showBrowserView?400:"80vw"}} multiline={true} rowsMax={6} name={"messageNewSignature"} value={this.state.messageNewSignature} onChange={(e)=>{this.handleChange(e)}} label="Message pour le signataire (optionnel)" variant={"outlined"} />

                                <div className={"flexCenter"}>
                                    {this.state.selectedFiles[0] && this.state.selectedFiles[0].infos && this.state.selectedFiles[0].infos.archived ? (
                                        <Button disabled={this.state.listSignataire.length === 0} style={{ fontSize:14,backgroundColor:this.state.listSignataire.length ===0?"lightGrey":"#52A8D9FF",color:this.state.listSignataire.length ===0 ? "white":"white",margin:10 }} variant={"outlined"} onClick={()=>{this.sendNewDemandeSignature()}}>Envoyer</Button>
                                    ):(
                                        <Button disabled={this.state.listSignataire.length === 0} style={{ fontSize:14,backgroundColor:this.state.listSignataire.length ===0?"lightGrey":"#52A8D9FF",color:this.state.listSignataire.length ===0 ? "white":"white",margin:10 }} variant={"outlined"} onClick={()=>{this.setState({dialogDemandeSignaturePhase:2})}}>Suivant</Button>
                                    )}
                                    <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({openSignatureDialog:false})}}>Retour</Button>

                                </div>
                            </>
                        ):(
                            <>
                                <Dropzone disabled={this.state.reUploadingSignatureFile || this.state.resultatSignatureReSend !==""} multiple={false} onDrop={(acceptedFiles) => {
                                    Toast.fire({
                                        icon: 'success',
                                        target:"#dialogDisplaySignature",
                                        title: "Fichier correctement déposé, prêt à la demande de signature."
                                    })
                                    this.setState({acceptedFileSignature:acceptedFiles[0]})
                                }}>
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{textAlign:"center"}}>
                                            <div {...getRootProps()} id={this.state.acceptedFileSignature ? "nohover":"hoverMoving"} style={{padding:"10vw",borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold", cursor:this.state.acceptedFileSignature ? "default":"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                <input {...getInputProps()} />
                                                <>
                                                    {this.state.acceptedFileSignature ? (
                                                        <>
                                                            {this.state.reUploadingSignatureFile ? (
                                                                // <h3 style={{textAlign:"center", margin:0}}>Demande en cours... {this.state.progressReUploadSignature}%</h3>
                                                                <>
                                                                    <h3 style={{textAlign:"center", margin:0}}>Demande en cours... {this.state.progressReUploadSignature}%</h3>
                                                                    <h3 style={{textAlign:"center", margin:0}}>Vitesse d'upload : {this.state.packetDebitReUploadSignature.toFixed(2)}MB/s</h3>
                                                                    <h3 style={{textAlign:"center", margin:0}}>Temps restant : {this.state.estimatedTimeReUploadSignature>60 ? (parseInt(this.state.estimatedTimeReUploadSignature/60)+"m"):this.state.estimatedTimeReUploadSignature+"s"}</h3>
                                                                    {this.state.progressReUploadSignature === 100 && (
                                                                        <h4 className={"loaderTextKS"} style={{textAlign:"center", margin:0}}>Vérifications en cours, ne quittez pas</h4>
                                                                    )}
                                                                </>

                                                            ):(
                                                                <>
                                                                    {this.state.resultatSignatureReSend === "" && <h3 style={{textAlign:"center", margin:0}}>Fichier correctement déposé.</h3>}
                                                                </>
                                                            )}
                                                        </>
                                                    ):(
                                                        <h3 style={{textAlign:"center", margin:0}}>Déposez le fichier à signer ici.</h3>
                                                    )}
                                                </>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <div  className={"flexCenter"}>
                                    <Button disabled={this.state.listSignataire.length === 0 || !this.state.acceptedFileSignature || this.state.reUploadingSignatureFile} style={{ fontSize:14,backgroundColor:(this.state.listSignataire.length === 0 || !this.state.acceptedFileSignature || this.state.reUploadingSignatureFile) ? "lightGrey":"#52A8D9FF",color:"#FFF",margin:10 }} variant={"outlined"} onClick={()=>{this.sendFileForSignature()}}>Envoyer</Button>
                                    <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({resultatSignatureReSend:"",progressReUploadSignature:0,estimatedTimeReUploadSignature:0,acceptedFileSignature:null,dialogDemandeSignaturePhase:1,cancelReSend:true})}}>Retour</Button>
                                </div>
                            </>
                        )}

                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openGestionSignatureDialog}
                    onClose={()=>{this.setState({openGestionSignatureDialog: false,})}}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                    id={"openGestionSignatureDialog"}
                >
                    <DialogContent style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center",overflow:"hidden"}}>
                        <div style={{maxHeight:500,overflowY:"auto"}}>
                            <h3 style={{textAlign:"center",fontWeigth:"normal"}}>Gestion des demandes de signature :</h3>
                            <TableContainer style={{maxHeight:450}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columnsListeSignature.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.listAllSignatureEnCours.length === 0 && (
                                            <p>Aucune demande de signature en cours ...</p>
                                        )}
                                        {this.state.listAllSignatureEnCours.map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columnsListeSignature.map((column) => {
                                                        return (
                                                            <TableCell key={column.id}>
                                                                {column.id === "Action" ? (
                                                                    <Tooltip arrow={true} title={"Annuler la demande de signature."}>
                                                                        <Button onClick={()=>{this.cancelThisValidation(row)}} name={row.pseudo}> <CloseIcon/> </Button>
                                                                    </Tooltip>
                                                                ):(
                                                                    row[column.id]
                                                                )}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayDialogUploadFile}
                    onClose={()=>{this.setState({displayDialogUploadFile: false})}}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                >
                    <DialogTitle>Enregistrement d'un document sur la blockchain :</DialogTitle>
                    <DialogContent style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
                        <p  className={"flexCenter"}>Vous allez enregistrer un document dans le dossier : <FolderIcon style={{marginLeft:5}}/>{this.state.breadcrumbsDisplay[this.state.breadcrumbsDisplay.length-1]}</p>

                        <div  className={"flexCenter"}>
                            <div onClick={()=>{this.uploadFileInFolder(false)}} id={"hoverMoving"} className={"hoverBlueCase"} style={{padding:10,margin:5,borderRadius:5,border:"1px solid black",cursor:"pointer"}}>
                                <p>Avec <span style={{fontWeight:"bold"}}>votre</span> signature</p>
                            </div>
                            <div onClick={()=>{this.uploadFileInFolder(true)}} id={"hoverMoving"} className={"hoverBlueCase"} style={{padding:10,margin:5,borderRadius:5,border:"1px solid black",cursor:"pointer"}}>
                                <p>Avec signature d'un <span style={{fontWeight:"bold"}}>tiers</span></p>
                            </div>

                        </div>
                        <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({displayDialogUploadFile:false})}}>Retour</Button>

                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayDialogSendProof}
                    onClose={()=>{this.setState({displayDialogSendProof: false,listRecepteurPreuveViaMail:[],phasePreuveViaMail:1})}}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                    id={"dialogAddPreuveViaMail"}
                >
                    <div style={{position:"relative", width:"100%"}}>
                        <Close onClick={()=>{this.setState({displayDialogSendProof: false,listRecepteurPreuveViaMail:[],phasePreuveViaMail:1})}} style={{position:"absolute",right:0,cursor:"pointer"}} />
                    </div>
                    <DialogTitle>Envoyer le(s) fichier(s) par mail : </DialogTitle>
                    <DialogContent style={{display:this.state.showBrowserView&&"flex",flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
                        <form style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center", marginTop:10}}>
                            {this.state.phasePreuveViaMail === 1 ? (
                                <>
                                    <TextField name={"recepteurProofName"} style={{marginBottom: 15,width:this.state.showBrowserView&&450}} value={this.state.recepteurProofName} onChange={(e)=>{this.handleChange(e)}} required={true} label="Nom du récepteur" variant="outlined" />
                                    <TextField name={"recepteurProofEmail"} style={{marginBottom: 15,width:this.state.showBrowserView&&450}} value={this.state.recepteurProofEmail} onChange={(e)=>{this.handleChange(e)}} required={true} label="Email du récepteur" variant="outlined" />
                                    <Button disabled={this.state.recepteurProofName === "" || this.state.recepteurProofEmail === ""} style={{backgroundColor:(this.state.recepteurProofName === "" || this.state.recepteurProofEmail === "" ? "#c7c7c7":"#52a8d9"),color:"white", margin:5}} variant={"contained"} onClick={()=>{this.addRecepteurPreuveviaMail()}}>Ajouter ce récepteur</Button>
                                    <h4 style={{marginTop:0}}>Liste des récepteurs :</h4>
                                    <TableContainer style={{maxHeight:200,overflowY:"auto"}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columnsListePreuveViaMail.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.listRecepteurPreuveViaMail.length === 0 && (
                                                    <p>Veuillez ajouter un récepteur ...</p>
                                                )}
                                                {this.state.listRecepteurPreuveViaMail.map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            {columnsListePreuveViaMail.map((column) => {
                                                                return (
                                                                    <TableCell key={column.id}>
                                                                        {column.id === "Action" ? (
                                                                            <Tooltip arrow={true} title={"Retirer ce récepteur."}>
                                                                                <Button onClick={()=>{this.removeRecepteurPreuveviaMail(row.recepteurName)}} name={row.recepteurName}> <CloseIcon style={{color:"#000"}}/> </Button>
                                                                            </Tooltip>
                                                                        ):(
                                                                            row[column.id]
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div  className={"flexCenter"}>
                                        <Button disabled={this.state.listRecepteurPreuveViaMail.length === 0} style={{backgroundColor:"#52a8d9",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({phasePreuveViaMail:2})}}>Suivant</Button>
                                        <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({displayDialogSendProof: false})}}>Retour</Button>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className={"flexCenter"} >
                                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                            <h4 style={{marginTop:0}}>Pièces jointes (facultatif) :</h4>
                                            <Dropzone multiple={true} onDrop={(acceptedFiles) => {
                                                let size = 0;
                                                let arrayBufferPiecesJointes = this.state.arrayBufferPiecesJointes.slice()
                                                acceptedFiles.forEach((file,index) => {
                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        const binaryStr = reader.result
                                                        arrayBufferPiecesJointes.push({name:file.name,buffer:Buffer.from(binaryStr, "base64")})
                                                        if(acceptedFiles.length-1 === index){
                                                            this.setState({arrayBufferPiecesJointes:arrayBufferPiecesJointes},()=>{
                                                                arrayBufferPiecesJointes.forEach(elt=>{
                                                                    size+=elt.buffer.length
                                                                })
                                                                this.setState({totalSizePiecesJointes:size})
                                                            })
                                                        }
                                                    }
                                                    reader.readAsArrayBuffer(file)

                                                })
                                            }}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()} id={this.state.uploadingFile ? "nohover":"hoverMoving"} style={{padding:30,borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",marginBottom:5, color:"black", cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                            <input {...getInputProps()} />
                                                            <p>Déposez vos pièces jointes ici.</p>
                                                            <p>(factures / documents annexes)</p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    <div style={{width:this.state.showBrowserView&&450}}>
                                        {this.state.arrayBufferPiecesJointes.map(elt=>(
                                            <p className={"flexCenter"} style={{margin:3}}>{elt.name} <Close style={{cursor:"pointer"}} onClick={()=>{
                                                let arrayBufferPiecesJointes = this.state.arrayBufferPiecesJointes.slice()
                                                arrayBufferPiecesJointes.splice(arrayBufferPiecesJointes.findIndex(file => file.name === elt.name),1)
                                                this.setState({arrayBufferPiecesJointes:arrayBufferPiecesJointes})
                                            }}/></p>
                                        ))}
                                    </div>
                                    {this.state.onlyClient === false && (
                                        <p style={{marginTop:0}}>Envoyer le pdf de publicité de Kagesecur<Checkbox
                                            checked={this.state.sendPub}
                                            onChange={()=>{this.setState({sendPub:!this.state.sendPub})}}
                                            style ={{color: "#52A8D9FF"}}
                                        /></p>
                                    )}
                                    <div  className={"flexCenter"}>
                                        <Button style={{backgroundColor:"#52a8d9",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({displayDialogSendProof:false});this.validateProofViaMail()}}>Suivant</Button>
                                        <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({phasePreuveViaMail: 1})}}>Retour</Button>
                                    </div>
                                </>
                            )}
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayShareFolder}
                    onClose={()=>{this.setState({displayShareFolder: false,fileClipboardToShare:{},listSignataire:[],searchWithG:false,searchWithC:false,searchWithCSA:false})}}
                    id={"dialogDisplayShare"}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                >
                    <DialogTitle>
                        <div style={{position:"relative", width:"100%"}}>
                            <Close onClick={()=>{this.setState({displayShareFolder: false,fileClipboardToShare:{},listSignataire:[],searchWithG:false,searchWithC:false,searchWithCSA:false})}} style={{position:"absolute",right:0,cursor:"pointer"}} />
                        </div>
                        Gestion du partage du dossier :
                    </DialogTitle>
                    <DialogContent style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center",overflow:"hidden"}}>

                        <Paper style={{margin:10,padding:10, paddingTop:0,width:"100%"}}>

                            <div className={"flexCenter"} style={{width:"100%"}}>
                                <div className={"flexCenter"} style={{width:"100%",flexDirection:"column"}}>
                                    <Autocomplete
                                        disablePortal
                                        noOptionsText={'Aucun résultat'}
                                        ListboxProps={{ style: { maxHeight: 100 } }}
                                        options={this.state.allClientsDisplayed}
                                        value={this.state.clientChosenForShare}
                                        sx={{ width: "100%", marginBottom:0 }}
                                        onChange={(e, newval, reason) => {
                                            this.setState({clientChosenForShare:newval})
                                        }}
                                        filterOptions={filterOptions}
                                        renderInput={(params) =>
                                            <div  className={"flexCenter"}>
                                                <TextField variant={"outlined"} style={{marginBottom:0,width:"80%"}} {...params} label="Rechercher un utilisateur..." />
                                                <Tooltip arrow={true} title={"Ecrivez dans ce champs de texte l'identifiant de la personne à qui vous voulez partager le dossier"}>
                                                    <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                                </Tooltip>
                                            </div>
                                        }
                                    />
                                    <div style={{fontSize:12,marginBottom:0,marginTop:5,display:"flex",flexWrap:"wrap",width:"80%"}}>
                                        <p style={{margin:0,marginRight:3}}>Recherche parmi : </p>
                                        {((!this.state.searchWithG && !this.state.searchWithC && !this.state.searchWithCSA )|| (this.state.searchWithG && this.state.searchWithC && this.state.searchWithCSA)) ? <p style={{margin:0,fontWeight:"bold"}}>Tous les utilisateurs</p> :(
                                            <>
                                                <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{this.state.searchWithG && " Gestionnaires"}</p>
                                                <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{(this.state.searchWithG && this.state.searchWithC) ? " - Clients" : this.state.searchWithC && " Clients"}</p>
                                                <p style={{margin:0,fontWeight:"bold"}}>{((this.state.searchWithG || this.state.searchWithC) && this.state.searchWithCSA) ? " - Clients sans abonnements" : this.state.searchWithCSA && " Clients sans abonnements"}</p>
                                            </>
                                        )}
                                    </div>
                                    <div style={{width:"100%", display:"flex", justifyContent:"flex-start",alignItems:"center",flexWrap:"wrap"}}>
                                        <Button onClick={()=>{this.setState({searchWithG:!this.state.searchWithG},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithG ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 0" }}>Gestionnaires</Button>
                                        <Button onClick={()=>{this.setState({searchWithC:!this.state.searchWithC},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithC ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 2px" }}>Clients</Button>
                                        <Button onClick={()=>{this.setState({searchWithCSA:!this.state.searchWithCSA},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithCSA ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 0 10px 2px"}}>Clients sans abonnement</Button>
                                    </div>
                                </div>

                                {/*<Button disabled={this.state.clientChosenForShare === null} style={{backgroundColor:this.state.clientChosenForShare === null ? "#c7c7c7":"#52a8d9", margin:5,height:47, color:"#FFFFFF"}} variant={"contained"} onClick={()=>{this.ShareFolderWithClient()}} >Ajouter</Button>*/}
                                <Button disabled={this.state.clientChosenForShare === null} style={{backgroundColor:this.state.clientChosenForShare === null ? "#c7c7c7":"#52a8d9", margin:5,height:47, color:"#FFFFFF"}} variant={"contained"} onClick={()=>{this.AddClientToShare(this.state.clientChosenForShare)}} >Ajouter</Button>
                            </div>
                            {this.state.listShare.length>0 && (
                                <div>
                                    <div className={"flexCenter"} style={{maxHeight:200,overflowY:"auto", flexDirection:"column"}}>
                                        <h3 style={{textAlign:"center",fontWeigth:"normal"}}>Liste des clients à ajouter :</h3>
                                        <TableContainer className={"MuiTableContainerOverflow"}>
                                            <Table stickyHeader>
                                                <TableBody>
                                                    {this.state.listShare.length>0 && (
                                                        <>
                                                            {(this.state.listShare).map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                        {columnsClientShare.map((column) => {
                                                                            return (
                                                                                <>
                                                                                    {column.id === "Action" ?
                                                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                                            <Tooltip arrow={true} title={"Retirer cet utilisateur du partage de dossier."}>
                                                                                                <Button onClick={()=>{this.RemoveClientToShare(row)}}>
                                                                                                    <Close/>
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                        </TableCell>

                                                                                        :
                                                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                                            {row[column.id]}
                                                                                        </TableCell>
                                                                                    }
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <div style={{width:"100%", display:"flex",alignItems:"center", justifyContent:"center"}}>
                                        <Checkbox
                                            checked={this.state.anonyme}
                                            onChange={()=>{this.setState({anonyme:!(this.state.anonyme)})}}
                                            style ={{color: "#52A8D9FF", margin:0}}
                                        />
                                        <p style={{margin:0}}>Anonymiser les documents pour le client </p>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%"}}>
                                        <Button disabled={this.state.listShare.length === 0} style={{backgroundColor:this.state.listShare.length === 0 ? "#c7c7c7":"#52a8d9", margin:5,height:47, color:"#FFFFFF"}} variant={"contained"} onClick={()=>{this.ShareFolderWithClient()}} >Confirmer le partage</Button>
                                    </div>
                                </div>
                            )}
                            <hr style={{width:"100%",marginTop:10}}/>
                            <div style={{maxHeight:200,overflowY:"auto", display:'flex',alignItems:'center',justifyContent:'center', flexDirection:"column"}}>
                                <h3 style={{textAlign:"center",fontWeigth:"normal"}}>Liste des utilisateurs ayant accès à ce dossier :</h3>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableBody>
                                            {Object.keys(this.state.fileClipboardToShare).length>0 && (
                                                <>
                                                    {this.state.fileClipboardToShare.shared === false ?(
                                                        <>
                                                            <p>Ce dossier n'est partagé avec aucun utilisateur</p>
                                                        </>
                                                    ):(
                                                        <>
                                                            {(this.state.fileClipboardToShare.shared).map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                        {columnsClientShare.map((column) => {
                                                                            return (
                                                                                <>
                                                                                    {column.id === "Action" ?
                                                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                                            <Tooltip arrow={true} title={"Retirer cet utilisateur du partage de dossier."}>
                                                                                                <Button onClick={()=>{this.UnshareFolderWithClient(row.pseudo)}}>
                                                                                                    <Close/>
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                        </TableCell>

                                                                                        :
                                                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                                            <div className={"flexCenter"}>
                                                                                                {row[column.id]}
                                                                                                {row.anonyme && (
                                                                                                    <Tooltip arrow={true} title={"Cet utilisateur voit le dossier de manière anonymisée."}>
                                                                                                        <VisibilityOff style={{marginLeft:5}}/>
                                                                                                    </Tooltip>
                                                                                                )}

                                                                                            </div>


                                                                                        </TableCell>
                                                                                    }
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    </DialogContent>
                </Dialog>
                <div className={"containerFileManager"} style={{height:"80%", width:"100%",backgroundColor:"white",display:"flex",flexDirection:"column",borderRadius:8,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                    <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between", alignItems:"center"}}>
                        {this.state.showBrowserView && (
                            <>
                                {this.state.rechercheDisplay !== "" ? (
                                    <Breadcrumbs maxItems={4} style={{margin:0,marginLeft:15}}>
                                        <p onClick={()=>{this.clearFilterAndGoRoot()}} style={{cursor:"pointer", fontWeight:"bold"}}>...</p>
                                        <p style={{cursor:"pointer", fontWeight:"bold"}}>recherche:{this.state.rechercheDisplay}</p>
                                    </Breadcrumbs>
                                ):(
                                    <Breadcrumbs maxItems={4} style={{margin:0,marginLeft:15}}>
                                        <p onClick={()=>{this.clearFilterAndGoRoot()}} style={{cursor:"pointer", fontWeight:"bold"}}>...</p>
                                        {this.state.breadcrumbsDisplay.map((elt,index)=>(
                                            <div key={"breadcrumsID_"+index}>
                                                {this.state.breadcrumbsDisplay.indexOf(elt) === this.state.breadcrumbsDisplay.length-1 ? (
                                                    <p onClick={()=>{this.selectBreadcrumbs(elt,this.state.archiFiles.slice(), this.state.breadcrumbsDisplay.slice())}} className={"noselect"} style={{cursor:"pointer", color:"black"}}>{elt.length>64 ? elt.slice(0,7) + "..." + elt.slice(-5):elt.replace("#shared#","")}</p>
                                                ):(
                                                    <p onClick={()=>{this.selectBreadcrumbs(elt,this.state.archiFiles.slice(), this.state.breadcrumbsDisplay.slice())}} className={"noselect"} style={{cursor:"pointer", color:"grey"}}>{elt.length>64 ? elt.slice(0,7) + "..." + elt.slice(-5):elt.replace("#shared#","")}</p>
                                                )}
                                            </div>
                                        ))}
                                    </Breadcrumbs>
                                )}
                            </>
                        )}
                        <div className={"flexCenter"} style={{marginRight:this.state.showBrowserView && 30}} >
                            <div className={"flexCenter"} style={{margin:5,marginRight:this.state.showBrowserView && 40}}>
                                <div>
                                    {this.state.showBrowserView && (
                                        <IconButton onClick={()=>{
                                            this.setState({showNotifDiv:!this.state.showNotifDiv,notReadNotif:0},()=>{
                                                if(this.state.showNotifDiv){
                                                    sessionStorage.setItem("KAGESECUR_notifTS",Date.now())
                                                }
                                            })
                                        }} id={"buttonNotif"} style={{color:"black", marginRight:25}}>
                                            <div style={{display:"flex"}}>
                                                <Notifications/>
                                                {this.state.notReadNotif > 0 && (
                                                    <div className={"flexCenter"} style={{backgroundColor:"red",height: 13,width: 13,borderRadius:"50%",position:"absolute", left:"calc( 100% - 16px )"}}>
                                                        <p style={{fontSize:10,color:"white"}}>{this.state.notReadNotif}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </IconButton>
                                    )}
                                    {this.state.showNotifDiv && (
                                        <div style={{backgroundColor:"white",position:"absolute",boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",borderRadius:10,maxWidth:500,padding:15,maxHeight:350,overflow:"auto",zIndex:999}}>
                                            <div style={{position:"relative"}}>
                                                <Close onClick={()=>{this.setState({showNotifDiv:false,notReadNotif:0})}} style={{position:"absolute", top:-15, left:-15, cursor:"pointer"}}/>
                                            </div>
                                            <TableContainer >
                                                <Table stickyHeader>
                                                    <TableBody >
                                                        {this.state.notifications.length === 0 && (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={"noNotifs"}>
                                                                <TableCell key={"noNotifs"}>
                                                                    Vous n'avez aucune nouvelle notification.
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                        {(this.state.notifications).map((row) => {
                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.date}>
                                                                    <TableCell style={{color:row.ts < this.state.lastDisplayOfNotif ? "#bfbfbf":"black"}} key={row.date}>
                                                                        {row.type === "masterReport" ? (
                                                                            <>
                                                                                Vous avez un ou plusieurs fichiers en attente concernant Master Report.
                                                                                <span style={{fontWeight:"bold", cursor:"pointer"}} onClick={()=> {this.props.navigate("/KageSecur/ListeZoneTemporaire")}}>
                                                                                   Cliquez ici pour les consulter.
                                                                                </span>
                                                                            </>
                                                                        ):(
                                                                            <>
                                                                                {row.type === "signature" ? (
                                                                                    <>
                                                                                        La demande de signature du fichier "<span style={{fontWeight:"bold"}}>{row.fileName}</span>" a été traitée par <span style={{fontWeight:"bold"}}>{row.pseudoSignataire}</span> le <span style={{fontWeight:"bold"}}>{row.date}</span>.
                                                                                    </>
                                                                                ):(
                                                                                    <>
                                                                                        Le dossier "<span style={{fontWeight:"bold"}}>{row.path.split("/")[row.path.split("/").length-1]}</span>" vous a été partagé par <span style={{fontWeight:"bold"}}>{row.initiateur}</span> le <span style={{fontWeight:"bold"}}>{row.date}</span>.
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    )}
                                </div>


                                <div className={"flexCenter"}>
                                    <TextField style={{borderRadius:5, width:this.state.showBrowserView ?"20vw":"40vw"}} name={"recherche"} value={this.state.recherche} onChange={(e)=>{this.handleChange(e)}} label="Recherche" variant={"outlined"} />
                                    {this.state.rechercheDisplay !== "" ?
                                        <>
                                            <Button style={{height:"56px"}} type={"submit"} variant={"outlined"} onClick={()=>{this.clearFilterAndGoRoot()}}> {this.state.searchFinished ? <ClearIcon/> : <CircularProgress size={20}/>}   </Button>
                                        </>
                                        :
                                        <>
                                            <Tooltip arrow={true} title={"Attention la recherche peut prendre quelques minutes car l'application (pour des raisons de sécurité) doit déchiffrer les contenus afin de vous les afficher"}>
                                                <Button style={{height:"56px", color:"#000", borderColor:"#ababab"}} type={"submit"} onClick={(e)=>{this.handleClickRecherche(e)}} variant={"outlined"}> <SearchIcon/> </Button>
                                            </Tooltip>
                                        </>
                                    }
                                </div>
                            </div>
                            {this.state.fileSelectedIsFolder ? (
                                <Tooltip arrow={true} title={"Supprimer le dossier"}>
                                    {this.state.disableButtonMenu ? (
                                        <Delete style={{color:"grey"}}/>
                                    ):(
                                        <Delete onClick={()=>{(this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo)||(this.state.gestionnaire)||(this.state.onlyClient && !this.state.breadcrumbsDisplay.includes("sharedFolder"))) && this.deleteFolder()}} style={{margin:3,cursor:this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo)||(this.state.gestionnaire)||(this.state.onlyClient && !this.state.breadcrumbsDisplay.includes("sharedFolder"))  ? "pointer":"default", color:this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo)||(this.state.gestionnaire)||(this.state.onlyClient && !this.state.breadcrumbsDisplay.includes("sharedFolder"))  ? "black":"grey"}}/>
                                    )}
                                </Tooltip>
                            ):(
                                <Tooltip arrow={true} title={"Créer un nouveau dossier"}>
                                    {this.state.disableButtonMenu ? (
                                        <CreateNewFolder style={{color:"grey"}}/>
                                    ):(
                                        <CreateNewFolder onClick={()=>{(this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo)||(this.state.gestionnaire)||(this.state.onlyClient && !this.state.breadcrumbsDisplay.includes("sharedFolder"))) && (this.displayFileCreation())}} style={{margin:3,cursor:(this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo) ||(this.state.gestionnaire)||(this.state.onlyClient && !this.state.breadcrumbsDisplay.includes("sharedFolder"))) ? "pointer":"default", color:(this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo)||(this.state.gestionnaire)||(this.state.onlyClient && !this.state.breadcrumbsDisplay.includes("sharedFolder"))) ? "black":"grey"}}/>
                                    )}
                                </Tooltip>
                            )}
                            {this.state.downloadFinished ? (
                                <Tooltip disableHoverListener={this.state.selectedFiles.length>0 && this.state.selectedFiles[0].type === "sharedFolder"} arrow={true} title={"Cliquez pour afficher le menu"}>
                                    <MenuIcon onClick={(event)=>{(!this.state.disableMenu && ((this.state.onlyClient || this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo))|| this.state.gestionnaire)&& this.handleMenu(event))}} style={{margin:3,cursor:this.state.disableMenu? "default":((this.state.onlyClient ||this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo))|| this.state.gestionnaire)?"pointer":"default", color:this.state.disableMenu? "grey":((this.state.onlyClient ||this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo))|| this.state.gestionnaire)?"black":"grey"}} />
                                </Tooltip>
                            ):(
                                <div  className={"flexCenter"}>
                                    <CircularProgress style={{margin:5}} size={30}/>
                                    <p style={{position:"absolute",fontSize:10}}>{this.state.progressDownloadFile}%</p>
                                </div>
                            )}
                            <Menu
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                anchorEl={this.state.anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                onClose={()=>{this.handleCloseMenu()}}
                            >
                                {/*<MenuItem style={{position:"fixed",display:"flex",justifyContent:"center",margin:0,marginTop:218,width:222,zIndex:1,height:24,backgroundColor:"white"}}> <ArrowDownward style={{width:18}}/></MenuItem>*/}

                                {this.state.selectedFiles.length===0 ? (
                                    <div>
                                        <MenuItem onClick={()=>{this.handleCloseMenu();!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")?
                                            (this.setState({displayDialogUploadFile:true})
                                            ):(
                                                Swal.fire({
                                                    title: 'Erreur!',
                                                    text: "Vous ne pouvez enregistrer un document dans vos dossiers partagés.",
                                                    icon: 'warning',
                                                    confirmButtonText: 'Ok'
                                                })
                                            )}}><CloudUpload style={{marginRight:2}}/>Enregistrer un document dans ce dossier</MenuItem>
                                    </div>
                                ):(
                                    this.state.fileSelectedIsFolder ? (
                                        <div>
                                            {!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés") && !(this.state.selectedFiles[0] && this.state.selectedFiles[0].name.includes("#shared#Dossiers partagés")) ? (
                                                <div>
                                                    <MenuItem disabled={this.state.freeClient} onClick={()=>{
                                                        this.handleCloseMenu();
                                                        this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                                        Toast.fire({
                                                            title: "Naviguez dans le dossier de votre choix (double clic) et cliquez sur 'Déplacer ici'.",
                                                            icon: 'info',
                                                        })
                                                    }}>
                                                        <DriveFolderUpload style={{marginRight:2}}/>Déplacer
                                                    </MenuItem>
                                                    <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient} onClick={()=>{
                                                        this.handleCloseMenu();
                                                        this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name})
                                                    }}>
                                                        <Edit style={{marginRight:2}}/>Renommer
                                                    </MenuItem>
                                                    <MenuItem disabled={this.state.selectedFiles.length!== 1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip" )} onClick={()=>{
                                                        this.handleCloseMenu();
                                                        this.clickDownload(true)
                                                    }}>
                                                        <GetAppIcon style={{marginRight:2}}/>Télécharger (.zip)
                                                    </MenuItem>
                                                </div>
                                            ):(
                                                <div>
                                                    <MenuItem disabled={true}><DriveFolderUpload style={{marginRight:2}}/>Déplacer</MenuItem>
                                                    <MenuItem disabled={true}><Edit style={{marginRight:2}}/>Renommer</MenuItem>
                                                    <MenuItem disabled={this.state.selectedFiles.length!== 1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].name === "#shared#Dossiers partagés" )} onClick={()=>{
                                                        this.handleCloseMenu();
                                                        this.clickDownload(true)
                                                    }}>
                                                        <GetAppIcon style={{marginRight:2}}/>Télécharger (.zip)
                                                    </MenuItem>
                                                </div>
                                            )}
                                            {(!this.state.onlyClient) && (
                                                <div>
                                                    <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés") || (this.state.selectedFiles[0] && this.state.selectedFiles[0].name.includes("#shared#Dossiers partagés"))} onClick={()=>{this.handleCloseMenu();this.state.allClients.length === 0 && this.getAllClients();this.setState({displayShareFolder:true,fileClipboardToShare:this.state.selectedFiles[0]})}}><FolderShared style={{marginRight:2}}/>{(this.state.selectedFiles[0] &&this.state.selectedFiles[0].shared === false) ? "Partager le dossier":"Gérer le partage"}</MenuItem>
                                                    {(this.state.selectedFiles[0] &&this.state.selectedFiles[0].shared !== false) &&(
                                                        <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés") || (this.state.selectedFiles[0] && this.state.selectedFiles[0].name.includes("#shared#Dossiers partagés"))} onClick={()=>{this.handleCloseMenu();this.setState({fileClipboardToShare:this.state.selectedFiles[0]});this.UnshareFolder(this.state.selectedFiles[0]);}}><Close style={{marginRight:2}}/>Arreter le partage</MenuItem>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ):(
                                        <div>
                                            <MenuItem disabled={(this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient ||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{this.handleCloseMenu();this.sendProofMail()}}><Mail style={{marginRight:2}}/>Envoyer le fichier/preuve</MenuItem>
                                            {this.state.selectedFiles[0] && this.state.selectedFiles[0].infos &&( this.state.selectedFiles[0].infos.signed === "beingSigned" || this.state.selectedFiles[0].infos.signed === "intermediate") &&
                                                (
                                                    <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")}
                                                          onClick={()=>{
                                                              this.getSignEnCours()
                                                              this.handleCloseMenu()
                                                              this.setState({openGestionSignatureDialog:true})
                                                          }}
                                                    >
                                                          <div><FactCheck style={{marginRight:2}}/>Gérer les signatures</div>
                                                    </MenuItem>
                                                )
                                            }
                                            <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && (this.state.selectedFiles[0].status === "inZip") || (this.state.selectedFiles[0].infos && (!this.state.selectedFiles[0].infos.archived))) || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")}
                                                      onClick={()=>{
                                                          this.getSignEnCours()
                                                          this.handleCloseMenu()
                                                          this.setState({openSignatureDialog:true})
                                                      }}
                                            >
                                                <div><Gavel style={{marginRight:2}}/>Demande de signature</div>
                                            </MenuItem>
                                            <MenuItem disabled={this.state.selectedFiles.length>1 || this.state.selectedFiles.type !== "dossier" && this.state.selectedFiles.findIndex(elt => (elt.infos && elt.infos.archived === false)) !== -1} onClick={()=>{this.handleCloseMenu();this.openFileOrFolder(this.state.selectedFiles[0])}}><Visibility style={{marginRight:2}}/>Prévisualiser</MenuItem>
                                            <MenuItem disabled={this.state.selectedFiles.type !== "dossier" && this.state.selectedFiles.findIndex(elt => (elt.infos && elt.infos.archived === false)) !== -1} onClick={()=>{this.handleCloseMenu();this.clickDownload()}}><GetAppIcon style={{marginRight:2}}/>Télécharger</MenuItem>
                                            <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{this.handleCloseMenu();this.createCertificate()}}><Description style={{marginRight:2}}/>Créer un certificat</MenuItem>
                                            <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{this.handleCloseMenu();this.goToTraceability()}}><AccountTree style={{marginRight:2}}/>Traçabilité</MenuItem>
                                            <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip" ) || this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{
                                                this.handleCloseMenu();
                                                this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name.split("##")[1]})
                                            }}>
                                                <Edit style={{marginRight:2}}/>Renommer
                                            </MenuItem>
                                            {!this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")  && (
                                                <MenuItem disabled={this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés")} onClick={()=>{
                                                    this.handleCloseMenu();
                                                    this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                                    Toast.fire({
                                                        title: "Naviguez dans le dossier de votre choix et cliquez sur 'Déplacer ici'.",
                                                        icon: 'info',
                                                    })
                                                }}><DriveFolderUpload style={{marginRight:2}}/>Déplacer</MenuItem>

                                            )}
                                            {/*<MenuItem disabled={this.state.freeClient||this.state.breadcrumbsDisplay.includes("#shared#Dossiers partagés") || (this.state.selectedFiles.findIndex(elt => elt.path.includes("notArchived") === true) !== -1)} onClick={()=>{this.handleCloseMenu();this.shareFilesByMail()}}><AttachEmailIcon style={{marginRight:2}}/>Partager par email</MenuItem>*/}
                                        </div>
                                    )
                                )}
                            </Menu>
                        </div>
                    </div>
                    <hr style={{width:"99.8%",margin:0}}/>
                    {/*Système de tri*/}
                    <div style={{width:"100%", margin:0, display:"flex",justifyContent:"space-around",userSelect: "none"}}>
                        <div onClick={()=>{this.changeSortStatus("date")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                            <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "date" && "#000000"}}>Date </p>
                            {this.state.sortStatus === "date-down" ? <ArrowDownward/>:this.state.sortStatus === "date-up" && <ArrowUpward/>}
                        </div>
                        <div onClick={()=>{this.changeSortStatus("nom")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                            <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "nom" && "#000000"}}>Nom </p>
                            {this.state.sortStatus === "nom-down" ? <ArrowDownward/>:this.state.sortStatus === "nom-up" && <ArrowUpward/>}
                        </div>
                        <div onClick={()=>{this.changeSortStatus("poids")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                            <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "poids" && "#000000"}}>Poids</p>
                            {this.state.sortStatus === "poids-down" ? <ArrowDownward/>:this.state.sortStatus === "poids-up" && <ArrowUpward/>}
                        </div>
                        <div onClick={()=>{this.changeSortStatus("archivage")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                            <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "archivage" && "#000000"}}>Archivage</p>
                            {this.state.sortStatus === "archivage-down" ? <ArrowDownward/>:this.state.sortStatus === "archivage-up" && <ArrowUpward/>}
                        </div>
                        <div onClick={()=>{this.changeSortStatus("signature")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                            <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "signature" && "#000000"}}>Signature</p>
                            {this.state.sortStatus === "signature-down" ? <ArrowDownward/>:this.state.sortStatus === "signature-up" && <ArrowUpward/>}
                        </div>
                    </div>
                    <div style={{display:"flex",alignItems:"center"}} onClick={()=>{this.state.breadcrumbsDisplay.length>1 ? this.selectBreadcrumbs(this.state.breadcrumbsDisplay[this.state.breadcrumbsDisplay.length-2],this.state.archiFiles.slice(), this.state.breadcrumbsDisplay.slice()):(this.state.admin ? this.selectBreadcrumbs(this.state.breadcrumbsDisplay[this.state.breadcrumbsDisplay.length-2],this.state.archiFiles.slice(), this.state.breadcrumbsDisplay.slice()):this.clearFilterAndGoRoot())}}>
                        <Tooltip arrow={true} placement="left" title={"Retour arrière"}>
                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                <KeyboardReturnIcon style={{marginTop:5, marginLeft:20, cursor:"pointer"}}/>
                                {(this.state.admin && this.state.breadcrumbsDisplay.length === 2) && <p style={{cursor:"pointer", margin:0}}>Voir les autres dossiers</p>}
                            </div>
                        </Tooltip>
                    </div>
                    <div id={"divScroll"} style={{overflowY:"auto", width:"100%", height:"100%"}}>
                        <div style={{minHeight:this.state.virtualListHeight === 0 ? (this.state.toDisplay.length*54):(this.state.virtualListHeight), position:"relative", width:"100%"}}>
                            {this.state.displaySkeleton && (
                                <div style={{width:"100%"}}>
                                    <Skeleton style={{margin:10,marginTop:20}}/>
                                    <Skeleton style={{margin:10}} />
                                    <Skeleton style={{margin:10}} />
                                </div>
                            )}
                            {this.state.displayCreateFolderLine && (
                                <div style={{padding:10,display:"flex",alignItems:"center",justifyContent:"space-between" ,paddingLeft:30,border:0, backgroundColor:"transparent"}}>
                                    <div style={{cursor:"pointer", display:"flex",alignItems:"center", margin:0}}>
                                        <FolderIcon/>
                                        <TextField name={"newFolderName"} value={this.state.newFolderName} defaultValue={this.state.newFolderName} onChange={(e)=>{this.handleChangeFolder(e)}} style={{margin:0,marginLeft:5}} label="Nom du dossier" variant="outlined" />
                                        <Button disabled={!this.state.newFolderName || this.state.newFolderName.length < 2} onClick={()=>{this.CreateFolder()}} style={{height:"56px", color:(!this.state.newFolderName || this.state.newFolderName.length < 2) ? "#e2e2e2":"#000", borderColor:(!this.state.newFolderName || this.state.newFolderName.length < 2) ? "#e2e2e2":"#000"}} type={"submit"} variant={"outlined"}> <Check/> </Button>
                                        <Button onClick={()=>{this.setState({displayCreateFolderLine:false,newFolderName:""})}} style={{height:"56px", color:"#000", borderColor:"#000"}} type={"submit"} variant={"outlined"}> <Close/> </Button>
                                    </div>
                                </div>
                            )}
                            {this.state.rechercheDisplay === "" && (((!this.state.admin ? this.state.breadcrumbsDisplay.length===0:(this.state.breadcrumbsDisplay.length===2 && (this.state.breadcrumbsDisplay[1] === jwtDecoded.pseudo))) && !this.state.displaySkeleton)) && (
                                <div className={"selectableLineTDB"} onAuxClick={(e)=>{if(e.button === 2){this.selectFileOrFolder({type:"sharedFolder",name:"#shared#Dossiers partagés",path:"sharedFolder",content:[]},"rightClick")}}} onClick={()=>{this.selectFileOrFolder({type:"sharedFolder",name:"#shared#Dossiers partagés",path:"sharedFolder",content:[]})}} onDoubleClick={()=>{this.openSharedFolder()}} style={{padding:"15px 10px 15px 30px",display:"flex",alignItems:"center",justifyContent:"space-between" ,border:this.state["selectedsharedFolder"] === true ? "solid blue 2px":"0px", backgroundColor:this.state["selectedsharedFolder"] === true ? "#c3e2ff":"transparent",width:this.state["selectedsharedFolder"] === true ? "calc(100% - 44px)":"calc(100% - 40px)",zIndex:this.state["selectedsharedFolder"] === true && 99}}>
                                    <div style={{cursor:"pointer", display:"flex",alignItems:"center", margin:0}}>
                                        <FolderSpecial/>
                                        <p style={{margin:0,marginLeft:5}}>Dossiers partagés</p>
                                    </div>
                                </div>
                            )}
                            {this.state.toDisplay.map((folder,index)=>(
                                <>
                                    {this.state.firstVisibleIndex >= index-12 && this.state.firstVisibleIndex <= index+2 && (
                                        <div key={"toDisplayItem_"+index} className={"selectableLineTDB"} onAuxClick={(e)=>{if(e.button === 2){this.selectFileOrFolder(folder,"rightClick")}}} onClick={()=>{this.selectFileOrFolder(folder)}} onDoubleClick={()=>{
                                            if(folder.type !== "image" ||
                                                (folder.type === "image" &&
                                                    (!this.state.disableMenu && (this.state.admin && this.state.breadcrumbsDisplay.includes(jwtDecoded.pseudo)) || !this.state.admin)
                                                )
                                            ){
                                                this.openFileOrFolder(folder)
                                            }
                                        }} style={{border:this.state["selected"+folder.path] === true ? "solid blue 2px":"0px", backgroundColor:this.state["selected"+folder.path] === true ? "#c3e2ff":"transparent",width:this.state["selected"+folder.path] === true ? "calc(100% - 44px)":"calc(100% - 40px)",zIndex:this.state["selected"+folder.path] === true && 99,
                                            top: (((this.state.rechercheDisplay === "" && (((!this.state.admin ? this.state.breadcrumbsDisplay.length===0:(this.state.breadcrumbsDisplay.length===2 && (this.state.breadcrumbsDisplay[1] === jwtDecoded.pseudo))) && !this.state.displaySkeleton)) ? 1: 0) + index)*54)+ (this.state.displayCreateFolderLine ? 76:0)}}> {/*Condition pour ajoute 54px si on display le Dossier partagé et/ou 76px pour la création de dossiers*/}
                                            <div style={{cursor:"pointer", display:"flex",alignItems:"center", margin:0, position:"relative"}}>
                                                {folder.type ==="dossier" ? (
                                                    <>
                                                        {folder.shared ===false ? (
                                                            <FolderIcon style={{margin:"5px 0 5px 0"}}/>
                                                        ):(
                                                            <FolderShared style={{margin:"5px 0 5px 0"}}/>
                                                        )}
                                                    </>
                                                ):(
                                                    <>
                                                        {this.getIconForFileType(folder.name)}
                                                    </>
                                                )}
                                                {folder.path.includes("ciphered##") ? (
                                                    <Tooltip arrow={true} placement="bottom" title={folder.name.split("##")[1]}>
                                                        <p style={{margin:0,marginLeft:5}} >{folder.name.split("##")[1].slice(0, 4) + "..." + folder.name.split("##")[1].slice(-10)}</p>
                                                    </Tooltip>
                                                ):(
                                                    <>
                                                        {this.state.displayRenameFolder && this.state.fileClipboardToRename.name === folder.name ? (
                                                            <div style={{padding:10,display:"flex",alignItems:"center",justifyContent:"space-between" ,paddingLeft:30,border:0, backgroundColor:"transparent"}}>
                                                                <div style={{cursor:"pointer", display:"flex",alignItems:"center", margin:0, minWidth:250}}>
                                                                    <TextField inputProps={{ style: { padding: '8px' } }} name={"newFolderRename"} value={this.state.newFolderRename} defaultValue={folder.name.toString()} onChange={(e)=>{this.handleChange(e)}} style={{margin:0,marginLeft:5, backgroundColor:"#FFF"}} label={`Nom du ${this.state.fileClipboardToRename && (this.state.fileClipboardToRename.type==="dossier" ? "dossier":"fichier")}`} variant="outlined" />
                                                                    <button disabled={!this.state.newFolderRename || this.state.newFolderRename.length <2} className={"flexCenter buttonSK"} onClick={()=>{this.RenameFolder()}} style={{height:35, width:35, padding:0, backgroundColor:"#FFF", border:"1px solid lightgrey"}} type={"submit"}> <Check style={{width:23}}/> </button>
                                                                    <button className={"flexCenter buttonSK"} onClick={()=>{this.setState({displayRenameFolder:false,fileClipboardToRename:{},newFolderRename:""})}} style={{height:35, width:35, padding:0, backgroundColor:"#FFF", border:"1px solid lightgrey"}} type={"submit"}> <Close style={{width:23}}/> </button>
                                                                </div>
                                                            </div>
                                                        ):(
                                                            <div style={{width:"fit-content",margin:0,marginLeft:5}}>
                                                                <p style={{margin:0,maxHeight:42, overflow:"hidden"}} >{folder.name.includes("##") ? folder.name.split("##")[1]:folder.name}</p>
                                                            </div>
                                                            // <p style={{margin:0,marginLeft:5, maxHeight:42, position:"absolute", overflow:"hidden"}} >Ceci est un long nom de fichier qui risque de poser problème éventuellement je sais pas trop</p>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className={"flexCenter"} style={{margin:0,marginRight:20, cursor:"pointer"}}>
                                                <p style={{fontSize:12,margin:0, marginRight:20}} >{this.state.rechercheDisplay !== "" &&(folder.path.includes("./Q_file/stockageSecure/") &&(folder.path.split("/").slice(4)).join("/").slice(0,40)+"...")}</p>
                                                {(folder.type !== "dossier" && folder.infos && folder.infos.archived === true) && (
                                                    <p className={"noselect"} style={{margin:0}} >{folder.infos && (folder.infos.size/1000000).toFixed(3)+" MB"}</p>
                                                )}
                                                {folder.type ==="dossier" && (
                                                    <p className={"flexCenter"} style={{margin:0, fontStyle:"italic"}}>{folder.content.length} élément{folder.content.length>1 && "s"}</p>
                                                )}
                                                {folder.infos && (
                                                    <>
                                                        <div
                                                            onClick={()=>{this.setState({displayDialogInfo: true, fileInfo:folder})}}
                                                            style={{cursor:"pointer"}}
                                                        >
                                                            <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
                                                                borderColor:"#336480FF", color:"#FFFFFF", marginLeft:10}}>
                                                                <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                                                                    borderColor:"#52A8D9FF", color:"#FFFFFF"}}>
                                                                    <Tooltip arrow={true} placement="bottom" title={"Afficher les informations du fichier."}>
                                                                        <div className={"flexCenter"}>
                                                                            <QuestionMark style={{color:"#FFFFFF",width:18,marginTop:0}} />
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            onClick={()=>{this.changeArchivedStatus(folder)}}
                                                            style={{cursor:"pointer"}}
                                                        >
                                                            <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
                                                                borderColor:folder.infos.archived === true ? "#219400":"#AB1212FF",
                                                                borderRadius: 40, color:"#FFFFFF", marginLeft:10}}>
                                                                <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                                                                    borderColor:folder.infos.archived === true ? "#2ECE00":"#FF2222FF",
                                                                    borderRadius: 40, color:"#FFFFFF"}}>
                                                                    <Tooltip arrow={true} placement="bottom" title={folder.infos.archived === true ? "Ce document est stocké dans nos serveurs.":"Ce document n'est pas stocké dans nos serveurs."}>
                                                                        <div className={"flexCenter"}>
                                                                            <Save style={{color:"#FFFFFF",width:18,marginTop:0}} />
                                                                            <div style={{position:"relative", display:folder.infos.archived === true ? "none":"block"}}>
                                                                                <div style={{top:-2,left:-22,minWidth:22,minHeight:2,backgroundColor:"white",border:"0.5px solid #FF2222FF", transform:"rotate(45deg)",display:"block", position:"absolute"}}/>
                                                                            </div>
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
                                                            borderRightColor:folder.infos.signed === true ? "#219400":folder.infos.signed ==="beingSigned" ? "#C18A00":folder.infos.signed ==="intermediate" ?"#219400":"#336480",
                                                            borderBottomColor:folder.infos.signed === true ? "#219400":folder.infos.signed ==="beingSigned" ? "#C18A00":folder.infos.signed ==="intermediate" ?"#219400":"#336480",
                                                            borderLeftColor:folder.infos.signed === true ? "#219400":folder.infos.signed ==="beingSigned" ? "#C18A00":folder.infos.signed ==="intermediate" ?"#c18a00":"#336480",
                                                            borderTopColor:folder.infos.signed === true ? "#219400":folder.infos.signed ==="beingSigned" ? "#C18A00":folder.infos.signed ==="intermediate" ?"#c18a00":"#336480",
                                                            borderRadius: 40, color:"#FFFFFF", marginLeft:10}}>
                                                            <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                                                                borderRightColor:folder.infos.signed === true ? "#2ECE00":folder.infos.signed ==="beingSigned" ? "#FFB600":folder.infos.signed ==="intermediate"?"#2ece00":"#52a8d9",
                                                                borderBottomColor:folder.infos.signed === true ? "#2ECE00":folder.infos.signed ==="beingSigned" ? "#FFB600":folder.infos.signed ==="intermediate"?"#2ece00":"#52a8d9",
                                                                borderLeftColor:folder.infos.signed === true ? "#2ECE00":folder.infos.signed ==="beingSigned" ? "#FFB600":folder.infos.signed ==="intermediate"?"#ffb600":"#52a8d9",
                                                                borderTopColor:folder.infos.signed === true ? "#2ECE00":folder.infos.signed ==="beingSigned" ? "#FFB600":folder.infos.signed ==="intermediate"?"#ffb600":"#52a8d9",
                                                                borderRadius: 40, color:"#FFFFFF"}}>
                                                                <Tooltip arrow={true} placement="bottom" title={folder.infos && (folder.infos.signed === true ? "Fichier signé":folder.infos.signed ==="beingSigned" ? "Fichier en attente de signature":folder.infos.signed ==="intermediate" ?"Ficher approuvé mais en cours de validation par un autre signataire":"Fichier enregistré avec votre signature")}>
                                                                    <p style={{fontSize:12,margin:0,marginLeft:5,marginRight:5}} ><SecurityIcon style={{color:"#FFFFFF",width:18,marginTop:4}} /></p>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
                {this.state.displayMoveFileChoice && (
                    <div style={{position:"relative",width:"100%",display:"flex", justifyContent:"flex-end", zIndex:999}}>
                        <div style={{position:"absolute", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"flex-end",width:"100%",height:"100%",top:-25, right:20}}>
                            <div style={{maxHeight:200, overflow:"auto",position:"absolute", height:"fit-content", bottom:28}}>
                                {this.state.fileClipboardToMove.map((fol,index)=>(
                                    <p style={{display:index > 100 ? "none":"flex",alignItems:"center",backgroundColor:"white",padding:5,borderRadius:5,margin:2}}> <DriveFolderUpload/> {fol.type === "dossier" ? fol.name:fol.name.split("##")[1]}</p>
                                ))}
                            </div>
                            <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                                <Button disabled={!this.state.displayMoveFileChoice || this.state.movingFile} variant={"contained"} style={{width:130,backgroundColor:this.state.movingFile ? "grey":"#FF3629FF", textTransform:"none"}} onClick={()=>{this.setState({displayMoveFileChoice:false,fileClipboardToMove:[]})}}>
                                    <p className={"flexCenter"} style={{margin:3, fontSize:16, color:"#FFFFFF"}}>
                                        Annuler
                                    </p>
                                </Button>
                                <Button disabled={!this.state.displayMoveFileChoice || this.state.movingFile} variant={"contained"} style={{width:130,backgroundColor:this.state.movingFile ? "grey":"#52a8d9", marginLeft:10,textTransform:"none"}} onClick={()=>{this.moveFileOrFolder()}}>
                                    <p className={"flexCenter"} style={{margin:3, fontSize:16, color:"#FFFFFF"}}>
                                        Déplacer ici
                                    </p>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default withRouter(FileManagerComponent);