import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isMobile} from "react-device-detect";

import Axios from "axios";

import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import Swal from "sweetalert2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ClearIcon from '@mui/icons-material/Clear';
import {Close} from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import jwt_decode from "jwt-decode";
import {createFilterOptions} from '@mui/material/Autocomplete';
import {urlAxios, urlAxiosCore} from '../Components/GetURL';

const columnsClientShare =[
    { id: 'pseudo', label: 'Nom', minWidth: 120 },
    { id: 'Action', label: 'Supprimer', minWidth: 50,maxWidth:50 },
]
const columns = [
    { id: 'Date', label: 'Date', minWidth: 150 },
    { id: 'Nom', label: 'Nom', minWidth: 200 },
    { id: 'Action', label: 'Action', minWidth: 10 },
];

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    ignoreAccents: true,
    stringify: (option) => `${option.pseudo} ${option.email}`,
    trim: true,
});

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class SignatureCollaborative extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hashFile : '',
            fileName : '',
            acceptedFile : null,
            listAllTrace:[],
            champsMDP:"",
            champsIdentifiant:"",
            connected:false,
            detailDoc:{},
            allValidateurs:[],
            validateurChosen:"",
            openDialogChoiceValidateur:false,
            openDialogMotif:false,
            openDialogSignAndChoiceValidateur:false,
            motif:"",
            showBrowserView:true,
            downloadFinished:true,
            showSaveFilePickerCanceled:false,
            validationsEnAttente:[],
            listSignataire :[],
            searchWithG:false,
            searchWithC:false,
            searchWithCSA:false,
            allClientsDisplayed:[],
            allClient:[],
            clientChosenForSignataire:null
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    _askConnection;
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)

        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt")
        }, ()=> {
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            new Promise(async resolve => {
                if (!!this.state.jwt){
                    resolve()
                }else{
                    global.emitter.emit("askConnection") //Display interface connexion du header Kagesecur
                    this._askConnection = global.emitter.addListener("emitConnected",()=>{ //On prévient que le client s'est connecté
                        this.setState({jwt:sessionStorage.getItem("KAGESECUR_jwt")}, ()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                            resolve()
                        })
                    })
                }
            }).then(()=> {
                Axios.post(urlAxiosCore+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }

                    if (response.data.resultat === true){
                        global.emitter.emit("onRouteChangedKageSecur",{
                            user:jwtDecoded.gestionnaire ? "gestionnaire":"client",
                            pseudo:jwtDecoded.pseudo
                        })
                        this.getValidations()
                    }else{
                        window.location.replace("/KageSecur/Accueil")
                    }
                })
            })
        })
    }

    getValidations(){
        Axios.post(urlAxios+"getSignatures",{},{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then(response=>{
            if (response.data.jwt){
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
                sessionStorage.setItem("KAGESECUR_jwt", response.data.jwt)
            }
            if (response.data.resultat==="autorise"){
                this.setState({
                    connected:true,
                    champsIdentifiant:sessionStorage.getItem("KAGESECUR_pseudo"),
                    validationsEnAttente:response.data.allSignatures,
                    isSure:false,
                },()=>{
                    this.getAllClient()
                })
            }else{
                Swal.fire({
                    title:"Une erreur est survenue",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.props.navigate("/KageSecur/Accueil", { replace: true })
                })
            }
        })
    }

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    downloadFile(){

        let allPath = ["c","h","e","m","i","n"]
        allPath.splice(0,3)
        allPath[allPath.length-1] = allPath[allPath.length-1].replaceAll("##","pourcentdiese")

        Axios.post(urlAxios+"generateKSnonce",{idToken:this.state.detailDoc.fileName.split("##").shift()},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
            if (response.data.resultat === "done"){
                Toast.fire({
                    title: "Téléchargement démarré",
                    icon: 'info',
                })
                window.open(`${urlAxios}downloadSignCo?tokenJwt=${this.state.jwt}&hashFile=${this.state.detailDoc.hashFile}&fileName=${encodeURIComponent(this.state.detailDoc.fileName.split("##")[1])}&idToken=${this.state.detailDoc.fileName.split("##")[0]}&nonce=${response.data.nonce}`)
            }else{
                Toast.fire({
                    title: "Impossible de démarrer le téléchargement",
                    icon: 'warning',
                })
            }
        })
    }

    verifAuthUser(choice,force,errored){
        let self = this
        this.setState({
            openDialogChoiceValidateur:false,
            openDialogMotif:false
        })
        //Verification avec code envoyé par mail
        Axios.post(urlAxios+"sendCodeByMail", {
            empreinte:this.state.empreinte,
            force:force,
            hashJwt:this.state.empreinte
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if (response.data.jwt){
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            Swal.fire({
                title: 'Entrez le code reçu par mail',
                html:errored === true ? "<p>Veuillez saisir un code correct (6 caractères)</p>" : "<p>Ce code n'est valable qu'une heure.</p> ",
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                cancelButtonText:"Annuler",
                cancelButtonColor:"#d14529",
                confirmButtonText: 'Suivant',
                showDenyButton:true,
                denyButtonText:"Renvoyer le code",
                denyButtonColor:"#757575",
                showLoaderOnConfirm: true,
                preConfirm: (code) => {
                    if(code !== "" && code.length === 6){
                        self.choiceValidation(choice,code)
                    }else{
                        Toast.fire({
                            icon: 'error',
                            title: 'Veuillez saisir un code correct.'
                        })
                        this.verifAuthUser(choice,force,true)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result)=> {
                if(result.isDenied) {
                    this.verifAuthUser(choice,true,false)
                }
            })
        }).catch(()=>{
            Toast.fire({
                icon: 'error',
                title: 'Une erreur est survenue.'
            })
        })
    }

    choiceValidation(choice,code){ //Duplication de code un peu
        if(choice === "accept"){
            Swal.fire({
                title: 'Attention!',
                text: "Voulez-vous vraiment signer ce document ?",
                icon: 'info',
                confirmButtonText: "Oui, le signer",
                showCancelButton:true,
                cancelButtonText:"Annuler",
                cancelButtonColor:"red"
            }).then((result)=>{
                if(result.isConfirmed){
                    Axios.post(urlAxios+"confirmSignatures", {
                        hashToSign : this.state.detailDoc.hashFile,
                        idSignataire:jwtDecoded.pseudo,
                        empreinteSignataire:this.state.empreinte,
                        acceptOrDelete : choice,
                        code:code,
                        signatairesDelegate:this.state.listSignataire,
                        motif:this.state.motif,
                        hashJwt:this.state.empreinte
                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                        if (response.data.jwt){
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if(response.data.resultat === "Code incorrect."){
                            Swal.fire({
                                icon: 'error',
                                title: 'Code incorrect.',
                                text:"Le code de vérification n'est pas correct."
                            })
                        }else{
                            let list = this.state.validationsEnAttente.slice()
                            this.state.validationsEnAttente.forEach(elt => {
                                if (elt === this.state.detailDoc){
                                    list.splice(list.indexOf(elt),1)
                                }
                            })
                            Toast.fire({
                                icon: 'success',
                                title: 'Vous avez correctement signé ce document'
                            })

                            this.setState({
                                validationsEnAttente:list,
                                detailDoc:{},
                                openDialogChoiceValidateur:false,
                                openDialogMotif:false,
                                motif:""
                            })
                        }
                    }).catch(()=>{
                        Toast.fire({
                            icon: 'error',
                            title: 'Une erreur est survenue.'
                        })
                    })
                }
            })
        }else{
            if(this.state.listSignataire.length>0 || choice ==="refuse"){
                Axios.post(urlAxios+"confirmSignatures", {
                    hashToSign : this.state.detailDoc.hashFile,
                    idSignataire:jwtDecoded.pseudo,
                    empreinteSignataire:this.state.empreinte,
                    acceptOrDelete : choice,
                    code:code,
                    signatairesDelegate:this.state.listSignataire,
                    motif:this.state.motif,
                    hashJwt:this.state.empreinte
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                    if (response.data.jwt){
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if(response.data.resultat === "Code incorrect."){
                        Toast.fire({
                            icon: 'error',
                            title: 'Le code est incorrect.'
                        })
                    }else if (response.data.resultat === "Action impossible."){
                        Toast.fire({
                            icon: 'error',
                            title: 'Vous ne pouvez pas déléguer ou transférer une signature à vous-même.'
                        })
                    }else{
                        let list = this.state.validationsEnAttente.slice()
                        this.state.validationsEnAttente.forEach(elt => {
                            if (elt === this.state.detailDoc){
                                list.splice(list.indexOf(elt),1)
                            }
                        })
                        if(choice === "refuse"){
                            Toast.fire({
                                icon: 'success',
                                title: 'Vous avez correctement refusé ce document'
                            })
                        }else if(choice === "delegate"){
                            Toast.fire({
                                icon: 'success',
                                title: 'Vous avez correctement délégué ce document'
                            })
                        }else{
                            Toast.fire({
                                icon: 'success',
                                title: 'Vous avez correctement signé et délégué ce document'
                            })
                        }
                        this.setState({
                            validationsEnAttente:list,
                            detailDoc:{},
                            openDialogChoiceValidateur:false,
                            openDialogMotif:false,
                            motif:""
                        })
                    }
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Veuillez choisir un signataire.",
                    icon: 'error',
                    target:".MuiDialog-root",
                    confirmButtonText: 'Ok'
                })
            }
        }
    }

    getAllClient(){
        Axios.post(urlAxios+"getAllClients",{hashJwt: sessionStorage.getItem("KAGESECUR_hash")},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            let allClientsTemp = [], clientsOthersNodes = [];
            (response.data.resultat).forEach(client=>{
                if (client.thisSrv){
                    client.label = client.pseudo
                    allClientsTemp.push(client)
                }else{
                    client.label = client.pseudo
                    clientsOthersNodes.push(client)
                }
            })
            this.setState({
                allClients:allClientsTemp,
                allClientsDisplayed:allClientsTemp,
                allClientsOthersNodes:clientsOthersNodes
            })
        })
    }

    AddClientToSignataire(client){
        this.setState({clientChosenForSignataire:""})
        let listSignataireTemp = this.state.listSignataire
        if(!listSignataireTemp.includes(client)){
            listSignataireTemp.push(client)
            this.setState({
                listSignataire :listSignataireTemp
            })
        }else{
            Swal.fire({
                title: 'Utilisateur déjà présent !',
                text: "Vous ne pouvez pas ajouter deux fois le même utilisateur en tant que signataire.",
                target:"#dialogDisplayShare",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    RemoveClientToSignataire(client){
        let listSignataireTemp = this.state.listSignataire
        let indexClient = listSignataireTemp.findIndex(elt=>elt.pseudo === client)
        if(indexClient !== -1){
            listSignataireTemp.splice(indexClient,1)
            this.setState({
                listSignataire :listSignataireTemp
            })
        }
    }

    changeListAllClient(){ //Filtre
        let allClientToDisplay = []
        this.state.allClients.forEach(elt=>{
            if((elt.status === "client" && elt.abonnement === "Sans abonnement") && this.state.searchWithCSA){
                allClientToDisplay.push(elt)
            }else if ((elt.status === "client" && elt.abonnement !== "Sans abonnement") && this.state.searchWithC){
                allClientToDisplay.push(elt)
            }else if ((elt.status === "admin" || elt.status === "gestionnaire") && this.state.searchWithG){
                allClientToDisplay.push(elt)
            }
        })
        if (this.state.searchWithCSA === false && this.state.searchWithC === false && this.state.searchWithG === false){
            allClientToDisplay = this.state.allClients.slice()
        }
        this.setState({allClientsDisplayed:allClientToDisplay})
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.openDialogChoiceValidateur}
                    onClose={()=>{this.setState({openDialogChoiceValidateur: false,listSignataire:[],searchWithG:false,searchWithC:false,searchWithCSA:false})}}
                    id={"dialogDisplayShare"}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                >
                    <DialogTitle>
                        <div style={{display:"flex"}}>
                            <Button onClick={()=>{this.setState({openDialogChoiceValidateur:false})}} style={{padding:0, minWidth:40, marginRight:5}}><ChevronLeftIcon/></Button>
                            Choisissez un signataire :
                        </div>
                    </DialogTitle>
                        <DialogContent style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center",overflow:"hidden"}}>
                            <div style={{width:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
                                <div style={{width:"100%", display:"flex", justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                    <Autocomplete
                                        disablePortal
                                        noOptionsText={'Aucun résultat'}
                                        ListboxProps={{ style: { maxHeight: 100 } }}
                                        options={this.state.allClientsDisplayed}
                                        value={this.state.clientChosenForSignataire}
                                        sx={{ width: "100%", marginBottom:0 }}
                                        onChange={(e, newval, reason) => {
                                            this.setState({clientChosenForSignataire:newval})
                                        }}
                                        filterOptions={filterOptions}
                                        renderInput={(params) =>
                                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                <TextField variant={"outlined"} style={{marginBottom:0,width:"80%"}} {...params} label="Rechercher un utilisateur..." />
                                                <Tooltip arrow={true} title={"Ecrivez dans ce champs de texte l'identifiant de la personne à qui vous voulez demander une signature"}>
                                                    <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                                </Tooltip>
                                            </div>
                                        }                                    />
                                    <div style={{fontSize:12,marginBottom:0,marginTop:5,display:"flex",flexWrap:"wrap",width:"80%"}}>
                                        <p style={{margin:0,marginRight:3}}>Recherche parmi : </p>
                                        {((!this.state.searchWithG && !this.state.searchWithC && !this.state.searchWithCSA )|| (this.state.searchWithG && this.state.searchWithC && this.state.searchWithCSA)) ? <p style={{margin:0,fontWeight:"bold"}}>Tous les utilisateurs</p> :(
                                            <>
                                                <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{this.state.searchWithG && " Gestionnaires"}</p>
                                                <p style={{margin:0,fontWeight:"bold",marginRight:3}}>{(this.state.searchWithG && this.state.searchWithC) ? " - Clients" : this.state.searchWithC && " Clients"}</p>
                                                <p style={{margin:0,fontWeight:"bold"}}>{((this.state.searchWithG || this.state.searchWithC) && this.state.searchWithCSA) ? " - Clients sans abonnements" : this.state.searchWithCSA && " Clients sans abonnements"}</p>
                                            </>
                                        )}
                                    </div>
                                    <div style={{width:"100%", display:"flex", justifyContent:"flex-start",alignItems:"center",flexWrap:"wrap"}}>
                                        <Button onClick={()=>{this.setState({searchWithG:!this.state.searchWithG},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithG ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 0" }}>Gestionnaires</Button>
                                        <Button onClick={()=>{this.setState({searchWithC:!this.state.searchWithC},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithC ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 2px 10px 2px" }}>Clients</Button>
                                        <Button onClick={()=>{this.setState({searchWithCSA:!this.state.searchWithCSA},()=>{this.changeListAllClient()})}} style={{textTransform:"none",borderRadius: 10, fontSize:12,backgroundColor:this.state.searchWithCSA ? "#52A8D9FF":"lightgrey",color:"white",margin:"10px 0 10px 2px"}}>Clients sans abonnement</Button>
                                    </div>

                                </div>

                                <Button disabled={this.state.clientChosenForSignataire === null} style={{backgroundColor:this.state.clientChosenForSignataire === null ? "white":"#52A8D9FF",color:this.state.clientChosenForSignataire === null ? "lightgrey":"white", margin:5,height:47}} variant={"contained"} onClick={()=>{this.AddClientToSignataire(this.state.clientChosenForSignataire)}} >Ajouter</Button>
                            </div>
                            <hr style={{width:"100%",marginTop:0}}/>
                            <div style={{maxHeight:200,overflowY:"auto"}}>
                                <h3 style={{textAlign:"center",fontWeigth:"normal"}}>Liste des signataires :</h3>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableBody>
                                            {this.state.listSignataire.length === 0 && (
                                                <p style={{textAlign:"center"}}>Aucun signataire...</p>
                                            )}
                                            {this.state.listSignataire.map(elt=>(
                                                <TableRow hover role="checkbox" tabIndex={-1} key={elt.pseudo}>
                                                    {columnsClientShare.map((column) => {
                                                        return (
                                                            <>
                                                                {column.id === "Action" ?
                                                                    <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                        <Tooltip arrow={true} title={"Retirer cet utilisateur des signataires."}>
                                                                            <Button onClick={()=>{this.RemoveClientToSignataire(elt.pseudo)}}>
                                                                                <Close/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </TableCell>

                                                                    :
                                                                    <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                        {elt[column.id]}
                                                                    </TableCell>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <Button disabled={this.state.listSignataire.length ===0} onClick={()=>{this.verifAuthUser(this.state.delegateOrSignAndTransfer,false,false)}} style={{textTransform:"none", fontSize:14,backgroundColor:this.state.listSignataire.length ===0?"lightGrey":"#52A8D9FF",color:this.state.listSignataire.length ===0 ? "white":"white",margin:10 }} variant={"outlined"}>{this.state.delegateOrSignAndTransfer === "delegate" ? "Déléguer" : "Signer et transférer"}</Button>
                        </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.openDialogMotif}
                    onClose={()=>{this.setState({openDialogMotif: false})}}

                >
                    <DialogTitle style={{display:"flex"}}>
                        <div style={{display:"flex"}}>
                            <Button onClick={()=>{this.setState({openDialogMotif:false, motif:""})}} style={{padding:0, minWidth:40, marginRight:5}}><ChevronLeftIcon/></Button>
                            Motif de refus :
                        </div>
                    </DialogTitle>
                    <DialogContent style={{display:"flex", flexDirection:"column", justifyItems:"center"}}>
                        <TextField style={{width:300}} name={"motif"} value={this.state.motif} onChange={(e)=>{this.handleChange(e)}} placeholder={"Motif"}/>
                    </DialogContent>
                    <Button onClick={()=>{this.verifAuthUser("refuse",false,false)}} style={{margin:"10px"}} variant={"outlined"} color="primary">Refuser</Button>
                </Dialog>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                    <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110, display:!this.state.showBrowserView && "none"}} src={require("../assets/signatureOrange.png")} alt="imageDocument"/>
                    <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20 ,display:!this.state.showBrowserView&& "none"}} src={require("../assets/acceptBlue.png")} alt="imageAuction"/>
                    <h1 style={{marginTop:50, textAlign:"center", marginBottom:40}}>Signez <span style={{color:"#52a8d9"}}>les documents</span> de <span style={{color:"#f18f29"}}>vos clients</span></h1>
                    <div style={{display:"flex", width:"100%",height:"70%", justifyContent:"center", alignItems:"center", flexDirection:!this.state.showBrowserView && "column"}}>
                        <div style={{width:!this.state.showBrowserView ? "95%":"50%", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <div style={{display:"flex",flexDirection:"column", justifyContent:"center",marginLeft:this.state.showBrowserView && 50, alignItems:"center",backgroundColor:"white",padding:20,borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                <div style={{position:"relative",width:"100%", textAlign:"right", display:!!this.state.detailDoc && Object.entries(this.state.detailDoc).length===0 && "none"}}>
                                    <ClearIcon onClick={()=>{this.setState({detailDoc:{}})}} style={{position:"absolute", cursor:"pointer",top:-10, right:-10}}/>
                                </div>
                                <h3 style={{marginTop:!!this.state.detailDoc && Object.entries(this.state.detailDoc).length>0 && 0}}>{!!this.state.detailDoc && Object.entries(this.state.detailDoc).length>0 ?"Informations du document :" : "Selectionnez un document"}  </h3>
                                {!!this.state.detailDoc && Object.entries(this.state.detailDoc).length>0 && (
                                    <>
                                        <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"flex-start", fontSize:17}}>
                                            <p>Nom du fichier : {this.state.detailDoc.fileName.split("##")[1]}</p>
                                            <Tooltip arrow={true} title={this.state.detailDoc.hashFile}>
                                                <p style={{cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(this.state.detailDoc.hashFile);
                                                    Toast.fire({
                                                        icon: 'info',
                                                        title: 'Hash copié dans le presse-papier !'
                                                    })
                                                }}>Hash du fichier : {this.state.detailDoc.hashFile && (this.state.detailDoc.hashFile.slice(0,4) +"..."+ this.state.detailDoc.hashFile.slice(-4))}</p>
                                            </Tooltip>
                                            <p>Date d'enregistrement du fichier : {new Date(this.state.detailDoc.date).toLocaleString("fr-FR")}</p>
                                            <p>Identifiant client : {this.state.detailDoc.pseudo}</p>
                                            <Tooltip arrow={true} title={this.state.detailDoc.empreinte}>
                                                <p style={{cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(this.state.detailDoc.empreinte);
                                                    Toast.fire({
                                                        icon: 'info',
                                                        title: 'Empreinte copiée dans le presse-papier !'
                                                    })
                                                }}>Empreinte client : {this.state.detailDoc.empreinte && (this.state.detailDoc.empreinte.slice(0,4) +"..."+ this.state.detailDoc.empreinte.slice(-4))}</p>
                                            </Tooltip>
                                        </div>
                                        <Button onClick={()=>{this.state.downloadFinished && this.downloadFile()}} style={{backgroundColor:"#369dd7",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5, textTransform:"none"}} >
                                            {!this.state.downloadFinished ? (
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                    <p style={{margin:0,marginRight:10}}>Téléchargement en cours...</p>
                                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                        <CircularProgress style={{color:"white"}} size={30}></CircularProgress>
                                                        <p style={{position:"absolute",fontSize:10}}>{this.state.progressDownloadFile}%</p>
                                                    </div>
                                                </div>

                                            ):(
                                                <p style={{margin:0,marginRight:10}}>Télécharger le fichier</p>
                                            )}
                                        </Button>
                                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>
                                            <Button onClick={()=>{this.verifAuthUser("accept",false,false)}} style={{backgroundColor:"#369dd7",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5, textTransform:"none"}}>Signer</Button>
                                            <Button onClick={()=>{this.setState({delegateOrSignAndTransfer:"signAndTransfer", openDialogChoiceValidateur:true})}} style={{backgroundColor:"#FFFFFF",padding:5, color:"#369dd7", border:"1px solid #369dd7",width:"100%", fontWeight:"bold", margin:5, textTransform:"none"}}><p style={{margin:0, padding:0, position:"absolute", top:-3}}>Signer &</p><br/><p style={{margin:0, padding:0, position:"relative", top:7}}>Transférer</p></Button>
                                            <Button onClick={()=>{this.setState({delegateOrSignAndTransfer:"delegate", openDialogChoiceValidateur:true})}} style={{backgroundColor:"#FFFFFF",padding:5, color:"#369dd7", border:"1px solid #369dd7",width:"100%", fontWeight:"bold", margin:5, textTransform:"none"}}>Déléguer</Button>
                                            <Button onClick={()=>{this.setState({openDialogMotif:true})}} style={{backgroundColor:"#ff3e3e",padding:5, color:"white",width:"100%", fontWeight:"bold", margin:5, textTransform:"none"}}>Refuser</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div style={{width:!this.state.showBrowserView ? "95%":"50%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <Paper style={{margin:10, width:!this.state.showBrowserView ? "100%":"85%", overflowY:"scroll",maxHeight:365}}>
                                <TableContainer>
                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.validationsEnAttente.length > 0 ? (
                                                <>
                                                    {(this.state.validationsEnAttente).map((row) => {
                                                        return (
                                                            <TableRow style={{cursor:"pointer"}} onClick={()=>{this.setState({detailDoc:row})}}  hover tabIndex={-1} key={row.code}>
                                                                {columns.map(column => {
                                                                    return (
                                                                        <TableCell key={column.id}>
                                                                            {column.id === "Nom" || column.id === 'Date' ? (
                                                                                column.id === "Nom" ? (
                                                                                    <p>{row.fileName.split("##")[1]}</p>
                                                                                ):(
                                                                                    <p>{new Date(row.date).toLocaleString("fr-FR" ,{timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}).replace(","," à")}</p>
                                                                                )
                                                                            ):(
                                                                                <Button><VisibilityIcon style={{color:"#000"}}/></Button>
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </>
                                            ):(
                                                <TableRow style={{cursor:"pointer"}}  hover tabIndex={-1} >
                                                    <p style={{marginLeft:"10%"}}>Vous n'avez aucun document à signer...</p>
                                                </TableRow>
                                            )}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SignatureCollaborative)