import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';

import FileManagerComponent from "../Components/FileManagerComponent";
import {isMobile} from "react-device-detect";
import Axios from "axios";

import jwt_decode from "jwt-decode";
import {urlAxiosCore} from '../Components/GetURL';

let jwtDecoded;

class VoirMesDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(urlAxiosCore+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === false){
                    window.location.replace("/KageSecur/Accueil")
                }
            })
        })
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"divClickDeselect"} style={{display:"flex",alignItems:"center",marginTop:90 ,height:"calc(100% - 90px)",width:"100%", flexDirection:"column"}}>
                    {this.state.showBrowserView ? (
                        <>
                            <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../assets/folderOrange.png")} alt="imageDocument"/>
                            <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../assets/documentBlue.png")} alt="imageAuction"/>
                            <h1 style={{marginTop:50}} ><span style={{color:"#52a8d9"}}>Tableau</span> <span style={{color:"#f18f29"}}>de bord</span></h1>
                        </>
                    ):(
                        <>
                            <h2 style={{marginTop:50}}><span style={{color:"#52a8d9"}}>Tableau</span> <span style={{color:"#f18f29"}}>de bord</span></h2>
                        </>
                    )}
                    <div style={{display:"flex", width:this.state.showBrowserView ? "70%":"95%",height:"78%", justifyContent:"center", alignItems:"center"}}>
                        {jwtDecoded !== {} && <FileManagerComponent app={"KAGESECUR"}/>}
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(VoirMesDocuments)