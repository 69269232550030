import React, {Component} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {EventEmitter} from 'fbemitter';
import packageData from '../package.json';

//KageSecur
import AccueilKageSecur from "./KageSecur/AccueilKS";
import UploadFile from "./KageSecur/UploadFile";
import VerificationKageSecur from "./KageSecur/VerificationKS";
import TracabiliteKageSecur from "./KageSecur/TracabiliteKS";
import SignatureCollaborative from "./KageSecur/SignatureCollaborative";
import VoirMesDocuments from "./KageSecur/VoirMesDocuments";
import InscriptionClientKageSecur from "./KageSecur/InscriptionClientKS";
import InscriptionClientSekurEmail from "./KageSecur/InscriptionClientSekurEmail";
import ListeDesClients from "./KageSecur/ListeDesClients";
import PreuveViaMail from "./KageSecur/PreuveViaMail";
import ListeZoneTemporaire from "./KageSecur/ListeZoneTemporaire";
import SecurEmail from "./KageSecur/SecurEmail";
import ConnexionExterne from "./KageSecur/ConnexionExterne";
import SekurEmailMessage from "./KageSecur/SekurEmailMessage";

//Divers
import SharedHeaderKageSecur from "./KageSecur/components/SharedHeaderKS";

import Page404 from "./errorStatus/page404";
import Page403 from "./errorStatus/page403";
import Page503 from "./errorStatus/page503";
import axios from "axios";
import {withRouter} from "./shared/NavigationUtils";

const { date_version } = packageData;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: '',
    };
    global.emitter = new EventEmitter();
  }

  componentDidMount() {
    let cssblue = "color:#517591"
    let csslightblue = "color:#6589a3"
    let csswhite = "color:white"

    // console.log(
    //     "\n" +
    //     "\n" +
    //     "                                                  %c//\n" +
    //     "                                             *//%c((((((%c/*.\n" + //
    //     "                                        .,//%c((((((((((((((%c//,\n" + //
    //     "                                    .//%c(((((((((((((((((((((((%c//,.\n" + //
    //     "                                ,////%c(((((((((((((((((((((((((((((%c//\n" + //
    //     "                            //%c((((((((%c////%c(((((((((((((((((((%c///%c@#%c(/\n" + //
    //     "                       ,*/%c((((((((((((((((%c////%c(((((((((((%c////%c&@@@@%c(/\n" + //
    //     "                   ,//%c((((((((((((((((((((((((%c/////%c(%c////%c#&@@@@@@@@%c(/\n" +//
    //     "                 /////%c((((((((((((((((((((((((((%c////%c#@@@@@@@@@@@@@%c(/\n" + //
    //     "                 /////////%c((((((((((((((((((%c///(%c@@%c//%c&@@@@@@@@@@@@@%c(/\n" + //
    //     "                 /////////////%c((((((((((%c///(%c@@@@@@%c//%c&@@@@@@@@@@@@@%c(/\n" + //
    //     "                 //////////////////////%c%@@@@@@@@@@%c//%c&@@@@@@@@@@@@@%c(/\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c//%c&@@@@@@@@@@%c(//,.\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c//%c&@@@@@@%c//*,\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c//%c&&#%c//*.\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c///*\n" + //
    //     "             ,*/%c(((%c////////////////%c#@@@@@@@@@#%c/////\n" +
    //     "         ,//%c(((((((((((%c////////////%c#@@@@&%c(////%c#&@@%c/\n" +
    //     "    ,*/%c((((((((((((((((((((%c////////%c#@%c(////%c%@@@@@@@%c/\n" +
    //     ".//%c(((((((((((((((((((((((((((((%c/////(%c&@@@@@@@@@@@%c/\n" +
    //     "///////%c((((((((((((((((((((((%c//////%c#@@@@@@@@@@@@@@%c/\n" +
    //     "///////////%c((((((((((((((%c////%c%@@&%c//%c#@@@@@@@@@@@@@@%c/\n" +
    //     "///////////////%c((((((%c///(%c&@@@@@@&%c//%c#@@@@@@@@@@@@@@%c/\n" +
    //     "////////////////////%c#@@@@@@@@@@@&%c//%c#@@@@@@@@@@@@#%c//\n" +
    //     "//////////////////(%c@@@@@@@@@@@@@&%c//%c#@@@@@@@&#%c//*.\n" +
    //     "//////////////////(%c@@@@@@@@@@@@@&%c//%c#@@@&%c///*\n" +
    //     "//////////////////(%c@@@@@@@@@@@@@&%c//////\n" +
    //     "//////////////////(%c@@@@@@@@@@@&%c(//*.\n" +
    //     "    ,/////////////(%c@@@@@@@@%c(//,\n" +
    //     "        ,*////////(%c@@&#%c//*.\n" +
    //     "             *///////*\n" +
    //     "\n" +
    //     "\n%cV."+date_version
    //     ,cssblue,csslightblue,cssblue,csslightblue,cssblue,csslightblue,cssblue,csslightblue,cssblue
    //     ,csslightblue,cssblue,csslightblue,cssblue,csswhite,cssblue,csslightblue,cssblue,csslightblue
    //     ,cssblue,csswhite,cssblue,csslightblue,cssblue,csslightblue,cssblue,csswhite,cssblue,csslightblue
    //     ,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue
    //     ,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite
    //     ,cssblue,csslightblue,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,"color:initial"
    // )

    // let css1 = "color:#547790"
    // let css2 = "color:#F3B02AFF"
    //
    // console.log(
    //     "%c███████╗██████╗ ██╗   ██╗██████╗  ██████╗                                     \n" +
    //     "██╔════╝██╔══██╗██║   ██║██╔══██╗██╔═══██╗                                    \n" +
    //     "███████╗██████╔╝██║   ██║██████╔╝██║   ██║                                    \n" +
    //     "╚════██║██╔═══╝ ██║   ██║██╔══██╗██║   ██║                                    \n" +
    //     "███████║██║     ╚██████╔╝██║  ██║╚██████╔╝                                    \n" +
    //     "╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝                                     \n" +
    //     "                                                                              \n" +
    //     "%c██████╗ ██╗      ██████╗  ██████╗██╗  ██╗ ██████╗██╗  ██╗ █████╗ ██╗███╗   ██╗\n" +
    //     "██╔══██╗██║     ██╔═══██╗██╔════╝██║ ██╔╝██╔════╝██║  ██║██╔══██╗██║████╗  ██║\n" +
    //     "██████╔╝██║     ██║   ██║██║     █████╔╝ ██║     ███████║███████║██║██╔██╗ ██║\n" +
    //     "██╔══██╗██║     ██║   ██║██║     ██╔═██╗ ██║     ██╔══██║██╔══██║██║██║╚██╗██║\n" +
    //     "██████╔╝███████╗╚██████╔╝╚██████╗██║  ██╗╚██████╗██║  ██║██║  ██║██║██║ ╚████║\n" +
    //     "╚═════╝ ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝\n" +
    //     "                                                                              \n" +
    //     "██████╗ ██╗      █████╗ ████████╗███████╗ ██████╗ ██████╗ ███╗   ███╗         \n" +
    //     "██╔══██╗██║     ██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗████╗ ████║         \n" +
    //     "██████╔╝██║     ███████║   ██║   █████╗  ██║   ██║██████╔╝██╔████╔██║         \n" +
    //     "██╔═══╝ ██║     ██╔══██║   ██║   ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║         \n" +
    //     "██║     ███████╗██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║         \n" +
    //     "╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝         \n"  +
    //     "                                                                               ",css1,css2)



    axios.interceptors.request.use(function (config) {
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

// Add a response interceptor
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      console.log(error)
      if(error.response){
        if(error.response.status === 404){
          // alert("ERREUR 404 ! Veuillez rafraîchir la page")
          // window.location = "/page404"
        }else if(error.response.status === 503){
          window.location = "/page503"
        }else if(error.response.status === 403){
          window.location = "/page403"
          // alert("ERREUR 403 ! Veuillez rafraîchir la page")
        }
      }
      return Promise.reject(error);
    });
    let currentPath = this.props.location.pathname.toLowerCase()
    this.setState({currentPath})
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    let currentPath = this.props.location.pathname.toLowerCase()
    if(this.state.currentPath !== currentPath){
      this.setState({currentPath})
    }
  }

  componentDidCatch(error, errorInfo) {
    // axios.post(urlAxios+"frontErrors", {error:error.stack, pathname:window.location.pathname})
  }

  render() {
    return (
        <>
          {this.state.currentPath.includes("/kagesecur") && (
              <SharedHeaderKageSecur/>
          )}

          <Routes>
            {/*KageSecur*/}
            <Route path="/KageSecur/Accueil" element={<AccueilKageSecur/>}/>
            <Route path="/KageSecur/SansValidation" element={<UploadFile signature={false}/>}/>
            <Route path="/KageSecur/AvecValidation" element={<UploadFile signature={true}/>}/>
            <Route path="/KageSecur/Verification" element={<VerificationKageSecur/>}/>
            <Route path="/KageSecur/Tracabilite" element={<TracabiliteKageSecur/>}/>
            <Route path="/KageSecur/SignatureCollaborative" element={<SignatureCollaborative/>}/>
            <Route path="/KageSecur/TableauDeBord" element={<VoirMesDocuments/>}/>
            <Route path="/KageSecur/ListeDesClients" element={<ListeDesClients/>}/>
            <Route path="/KageSecur/InscriptionClientKageSecur" element={<InscriptionClientKageSecur/>}/>
            <Route path="/KageSecur/InscriptionClientSekurEmail" element={<InscriptionClientSekurEmail/>}/>
            <Route path="/KageSecur/PreuveViaMail" element={<PreuveViaMail/>}/>
            <Route path="/KageSecur/ListeZoneTemporaire" element={<ListeZoneTemporaire/>}/>
            <Route path="/KageSecur/SekurEmail" element={<SecurEmail/>}/>
            <Route path="/KageSecur/ConnexionExterne" element={<ConnexionExterne/>}/>
            <Route path="/KageSecur/SekurEmailMessage" element={<SekurEmailMessage/>}/>
            {/*Divers*/}
            <Route path="/page404" element={<Page404/>}/>
            <Route path="/page403" element={<Page403/>}/>
            <Route path="/page503" element={<Page503/>}/>

            {/* Redirect route */}
            <Route path="/KageSecur" element={<Navigate replace to="/KageSecur/Accueil" />} />

            {/* Default redirect */}
            <Route path="*" element={<Navigate to="/KageSecur/Accueil"/>}/>
          </Routes>
        </>
    );
  }
}
export default withRouter(App)
