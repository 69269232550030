import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isMobile} from "react-device-detect";

import Axios from "axios";

import {sha256} from "js-sha256";
import {Button, CircularProgress, IconButton} from "@mui/material";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import GetAppIcon from '@mui/icons-material/GetApp';
import CryptoJS from "crypto-js";
import {KeyboardArrowLeft} from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import {urlAxios, urlAxiosCore} from '../Components/GetURL';


let jwtDecoded;

const sizeLimitFile = 10737418240 // Soit 2GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class VerificationKageSecur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            hashFile : '',
            fileName : '',
            acceptedFile : null,
            verifiedDocument:"",
            chargementFichier:false,
            cancelDropZone:false,
            fileSaved:null,
            secondsDownload:0
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(urlAxiosCore+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    if (jwtDecoded.freeClient){
                        Swal.fire({
                            text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/KageSecur/Accueil", { replace: true })
                        })
                    }else {
                        global.emitter.emit("onRouteChangedKageSecur", {
                            user: jwtDecoded.gestionnaire ? "gestionnaire" : "client",
                            pseudo: jwtDecoded.pseudo
                        })
                        this.verification()
                    }
                }else{
                    global.emitter.emit("askConnection")
                    const self = this;
                    loop()
                    function loop(){
                        if (!!sessionStorage.getItem("KAGESECUR_hash")){
                            self.verification()
                        }else{
                            setTimeout(()=>{
                                loop()
                            },1000)
                        }
                    }
                }
            })
        })
    }

    verification(){
        const queryParams = new URLSearchParams(window.location.search);
        if (!!queryParams.get("hf")){
            Axios.post(urlAxios+"verification", {
                hash:queryParams.get("hf"),
            },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).catch((err)=>{console.log(err)}).then(response=>{
                if (response.data.resultat === "Votre document est enregistré."){
                    this.setState({
                        verifiedDocument:response.data,
                        hashFile:queryParams.get("hf")
                    },()=>{
                        this.setState({secondsDownload:3,hf:queryParams.get("hf")},()=>{
                            this.timeoutDownload()
                        })
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Votre document est enregistré dans notre blockchain !'
                    })
                }else{
                    Toast.fire({
                        title: response.data.resultat,
                        icon: "error",
                    })
                }
            })
        }
    }

    timeoutDownload(){
        if(this.state.secondsDownload !== 0){
            setTimeout(()=>{
                this.setState({secondsDownload:this.state.secondsDownload-1},()=>{
                    if(this.state.secondsDownload === 0){
                        this.download()
                    }else{
                        this.timeoutDownload()
                    }
                })
            },1000)
        }
    }

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    verifEmpreinte(){
        Axios.post(urlAxios+"verification", {
            empreinte:sessionStorage.getItem("KAGESECUR_hash"),
            hash:sha256(this.state.hashFile),
            hashJwt:sessionStorage.getItem("KAGESECUR_hash")
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).catch((err)=>{console.log(err)}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            this.setState({verifiedDocument:response.data})
            if (response.data.resultat === "Votre document est enregistré."){
                Toast.fire({
                    icon: 'success',
                    title: 'Votre document est enregistré dans notre blockchain !'
                })
            }else if(response.data.resultat === "Le document n'a pas encore été validé."){
                this.setState({...this.setInitialStates()})
                Swal.fire({
                    title: "Veuillez patienter...",
                    text: "Votre document n'a pas encore été validé par un signataire, mais est bien présent dans notre blockchain.",
                    icon: "warning",
                    confirmButtonText: 'Ok'
                })
            }else if(response.data.resultat === "Ce document ne vous appartient pas."){
                this.setState({...this.setInitialStates()})
                Swal.fire({
                    title: "Attention !",
                    text: "Ce document est bien présent dans notre blockchain, mais il ne vous appartient pas.",
                    icon: "warning",
                    confirmButtonText: 'Ok'
                })
            }else{
                this.setState({...this.setInitialStates()})
                Swal.fire({
                    title: "Attention !",
                    text: response.data.resultat,
                    icon: "warning",
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    download(){
        if(this.state.fileSaved){
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(this.state.fileSaved);
            link.download = "attestation_KAGESECUR";
            link.click();
        }else{
            Axios.post(urlAxios+"generateAttestation", {
                fileName:this.state.verifiedDocument.fileName,
                auth:this.state.verifiedDocument.auth,
                date:this.state.verifiedDocument.date,
                validateur:this.state.verifiedDocument.signataires.length>0 ? this.state.verifiedDocument.signataires : [],
                empreinte:this.state.verifiedDocument.empreinte,
                pseudo:this.state.verifiedDocument.identifiant,
                hashfile:this.state.verifiedDocument.hashfile
            },{responseType:"arraybuffer"}).then(response => {
                let file = new Blob([response.data], {type: 'application/pdf'});
                this.setState({fileSaved:file})
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(file);
                link.download = "attestation_KAGESECUR";
                link.click();
            })
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                    <h2 style={{marginTop:50, textAlign:"center"}}>Vérifiez <span style={{color:"#52a8d9"}}>l'enregistrement et l'intégrité</span> de <span style={{color:"#f18f29"}}>votre document</span></h2>
                    <div style={{height:"100%",width:"100%",display:"flex",flexDirection:!this.state.showBrowserView && "column", alignItems:"center"}}>
                        <div style={{width:"50%", display:(!this.state.showBrowserView && !this.state.hashFile==="") ? "none":"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <Dropzone multiple={false} maxFiles={1} onDrop={(acceptedFiles) => {
                                acceptedFiles.forEach((file) => {
                                    if(file.size >= sizeLimitFile){
                                        Toast.fire({
                                            icon: 'error',
                                            title: 'Le fichier est trop lourd (>2GB) !'
                                        })
                                    }else {
                                        this.setState({chargementFichier:true, dropzoneProgress:0,cancelDropZone:false, hashFile:"",listAllTrace:[]})
                                        let stream = file.stream()
                                        let re = stream.getReader()
                                        let progressDropzone = 0
                                        let SHA = CryptoJS.algo.SHA256.create()
                                        let self = this
                                        new ReadableStream({
                                            start(controller) {
                                                function push() {
                                                    re.read().then( ({done, value}) => {
                                                        if (done) {
                                                            const finalHash = SHA.finalize().toString()
                                                            self.setState({chargementFichier:false,hashFile:finalHash,dropzoneProgress:100},()=>{
                                                                self.verifEmpreinte()
                                                            })
                                                            controller.close();
                                                            return;
                                                        }
                                                        let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                        SHA.update(wordBuffer);
                                                        progressDropzone = parseFloat(progressDropzone+(value.length/file.size)*100)
                                                        self.setState({dropzoneProgress:progressDropzone})
                                                        setTimeout(()=>{
                                                            if (!self.state.cancelDropZone){
                                                                push();
                                                            }else{
                                                                self.setState({...self.setInitialStates()})
                                                            }
                                                        },10)
                                                    })
                                                }
                                                push();
                                            }
                                        });
                                    }
                                })
                            }}>
                                {({getRootProps, getInputProps}) => (
                                    <section style={{display:"flex",alignItems:"center",justifyContent:"center", flexDirection:"column"}}>
                                        <div {...getRootProps()} id={this.state.hashFile === "" ? "hoverMoving":"nohover"} style={{padding:"10vw", paddingTop:this.state.hashFile === '' ? ("8vw"):("6vw"), paddingBottom:this.state.hashFile === '' ? ("8vw"):("6vw"),borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:this.state.hashFile===""?("black"):("white"),fontWeight:"bold", cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:this.state.hashFile===""?("transparent"):("#52a8d9")}}>
                                            <input {...getInputProps()} />
                                            {this.state.hashFile === '' ? (
                                                <>
                                                    {this.state.chargementFichier === true ? (
                                                        <div style={{position:"relative"}}>
                                                            <CircularProgress size={150}/>
                                                            <h2 style={{position:"absolute", top:36,left:48}}>{parseInt(this.state.dropzoneProgress)}%</h2>
                                                        </div>
                                                    ):(
                                                        <h3>Déposez votre fichier ici.</h3>
                                                    )}
                                                </>
                                            ):(
                                                <div style={{display:"flex",alignItems:"center",justifyContent:"center", flexDirection:"column"}}>
                                                    <h3>Votre document est correctement déposé.</h3>
                                                    <h5>Vous pouvez déposer un nouveau fichier ici.</h5>
                                                </div>
                                            )}
                                        </div>
                                        {this.state.chargementFichier === true &&
                                            <Button variant={"contained"} style={{textTransform:"none", marginTop:25,backgroundColor:"#52a8d9", color:"#FFFFFF"}} onClick={()=>{this.setState({cancelDropZone:true})}}>Arrêter le processus</Button>
                                        }
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <div style={{width:"50%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            {this.state.hashFile !== "" ? (
                                <>
                                    <h3 style={{marginTop:90,display:"flex", justifyContent:"center",alignItems:"center"}}>
                                        <IconButton
                                            onClick={()=>{this.setState({...this.setInitialStates()})}}
                                            style={{cursor:"pointer"}}
                                        >
                                            <KeyboardArrowLeft/>
                                        </IconButton>
                                        Informations du document :
                                    </h3>
                                    <div>
                                        <p style={{fontWeight:"bold",fontSize:17, textAlign:"center"}} >{this.state.verifiedDocument.resultat}</p>
                                        {this.state.verifiedDocument.resultat === "Votre document est enregistré." && (
                                            <div style={{display:"flex", justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
                                                <div>
                                                    <p style={{fontSize:17}} >Date d'enregistrement : {new Date(this.state.verifiedDocument.date).toLocaleString("fr-FR" ,{timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}).replace(","," à")}</p>
                                                    <p style={{fontSize:17}}>Nom du fichier : {this.state.verifiedDocument.fileName}</p>
                                                    <p style={{fontSize:17}}>Signataire(s) : {this.state.verifiedDocument.signataires.length>0 ? this.state.verifiedDocument.signataires.join(" - "):"Aucun"}</p>
                                                </div>
                                                {this.state.secondsDownload !== 0 ? (
                                                    <p style={{fontSize:17, fontWeight:"bold"}}>Votre téléchargement va démarrer dans {this.state.secondsDownload}s</p>
                                                ):(
                                                    <Button  id={"zoomInAndOut"} variant={"contained"} style={{marginTop:15,backgroundColor:"#52a8d9", marginBottom:10, textTransform:"none"}} onClick={()=>{this.download()}}><p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>{this.state.hf ? "Re-t":"T"}éléchargez un certificat <GetAppIcon style={{marginLeft:4}} /> </p> </Button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </>
                            ):(
                                <>
                                    <h3>Fonctionnement de la vérification :</h3>
                                    <div>
                                        <p style={{fontSize:17, margin:20}}>Une fois votre document déposé, une recherche permet de détecter si le document est effectivement <span style={{color:"#52a8d9", fontWeight:"bold"}}>présent</span> dans notre système, et s'il a été <span style={{color:"#52a8d9", fontWeight:"bold"}}>signé</span> ou non. Vous pourrez aussi télécharger un <span style={{color:"#52a8d9", fontWeight:"bold"}}>certificat</span> attestant que vous êtes le détenteur de ce document.</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(VerificationKageSecur)