import {io} from "socket.io-client";
import {baseUrl, suffix} from './GetURL';

// //TEST LOCAL
// const baseUrl = "https://vps-e281c86d.vps.ovh.net";
// const suffix = "/back";

let socketIO;
const socketInstance = {
    connectionSocket: function(toVerify){
        return new Promise(async resolve => {
            socketIO = io(baseUrl+(suffix === ":42000" ? suffix : ""), {
                query:"toVerify="+toVerify,
                path:"/kagedoc/socket.io",
                transports: ["websocket"]
            });
            socketIO.on("connect", () => {
                sessionStorage.setItem("socket_id_session",socketIO.id)
                resolve(socketIO)
            });
            socketIO.on("connectResult", (args) => {
                if(args.status === "Forbidden"){
                    socketIO.disconnect()
                    console.log("Forbidden : Socket déconnecté.")
                }
                resolve(socketIO)
            });
        })

    }
}

export default socketInstance;