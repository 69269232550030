import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isMobile} from "react-device-detect";

import Swal from "sweetalert2";
import {Button} from "@mui/material";
import {Image as ImageIcon, InsertDriveFile, Save} from "@mui/icons-material";
import Axios from "axios";
import sanitizeHtml from 'sanitize-html';
import Download from "@mui/icons-material/Download";
import {urlAxios} from '../Components/GetURL';

const sizeLimitFile = 10737418240 // 10GB

const formatImage =["jpg","jpeg","png","gif","eps","svg"]

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class SekurEmailMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates()
        };
    }

    setInitialStates(){
        return{
            showBrowserView:true,
            attachments : [],
            loadingProcess:true,
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        let idToken = new URLSearchParams(window.location.search).get("idToken");
        let code = new URLSearchParams(window.location.search).get("code");
        if(!!idToken&&!!code){
            this.setState({idToken,code},()=>{
                this.visualizeSekurEmail()
            })
        }
        window.addEventListener('resize', this.resizePage)
        // this.setState({
        //     jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        // },()=>{
        //     jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
        //     Axios.post(urlAxios+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
        //         if(response.data.jwt){
        //             sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
        //             this.setState({
        //                 jwt:response.data.jwt
        //             },()=>{
        //                 jwtDecoded = jwt_decode(this.state.jwt)
        //             })
        //         }
        //         if (response.data.resultat === true){
        //             if (jwtDecoded.freeClient){
        //                 Swal.fire({
        //                     text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
        //                     icon: 'warning',
        //                     confirmButtonText: 'Ok'
        //                 }).then(()=>{
        //                     this.props.navigate("/KageSecur/Accueil")
        //                 })
        //             }else {
        //                 global.emitter.emit("onRouteChangedKageSecur", {
        //                     user: jwtDecoded.gestionnaire ? "gestionnaire" : "client",
        //                     pseudo: jwtDecoded.pseudo
        //                 })
        //             }
        //         }else{
        //             window.location.replace("/KageSecur/Accueil")
        //         }
        //     })
        // })
    }

    visualizeSekurEmail(){
        Axios.post(urlAxios+"visualizeSekurEmail", {
            idToken:this.state.idToken,code:this.state.code
        }).then((response)=>{
            console.log(response.data)
            if(response.data.resultat === "done"){
                this.setState({
                    infos:response.data.infos,
                    loadingProcess:false
                })
            }else{
                Swal.fire({
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    downloadFile(file){
        let bufferFile = Buffer.from(file.data.data, 'base64')
        const url = window.URL.createObjectURL(new Blob([bufferFile]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name.includes("##") ? file.name.split("##")[1]:file.name);
        document.body.appendChild(link);
        link.click();
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:this.state.showBrowserView ? "calc(100%-90px)":"calc(80%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                    {this.state.showBrowserView ? (
                        <>
                            <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../assets/DocumentSecureOrange.png")} alt="imageDocument"/>
                            <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../assets/SecurEmailBleuCropped.png")} alt="imageAuction"/>
                            <h1 style={{marginTop:50}}><span style={{color:"#52a8d9"}}>Sekur</span><span style={{color:"#f18f29"}}>Email</span></h1>
                        </>
                    ):(
                        <h3 style={{marginTop:50,textAlign:"center"}}><span style={{color:"#52a8d9"}}>Sekur</span><span style={{color:"#f18f29"}}>Email</span></h3>
                    )}
                    {!this.state.loadingProcess && (
                        <div style={{overflowY:"auto",overflowX:"hidden",marginBottom:30,width:this.state.showBrowserView?"50%":"90%",backgroundColor:"white",display:"flex",flexDirection:"column",alignContent:"center",borderRadius:8,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding:35, paddingTop:30}}>
                            <div style={{display:"flex"}}>
                                <p style={{marginBottom:5, marginTop:0}}><span style={{fontWeight:"bold"}}> De: </span> {`<${this.state.infos.from.name}> `}{this.state.infos.from.email}</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <p style={{marginBottom:5}}><span style={{fontWeight:"bold"}}> À: </span> {`<${this.state.infos.to.name}> `}{this.state.infos.to.email}</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <p style={{marginBottom:15}}><span style={{fontWeight:"bold"}}> Objet: </span> {this.state.infos.subject}</p>
                            </div>
                            <div style={{display:"flex"}}>
                                <p style={{marginBottom:5}}><span style={{fontWeight:"bold"}}> Pièces jointes : </span> </p>
                            </div>
                            <div className={"flexCenter"} style={{justifyContent:"flex-start"}}>
                                {this.state.infos.attachments.map((elt)=>(
                                    <div onClick={()=>{this.downloadFile(elt)}} className={"flexCenter darkenHover"} style={{maxWidth:250, width:"fit-content", margin:"10px 20px 10px 0", minHeight:72,border:"1px solid #ebebeb", cursor:"pointer"}}>
                                        {elt.contentType.split("/")[0] === "image" ? (
                                            <ImageIcon style={{width:"10%", height:"10%"}}/>
                                        ):(
                                            <InsertDriveFile style={{width:"10%", height:"10%"}}/>
                                        )}
                                        <p style={{width:"fit-content",margin:"5%", textAlign:"center",wordWrap: "break-word", overflow:"hidden", maxHeight:63}}>{elt.name.includes("##") ? elt.name.split("##")[1]:elt.name}</p>
                                        <div className={"flexCenter"} style={{height:"100%"}}>
                                            <div className={"flexCenter"} style={{height:"100%"}}>
                                                <Download/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Button onClick={()=>{window.open(urlAxios+`downloadSekurEmail?code=${this.state.code}&idToken=${this.state.idToken}`)}} variant={"contained"} style={{marginTop:5, marginBottom:10, textTransform:"none", backgroundColor:"#52a8d9", width:"100%"}}>
                                <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:1, fontSize:16, color:"#FFFFFF"}}>
                                    <div className={"flexCenter"}>
                                        <Save style={{marginRight:5}}/>
                                        Sauvegarder le mail
                                    </div>
                                </p>
                            </Button>
                            <p style={{marginTop:10}}><span style={{fontWeight:"bold"}}> Aperçu du mail : </span> </p>
                            <i style={{marginBottom:10, fontWeight:"bold"}}>Une copie de cet email vous a été envoyé sur votre boîte mail.</i>
                            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.state.infos.html) }} className={"editorMail"} style={{border:"1px solid black", marginTop:0, padding:15, borderRadius:8}}/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(SekurEmailMessage)