import React, {Component} from "react";
import {
    ArrowRight,
    Close,
    DragIndicator,
    DriveFolderUpload,
    FolderShared,
    Gavel,
    Help,
    KeyboardReturn,
    Menu,
    Save
} from "@mui/icons-material";
import VerifiedIcon from '@mui/icons-material/Verified';
import {Button, Checkbox} from "@mui/material";
import Draggable from "react-draggable";
import jwt_decode from "jwt-decode";


function RenderTableauDeBord(onExplicationChange){
    const gestionnaire = jwt_decode(sessionStorage.getItem("KAGESECUR_jwt")).gestionnaire || false
    const [explicationState, setExplication] = React.useState(false);
    React.useEffect(()=>{
        onExplicationChange.onExplicationChange(explicationState)
    },[explicationState])

    return (
        <>
            <div onClick={()=>{setExplication("Comment déplacer un fichier")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment déplacer un fichier</p>
            </div>
            <div onClick={()=>{setExplication("Comment demander une signature")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment demander une signature</p>
            </div>
            <div onClick={()=>{setExplication("Comment envoyer une preuve par mail")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment envoyer une preuve par mail</p>
            </div>
            {gestionnaire && (
                <div onClick={()=>{setExplication("Comment partager un dossier")}} className={"divTuto"}>
                    <p style={{margin:10}}>Comment partager un dossier</p>
                </div>
            )}
            <div onClick={()=>{setExplication("Raccourcis et astuces")}} className={"divTuto"}>
                <p style={{margin:10}}>Raccourcis et astuces</p>
            </div>
        </>
    )
}

function RenderTableauDeBordClient(onExplicationChange){
    const [explicationState, setExplication] = React.useState(false);
    React.useEffect(()=>{
        onExplicationChange.onExplicationChange(explicationState)
    },[explicationState])

    return (
        <>
            <div onClick={()=>{setExplication("Comment déplacer un fichier")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment déplacer un fichier</p>
            </div>
            <div onClick={()=>{setExplication("Comment demander une signature")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment demander une signature</p>
            </div>
            <div onClick={()=>{setExplication("Comment envoyer une preuve par mail")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment envoyer une preuve par mail</p>
            </div>
            <div onClick={()=>{setExplication("Raccourcis et astuces")}} className={"divTuto"}>
                <p style={{margin:10}}>Raccourcis et astuces</p>
            </div>
        </>
    )
}

function RenderSansValidation(onExplicationChange){
    const [explicationState, setExplication] = React.useState(false);
    React.useEffect(()=>{
        onExplicationChange.onExplicationChange(explicationState)
    },[explicationState])
    return (
        <>
            <div onClick={()=>{setExplication("Comment enregistrer un document sans signature")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment enregistrer un document sans signature</p>
            </div>
        </>
    )
}

function RenderAvecValidation(onExplicationChange){
    const [explicationState, setExplication] = React.useState(false);
    React.useEffect(()=>{
        onExplicationChange.onExplicationChange(explicationState)
    },[explicationState])
    return (
        <>
            <div onClick={()=>{setExplication("Comment enregistrer un document avec signature")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment enregistrer un document avec signature</p>
            </div>
        </>
    )
}

function RenderSignatureCollaborative(onExplicationChange){
    const [explicationState, setExplication] = React.useState(false);
    React.useEffect(()=>{
        onExplicationChange.onExplicationChange(explicationState)
    },[explicationState])
    return (
        <>
            <div onClick={()=>{setExplication("Comment signer un document")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment signer un document</p>
            </div>
            <div onClick={()=>{setExplication("Comment refuser un document")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment refuser un document</p>
            </div>
            <div onClick={()=>{setExplication("Comment signer et transférer un document")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment signer et transférer un document</p>
            </div>
            <div onClick={()=>{setExplication("Comment déléguer un document")}} className={"divTuto"}>
                <p style={{margin:10}}>Comment déléguer un document</p>
            </div>
        </>
    )
}


function RenderArchivedIcon(){
    return(
        <div style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
            borderColor:"#219400",
            borderRadius: 40, color:"#FFFFFF",display:"flex",alignItems:"center", justifyContent:"center", marginLeft:10}}>
            <div style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                borderColor:"#2ECE00",
                borderRadius: 40, color:"#FFFFFF",display:"flex",alignItems:"center", justifyContent:"center"}}>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <Save style={{color:"#FFFFFF",width:18,marginTop:0}} />
                </div>
            </div>
        </div>
    )
}

function RenderNotArchivedIcon(){
    return(
        <div style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
            borderColor:"#AB1212FF",
            borderRadius: 40, color:"#FFFFFF",display:"flex",alignItems:"center", justifyContent:"center", marginLeft:10}}>
            <div style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                borderColor:"#FF2222FF",
                borderRadius: 40, color:"#FFFFFF",display:"flex",alignItems:"center", justifyContent:"center"}}>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <Save style={{color:"#FFFFFF",width:18,marginTop:0}} />
                    <div style={{position:"relative", display:"block"}}>
                        <div style={{top:-2,left:-22,minWidth:22,minHeight:2,backgroundColor:"white",border:"0.5px solid #FF2222FF", transform:"rotate(45deg)",display:"block", position:"absolute"}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

class HelpCenter extends Component {
    constructor(props) {
        super(props);
        this.state={
            openHelpCenter:false,
            explication:false,
            displayHelpForHelp:true,
        };
        this._handleChangeExplication = this._handleChangeExplication.bind(this)
        this._getExplication = this._getExplication.bind(this)
    }
    _onRouteChangedKageSecur

    componentDidMount() {
        this.setState({tutoToDisplay:window.location.pathname.split("/")[2]})
        this._onRouteChangedKageSecur = global.emitter.addListener("onRouteChangedKageSecur",(data)=>{
            this.setState({
                user:data.user,
                pseudo:data.pseudo
            })
            this.setState({tutoToDisplay:window.location.pathname.split("/")[2], explication:false})
        })
    }

    handleStart = event => {
        event.preventDefault()
        this.setState({displayHelpForHelp:false})
    };
    handleDrag = event => {
        event.preventDefault()
    };
    handleStop = event => {
        event.preventDefault()
    };

    _handleChangeExplication(explication){
        this.setState({explication:explication})
    }

    _getExplication(){
        let test = this.state.explication
        return test
    }

    render(){ //Affichage sur la page
        return (
           <div>
               <div style={{cursor:"pointer"}} onClick={()=>{this.setState({openHelpCenter:!this.state.openHelpCenter,explication:false})}}>
                   <Help style={{width:30,height:30, cursor:"pointer"}}/>
                    <p style={{margin:0}}>Aide</p>
               </div>
               {this.state.openHelpCenter && (
                   <Draggable
                       axis="both"
                       handle=".handle"
                       defaultPosition={{x: 0, y: 0}}
                       position={null}
                       grid={[10, 10]}
                       bounds={"body"}
                       scale={1}
                       onStart={this.handleStart}
                       // onDrag={this.handleDrag}
                       // onStop={this.handleStop}
                       className={"draggableDiv"}
                       >
                       <div style={{backgroundColor:"#FFFFFF",width:350, position:"absolute",borderRadius:5,padding:10 ,zIndex:9999, textAlign:"center", right:80,boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
                           <h3>Aide</h3>
                           <div style={{position:"absolute", right:10,top:10}} className={"flexCenter"}>
                                <p onClick={()=>{this.setState({displayHelpForHelp:false})}} style={{display:this.state.displayHelpForHelp ? "flex":"none",justifyContent:"center",alignItems:"center", color:"darkblue",cursor:"pointer",margin:0}} id={"alwaysHover"}>Déplacer l'aide <ArrowRight/> </p>
                               <DragIndicator className={"handle"} style={{cursor:"move"}}/>
                           </div>

                           {this.state.explication === false && (
                               <Button onClick={()=>{this.setState({openHelpCenter:false})}} style={{position:"absolute", left:10,top:10, cursor:"pointer", color:"#000000"}}>
                                   <Close/>
                               </Button>

                           )}


                           {this.state.explication !== false ? (
                               <>
                                   <Button onClick={()=>{this.setState({explication:false})}} style={{position:"absolute", left:10,top:10, cursor:"pointer", color:"#000000"}}>
                                       <KeyboardReturn/>
                                   </Button>
                                   <div className={"divExplication"}>
                                       <p style={{margin:10, fontWeight:"bold"}}>{this.state.explication}</p>
                                       {this.state.explication === "Comment déplacer un fichier" && (
                                           <>
                                               <p className={"flexCenter"}>1 - Selectionnez un fichier archivé <RenderArchivedIcon/> </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur l'icône <Menu/> pour ouvrir le menu</p>
                                               <p className={"flexCenter"}>3 - Cliquez sur <DriveFolderUpload style={{margin:5, marginRight:2}}/>Déplacer</p>
                                               <p className={"flexCenter"}>4 - Déplacez vous dans le bon dossier (double clique)</p>
                                               <p className={"flexCenter"}>5 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           Déplacer ici
                                                       </p>
                                                   </Button>
                                                   en bas à droite
                                               </p>
                                           </>
                                       )}
                                       {this.state.explication === "Comment demander une signature" && (
                                           <>
                                               <p>1 - Selectionnez un fichier </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur l'icône <Menu/> pour ouvrir le menu</p>
                                               <p className={"flexCenter"}>3 - Cliquez sur <Gavel style={{margin:5, marginRight:2}}/>Demande de signature</p>
                                               <p className={"flexCenter"}>4 - Selectionnez au moins un utilisateur </p>
                                               <p className={"flexCenter"}>5 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           AJOUTER
                                                       </p>
                                                   </Button>
                                               </p>
                                               <div className={"divExplication"}>
                                                   <p style={{fontWeight:"bold"}} className={"flexCenter"}>Cas n°1 : fichier archivé <RenderArchivedIcon/></p>
                                                   <p className={"flexCenter"}>6 - Cliquez sur
                                                       <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                           <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                               ENVOYER
                                                           </p>
                                                       </Button>
                                                   </p>
                                               </div>
                                               <div className={"divExplication"}>
                                                   <p style={{fontWeight:"bold"}} className={"flexCenter"}>Cas n°2 : fichier non archivé <RenderNotArchivedIcon/></p>
                                                   <p className={"flexCenter"}>6 - Cliquez sur
                                                       <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                           <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                               SUIVANT
                                                           </p>
                                                       </Button>
                                                   </p>
                                                   <p className={"flexCenter"}>7 - Déposez le bon fichier dans la zone </p>
                                                   <p className={"flexCenter"}>8 - Cliquez sur
                                                       <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                           <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                               ENVOYER
                                                           </p>
                                                       </Button>
                                                   </p>
                                               </div>
                                           </>
                                       )}
                                       {this.state.explication === "Comment envoyer une preuve par mail" && (
                                           <>
                                               <p>1 - Selectionnez un fichier </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur l'icône <Menu/> pour ouvrir le menu</p>
                                               <p className={"flexCenter"}>3 - Cliquez sur <VerifiedIcon style={{margin:5, marginRight:2}}/>Envoyer la preuve</p>
                                               <p className={"flexCenter"}>4 - Renseignez au moins un nom et un email</p>
                                               <p className={"flexCenter"} style={{marginTop:-15}}>et cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           AJOUTER CE RÉCEPTEUR
                                                       </p>
                                                   </Button></p>
                                               <p className={"flexCenter"}>5 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           SUIVANT
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p style={{fontStyle:"italic"}} className={"flexCenter"}>Facultatif - Insérez une pièce jointe</p>
                                               <p className={"flexCenter"}>6 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           ENVOYER
                                                       </p>
                                                   </Button>
                                               </p>
                                           </>
                                       )}
                                       {this.state.explication === "Comment partager un dossier" && (
                                           <>
                                               <p>1 - Selectionnez un dossier </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur l'icône <Menu/> pour ouvrir le menu</p>
                                               <p className={"flexCenter"}>3 - Cliquez sur <FolderShared style={{margin:5, marginRight:2}}/>Partager le dossier</p>
                                               <p className={"flexCenter"}>4 - Selectionnez au moins un utilisateur </p>
                                               <p className={"flexCenter"}>5 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           AJOUTER
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>6 - Cliquez sur
                                                   <Button disabled={true} style={{backgroundColor:"#52a8d9", margin:5,height:47, color:"#FFFFFF"}} variant={"contained"} >Confirmer le partage</Button>
                                               </p>

                                           </>
                                       )}
                                       {this.state.explication === "Comment enregistrer un document sans signature" && (
                                           <>
                                               <p>1 - Déposez un ou plusieurs fichier(s) dans la zone à gauche </p>
                                               <p className={"flexCenter"}>2 - Vérifiez que la liste de document corresponde à vos besoins</p>
                                               <p className={"flexCenter"}>3 - En cas d'erreur, retirez un fichier</p>
                                               <p style={{marginTop:-15}} className={"flexCenter"}>en cliquant sur <Close/></p>
                                               <p className={"flexCenter"}>4 - Cochez ou non la case
                                                   <Checkbox
                                                       checked={true}
                                                       disabled={true}
                                                       style ={{color: "#52A8D9FF", margin:0}}
                                                   />
                                               </p>
                                               <p className={"flexCenter"}>5 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Enregistrer le(s) document(s)
                                                       </p>
                                                   </Button>
                                               </p>
                                           </>
                                       )}
                                       {this.state.explication === "Raccourcis et astuces" && (
                                           <>
                                               <div className={"divExplication"}>
                                                   <p style={{fontWeight:"bold"}} className={"flexCenter"}>Selectionner plusieurs fichiers en même temps :</p>
                                                   <p className={"flexCenter"}>1 -Maintenez la touche
                                                       <div style={{backgroundColor:"#363636", color:"white", padding:9, paddingRight:14, margin:6, borderRadius:4}}>
                                                           <p style={{margin:0, position:"relative", top:-8, left:-6, fontSize:11}}>Ctrl </p>
                                                       </div>
                                                       de votre clavier
                                                   </p>
                                                   <p className={"flexCenter"}>2 - Selectionnez vos fichiers avec un clic gauche</p>
                                               </div>
                                               <div className={"divExplication"}>
                                                   <p style={{fontWeight:"bold"}} className={"flexCenter"}>Déplacer rapidement un fichier :</p>
                                                   <p className={"flexCenter"}>1 - Selectionnez un fichier avec un clic gauche</p>
                                                   <p className={"flexCenter"}>2 -Appuyez sur les touches
                                                       <div style={{backgroundColor:"#363636", color:"white", padding:9, paddingRight:14, margin:6, borderRadius:4}}>
                                                           <p style={{margin:0, position:"relative", top:-8, left:-6, fontSize:11}}>Ctrl </p>
                                                       </div>
                                                       +
                                                       <div style={{backgroundColor:"#363636", color:"white", padding:"5px 12px 6px 10px", margin:6, borderRadius:4}}>
                                                           <p style={{margin:0, position:"relative", top:-8, left:-6, fontSize:17}}>X </p>
                                                       </div>
                                                   </p>
                                                   <p className={"flexCenter"}>3 - Déplacez vous dans un autre dossier</p>
                                                   <p className={"flexCenter"}>4 -Appuyez sur les touches
                                                       <div style={{backgroundColor:"#363636", color:"white", padding:9, paddingRight:14, margin:6, borderRadius:4}}>
                                                           <p style={{margin:0, position:"relative", top:-8, left:-6, fontSize:11}}>Ctrl </p>
                                                       </div>
                                                       +
                                                       <div style={{backgroundColor:"#363636", color:"white", padding:"5px 12px 6px 10px", margin:6, borderRadius:4}}>
                                                           <p style={{margin:0, position:"relative", top:-8, left:-6, fontSize:17}}>V </p>
                                                       </div>
                                                   </p>
                                               </div>
                                           </>
                                       )}
                                       {this.state.explication === "Comment enregistrer un document avec signature" && (
                                           <>
                                               <p>1 - Déposez un ou plusieurs fichier(s) dans la zone à gauche </p>
                                               <p className={"flexCenter"}>2 - Vérifiez que la liste de document corresponde à vos besoins</p>
                                               <p className={"flexCenter"}>3 - En cas d'erreur, retirez un fichier</p>
                                               <p style={{marginTop:-15}} className={"flexCenter"}>en cliquant sur <Close/></p>
                                               <p className={"flexCenter"}>4 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Gérer les signataires
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>5 - Selectionnez au moins un utilisateur </p>
                                               <p className={"flexCenter"}>6 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           AJOUTER
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>7 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Valider la liste
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>8 - Cochez ou non la case
                                                   <Checkbox
                                                       checked={true}
                                                       disabled={true}
                                                       style ={{color: "#52A8D9FF", margin:0}}
                                                   />
                                               </p>
                                               <p className={"flexCenter"}>9 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Enregistrer le(s) document(s)
                                                       </p>
                                                   </Button>
                                               </p>
                                           </>
                                       )}
                                       {this.state.explication === "Comment signer un document" && (
                                           <>
                                               <p>1 - Selectionnez une demande de signature </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           Signer
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>3 - Un code vous a été envoyé par mail, renseignez le dans le champs de texte. </p>
                                               <p className={"flexCenter"}>4 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#2778c4", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Suivant
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>5 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#2778c4", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Oui, le signer
                                                       </p>
                                                   </Button>
                                               </p>
                                           </>
                                       )}
                                       {this.state.explication === "Comment refuser un document" && (
                                           <>
                                               <p>1 - Selectionnez une demande de signature </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#ff3e3e", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           Refuser
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p style={{fontStyle:"italic"}} className={"flexCenter"}>Facultatif - Renseignez un motif de refus. </p>
                                               <p className={"flexCenter"}>3 - Un code vous a été envoyé par mail, renseignez le dans le champs de texte. </p>
                                               <p className={"flexCenter"}>4 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#2778c4", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Suivant
                                                       </p>
                                                   </Button>
                                               </p>
                                           </>
                                       )}
                                       {this.state.explication === "Comment signer et transférer un document" && (
                                           <>
                                               <p>1 - Selectionnez une demande de signature </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur
                                                   <Button disabled={true} style={{backgroundColor:"#FFFFFF",padding:5, color:"#369dd7", border:"1px solid #369dd7",width:100, fontWeight:"bold", margin:5, textTransform:"none"}}><p style={{margin:0, padding:0, position:"absolute", top:-3}}>Signer &</p><br/><p style={{margin:0, padding:0, position:"relative", top:7}}>Transférer</p></Button>
                                               </p>
                                               <p className={"flexCenter"}>3 - Selectionnez au moins un utilisateur </p>
                                               <p className={"flexCenter"}>4 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           AJOUTER
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>4 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           Signer et transférer
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>5 - Un code vous a été envoyé par mail, renseignez le dans le champs de texte. </p>
                                               <p className={"flexCenter"}>6 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#2778c4", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Suivant
                                                       </p>
                                                   </Button>
                                               </p>
                                           </>
                                       )}
                                       {this.state.explication === "Comment déléguer un document" && (
                                           <>
                                               <p>1 - Selectionnez une demande de signature </p>
                                               <p className={"flexCenter"}>2 - Cliquez sur
                                                   <Button disabled={true} style={{backgroundColor:"#FFFFFF",padding:5, color:"#369dd7", border:"1px solid #369dd7",width:"100px", fontWeight:"bold", margin:5, textTransform:"none"}}>Déléguer</Button>
                                               </p>
                                               <p className={"flexCenter"}>3 - Selectionnez au moins un utilisateur </p>
                                               <p className={"flexCenter"}>4 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           AJOUTER
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>4 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#52a8d9", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, color:"#FFFFFF"}}>
                                                           Déléguer
                                                       </p>
                                                   </Button>
                                               </p>
                                               <p className={"flexCenter"}>5 - Un code vous a été envoyé par mail, renseignez le dans le champs de texte. </p>
                                               <p className={"flexCenter"}>6 - Cliquez sur
                                                   <Button disabled={true} variant={"contained"} style={{backgroundColor:"#2778c4", margin:5,textTransform:"none"}}>
                                                       <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:0, color:"#FFFFFF"}}>
                                                           Suivant
                                                       </p>
                                                   </Button>
                                               </p>
                                           </>
                                       )}
                                   </div>
                               </>
                           ):(
                               <>
                                   {this.state.tutoToDisplay === "TableauDeBord" ? (
                                       <RenderTableauDeBord onExplicationChange={this._handleChangeExplication}/>
                                   ):(
                                       <>
                                           {this.state.tutoToDisplay === "SansValidation" ? (
                                               <RenderSansValidation onExplicationChange={this._handleChangeExplication}/>
                                           ):(
                                               <>
                                                   {this.state.tutoToDisplay === "AvecValidation" ? (
                                                       <RenderAvecValidation onExplicationChange={this._handleChangeExplication}/>
                                                   ):(
                                                       <>
                                                           {this.state.tutoToDisplay === "SignatureCollaborative" ? (
                                                               <RenderSignatureCollaborative onExplicationChange={this._handleChangeExplication}/>
                                                           ):(
                                                               <>
                                                                   {this.state.tutoToDisplay === "TableauDeBordClient" ? (
                                                                       <RenderTableauDeBordClient onExplicationChange={this._handleChangeExplication}/>
                                                                   ):(
                                                                       <>
                                                                           <p>Pas d'aide disponible</p>
                                                                       </>
                                                                   )}
                                                               </>
                                                           )}
                                                       </>
                                                   )}
                                               </>
                                           )}
                                       </>
                                   )}
                               </>
                           )}
                       </div>
                   </Draggable>
               )}
           </div>
        );
    }
}
export default HelpCenter